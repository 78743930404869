import React from 'react';
import { motion } from 'framer-motion';

interface LogoVerticalProps {
  className?: string;
  color?: string;
  animated?: boolean;
  showTagline?: boolean;
}

export default function LogoVertical({
  className = '',
  color = 'currentColor',
  animated = true,
  showTagline = false
}: LogoVerticalProps) {
  return (
    <div className={`flex flex-col items-center ${className}`}>
      <motion.div
        initial={animated ? { opacity: 0, y: -20 } : { opacity: 1, y: 0 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="text-center"
      >
        <div className="text-2xl font-light tracking-wider relative inline-block">
          <span className="font-normal">Infinite</span>Mind<span className="text-primary">.TECH</span>
          <motion.div 
            className="absolute -bottom-1 left-0 w-full h-[1px] bg-primary"
            initial={animated ? { scaleX: 0 } : { scaleX: 1 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          />
        </div>
        
        {showTagline && (
          <motion.div
            initial={animated ? { opacity: 0, y: 10 } : { opacity: 1, y: 0 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="mt-2 text-sm tracking-wide text-current/70"
          >
            Shaping Digital Future
          </motion.div>
        )}
      </motion.div>
    </div>
  );
}