import React from 'react';
import { motion } from 'framer-motion';

interface CaseStudyFilterProps {
  categories: string[];
  selectedCategory: string;
  onCategorySelect: (category: string) => void;
}

export default function CaseStudyFilter({ 
  categories, 
  selectedCategory, 
  onCategorySelect 
}: CaseStudyFilterProps) {
  return (
    <div className="flex flex-wrap justify-center gap-4 mb-16">
      {categories.map((category, index) => (
        <motion.button
          key={category}
          onClick={() => onCategorySelect(category)}
          className={`px-6 py-2 rounded-full transition-colors ${
            selectedCategory === category
              ? 'bg-primary text-white'
              : 'text-white/70 hover:text-white hover:bg-primary/10'
          }`}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {category}
        </motion.button>
      ))}
    </div>
  );
}