import React from 'react';
import { motion } from 'framer-motion';
import { X, Send, ArrowRight } from 'lucide-react';
import * as Dialog from '@radix-ui/react-dialog';
import FadeIn from '../animations/FadeIn';
import AINetworkAnimation from '../animations/AINetworkAnimation';

const projectTypes = [
  'Digital Transformation',
  'AI Integration',
  'Cloud Migration',
  'Custom Software Development',
  'UX/UI Design',
  'Data Analytics'
];

const formFields = [
  {
    label: 'Full Name',
    type: 'text',
    placeholder: 'Enter your full name',
    required: true
  },
  {
    label: 'Email',
    type: 'email',
    placeholder: 'Enter your email address',
    required: true
  },
  {
    label: 'Company',
    type: 'text',
    placeholder: 'Enter your company name',
    required: true
  },
  {
    label: 'Project Description',
    type: 'textarea',
    placeholder: 'Tell us about your project...',
    required: true
  }
];

interface StartProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function StartProjectModal({ isOpen, onClose }: StartProjectModalProps) {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay asChild>
          <motion.div
            className="fixed inset-0 bg-black/90 backdrop-blur-sm z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        </Dialog.Overlay>
        
        <Dialog.Content asChild>
          <motion.div
            className="fixed inset-4 md:inset-8 bg-dark-lighter rounded-lg shadow-xl z-50 overflow-hidden"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <AINetworkAnimation />
            
            <div className="h-full flex flex-col relative z-10">
              {/* Header */}
              <div className="p-6 md:p-8 border-b border-primary/10">
                <div className="flex items-center justify-between">
                  <FadeIn>
                    <Dialog.Title className="text-2xl md:text-3xl font-light">
                      Start Your Project
                    </Dialog.Title>
                  </FadeIn>
                  <Dialog.Close asChild>
                    <button 
                      className="text-white/50 hover:text-white transition-colors"
                      aria-label="Close dialog"
                    >
                      <X className="w-6 h-6" />
                    </button>
                  </Dialog.Close>
                </div>
                <Dialog.Description className="mt-2 text-white/70">
                  Fill out the form below to start your project with InfiniteMind.
                </Dialog.Description>
              </div>

              {/* Content */}
              <div className="flex-1 overflow-y-auto p-6 md:p-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  {/* Form */}
                  <div>
                    <FadeIn delay={0.1}>
                      <form className="space-y-6">
                        {formFields.map((field) => (
                          <div key={field.label}>
                            <label 
                              htmlFor={field.label.toLowerCase().replace(/\s+/g, '-')}
                              className="block text-sm text-white/70 mb-2"
                            >
                              {field.label}
                            </label>
                            {field.type === 'textarea' ? (
                              <textarea
                                id={field.label.toLowerCase().replace(/\s+/g, '-')}
                                className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors resize-none"
                                placeholder={field.placeholder}
                                required={field.required}
                                rows={4}
                              />
                            ) : (
                              <input
                                type={field.type}
                                id={field.label.toLowerCase().replace(/\s+/g, '-')}
                                className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
                                placeholder={field.placeholder}
                                required={field.required}
                              />
                            )}
                          </div>
                        ))}

                        <div>
                          <label className="block text-sm text-white/70 mb-2">
                            Project Type
                          </label>
                          <div className="grid grid-cols-2 gap-4">
                            {projectTypes.map((type) => (
                              <label 
                                key={type}
                                className="flex items-center space-x-3 cursor-pointer group"
                              >
                                <input
                                  type="checkbox"
                                  name="project-type"
                                  value={type}
                                  className="w-5 h-5 rounded border-primary/30 text-primary focus:ring-primary/30 bg-dark"
                                />
                                <span className="text-white/70 group-hover:text-white transition-colors">
                                  {type}
                                </span>
                              </label>
                            ))}
                          </div>
                        </div>

                        <button 
                          type="submit"
                          className="w-full px-8 py-4 bg-primary text-white hover:bg-primary-light transition-colors duration-300 flex items-center justify-center space-x-2 group"
                        >
                          <span>Submit Project Request</span>
                          <Send className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
                        </button>
                      </form>
                    </FadeIn>
                  </div>

                  {/* Info Panel */}
                  <div>
                    <FadeIn delay={0.2}>
                      <div className="space-y-8">
                        <div>
                          <h3 className="text-lg font-light mb-4">Why Choose Us?</h3>
                          <div className="space-y-3">
                            {[
                              'Expert team with proven track record',
                              'Cutting-edge technology solutions',
                              'Agile development methodology',
                              'Transparent communication',
                              'Scalable and future-proof solutions'
                            ].map((point, index) => (
                              <div 
                                key={index}
                                className="flex items-center space-x-3 text-white/70"
                              >
                                <div className="w-1.5 h-1.5 rounded-full bg-primary" />
                                <span>{point}</span>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div>
                          <h3 className="text-lg font-light mb-4">Our Process</h3>
                          <div className="space-y-4">
                            {[
                              'Initial Consultation',
                              'Requirements Analysis',
                              'Solution Design',
                              'Development & Testing',
                              'Deployment & Support'
                            ].map((step, index) => (
                              <div 
                                key={index}
                                className="flex items-center space-x-3"
                              >
                                <div className="w-6 h-6 rounded-full bg-primary/10 text-primary flex items-center justify-center text-sm">
                                  {index + 1}
                                </div>
                                <span className="text-white/70">{step}</span>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="bg-dark p-6 rounded-lg">
                          <h3 className="text-lg font-light mb-4">Ready to Get Started?</h3>
                          <p className="text-white/70">
                            Our team will review your project details and get back to you within 24 hours to discuss next steps.
                          </p>
                          <div className="flex items-center mt-4 text-primary">
                            <ArrowRight className="w-5 h-5 mr-2" />
                            <span>Average response time: 4 hours</span>
                          </div>
                        </div>
                      </div>
                    </FadeIn>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}