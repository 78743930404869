import React from 'react';
import { motion } from 'framer-motion';
import { Mic, MicOff } from 'lucide-react';

interface VoiceButtonProps {
  isListening: boolean;
  onToggle: () => void;
  disabled?: boolean;
}

export default function VoiceButton({ isListening, onToggle, disabled }: VoiceButtonProps) {
  return (
    <motion.button
      onClick={onToggle}
      className={`p-2 rounded-full ${
        isListening 
          ? 'bg-primary text-white' 
          : 'text-primary hover:bg-primary/10'
      } transition-colors disabled:opacity-50 disabled:cursor-not-allowed`}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      disabled={disabled}
    >
      {isListening ? (
        <MicOff className="w-4 h-4" />
      ) : (
        <Mic className="w-4 h-4" />
      )}
    </motion.button>
  );
}