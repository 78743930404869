import { ProcessNode } from '../types/ProcessNode';

export class FlowSimulation {
  private nodes: Map<string, ProcessNode> = new Map();
  private processingQueue: string[] = [];
  private processDelay: number = 2000; // 2 seconds per node
  private lastProcessTime: number = 0;

  constructor(initialNodes: ProcessNode[]) {
    initialNodes.forEach(node => this.nodes.set(node.id, node));
    this.processingQueue = this.findStartNodes();
  }

  private findStartNodes(): string[] {
    return Array.from(this.nodes.values())
      .filter(node => node.type === 'input')
      .map(node => node.id);
  }

  update(currentTime: number) {
    if (currentTime - this.lastProcessTime > this.processDelay) {
      this.processNextNodes();
      this.lastProcessTime = currentTime;
    }
  }

  private processNextNodes() {
    const currentNodes = [...this.processingQueue];
    this.processingQueue = [];

    currentNodes.forEach(nodeId => {
      const node = this.nodes.get(nodeId);
      if (!node) return;

      // Complete current node
      node.status = 'complete';

      // Queue connected nodes
      node.connections.forEach(targetId => {
        const targetNode = this.nodes.get(targetId);
        if (targetNode && targetNode.status === 'idle') {
          targetNode.status = 'processing';
          this.processingQueue.push(targetId);
        }
      });
    });
  }

  getNodes(): ProcessNode[] {
    return Array.from(this.nodes.values());
  }

  reset() {
    this.nodes.forEach(node => node.status = 'idle');
    this.processingQueue = this.findStartNodes();
    this.lastProcessTime = 0;
  }
}