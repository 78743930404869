import React from 'react';
import { Helmet } from 'react-helmet-async';
import CoPilotHero from '../../components/sections/CoPilotHero';
import BusinessCoPilot from '../../components/sections/BusinessCoPilot';
import ConsultingServices from './components/ConsultingServices';
import RoadmapSection from '../../components/sections/RoadmapSection';
import AreasOfFocus from './components/AreasOfFocus';
import ConsultingTeam from './components/ConsultingTeam';
import FooterSection from '../../components/sections/FooterSection';

export default function ConsultingPage() {
  return (
    <>
      <Helmet>
        <title>Business Co-Pilot - InfiniteMind</title>
        <meta 
          name="description" 
          content="Transform your business with InfiniteMind's AI-powered business co-pilot services. We provide end-to-end solutions from strategy to execution." 
        />
        <meta name="keywords" content="business co-pilot, AI, digital transformation, strategy, growth, innovation" />
      </Helmet>
      
      <div className="bg-dark text-white">
        <CoPilotHero />
        <BusinessCoPilot />
        <ConsultingServices />
        <RoadmapSection />
        <AreasOfFocus />
        <ConsultingTeam />
        <FooterSection />
      </div>
    </>
  );
}