import React from 'react';
import { motion } from 'framer-motion';
import { X, Send, Phone, Mail, MapPin } from 'lucide-react';
import * as Dialog from '@radix-ui/react-dialog';
import FadeIn from '../animations/FadeIn';
import ContactForm from './ContactForm';
import StartProjectForm from './StartProjectForm';
import AINetworkAnimation from '../animations/AINetworkAnimation';

interface ActionModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: 'contact' | 'project' | 'work' | null;
}

const contactInfo = [
  {
    icon: <Phone className="w-5 h-5" />,
    label: 'Phone',
    value: '+33 6 75 35 39 65',
    href: 'tel:+33675353965'
  },
  {
    icon: <Mail className="w-5 h-5" />,
    label: 'Email',
    value: 'contact@infinitemind.tech',
    href: 'mailto:contact@infinitemind.tech'
  },
  {
    icon: <MapPin className="w-5 h-5" />,
    label: 'Location',
    value: 'Champs Elysées, Paris - France',
    href: '#'
  }
];

export default function ActionModal({ isOpen, onClose, type }: ActionModalProps) {
  const getTitle = () => {
    switch (type) {
      case 'contact':
        return "Let's Talk";
      case 'project':
        return 'Start Your Project';
      case 'work':
        return 'Our Work';
      default:
        return '';
    }
  };

  const getDescription = () => {
    switch (type) {
      case 'contact':
        return 'Get in touch with us to discuss your needs or just say hello.';
      case 'project':
        return 'Fill out the form below to start your project with InfiniteMind.';
      case 'work':
        return 'Explore our portfolio of innovative solutions and transformative projects.';
      default:
        return '';
    }
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay asChild>
          <motion.div
            className="fixed inset-0 bg-black/90 backdrop-blur-sm z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        </Dialog.Overlay>
        
        <Dialog.Content asChild>
          <motion.div
            className="fixed inset-4 md:inset-8 bg-dark-lighter rounded-lg shadow-xl z-50 overflow-hidden"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <AINetworkAnimation />
            
            <div className="h-full flex flex-col relative z-10">
              {/* Header */}
              <div className="p-6 md:p-8 border-b border-primary/10">
                <div className="flex items-center justify-between">
                  <FadeIn>
                    <Dialog.Title className="text-2xl md:text-3xl font-light">
                      {getTitle()}
                    </Dialog.Title>
                  </FadeIn>
                  <Dialog.Close asChild>
                    <button 
                      className="text-white/50 hover:text-white transition-colors"
                      aria-label="Close dialog"
                    >
                      <X className="w-6 h-6" />
                    </button>
                  </Dialog.Close>
                </div>
                <Dialog.Description className="mt-2 text-white/70">
                  {getDescription()}
                </Dialog.Description>
              </div>

              {/* Content */}
              <div className="flex-1 overflow-y-auto p-6 md:p-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  {/* Form */}
                  <div>
                    <FadeIn delay={0.1}>
                      {type === 'contact' ? (
                        <ContactForm />
                      ) : type === 'project' ? (
                        <StartProjectForm />
                      ) : null}
                    </FadeIn>
                  </div>

                  {/* Contact Information */}
                  <div>
                    <FadeIn delay={0.2}>
                      <div className="space-y-8">
                        <div>
                          <h3 className="text-lg font-light mb-4">Contact Information</h3>
                          <div className="space-y-4">
                            {contactInfo.map((info) => (
                              <a
                                key={info.label}
                                href={info.href}
                                className="flex items-center space-x-4 text-white/70 hover:text-white group"
                              >
                                <div className="p-3 bg-dark rounded-lg group-hover:text-primary transition-colors">
                                  {info.icon}
                                </div>
                                <div>
                                  <p className="text-sm text-white/50">{info.label}</p>
                                  <p className="text-white group-hover:text-primary transition-colors">
                                    {info.value}
                                  </p>
                                </div>
                              </a>
                            ))}
                          </div>
                        </div>

                        <div>
                          <h3 className="text-lg font-light mb-4">Office Hours</h3>
                          <div className="space-y-2 text-white/70">
                            <p>Monday - Friday: 9:00 AM - 6:00 PM CET</p>
                            <p>Saturday - Sunday: Closed</p>
                          </div>
                        </div>
                      </div>
                    </FadeIn>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}