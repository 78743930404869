import React from 'react';
import { motion } from 'framer-motion';
import WorkHero from './components/WorkHero';
import IndustrySection from './components/IndustrySection';
import TestimonialSlider from './components/TestimonialSlider';
import ContactCTA from '../../components/shared/ContactCTA';

export default function WorkPage() {
  return (
    <div className="bg-dark text-white">
      <WorkHero />
      <IndustrySection />
      <TestimonialSlider />
      <ContactCTA />
    </div>
  );
}