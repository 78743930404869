import React from 'react';
import { motion } from 'framer-motion';
import { Code, Palette, Lightbulb, Rocket, Cpu, Users } from 'lucide-react';
import FadeIn from '../animations/FadeIn';

const expertiseAreas = [
  {
    icon: <Lightbulb className="w-8 h-8" />,
    title: "Digital Strategy",
    description: "Crafting innovative digital strategies that drive growth and transformation in the digital age.",
    capabilities: [
      { label: "Market Analysis", value: "98%" },
      { label: "Digital Roadmap", value: "95%" },
      { label: "Innovation Planning", value: "92%" }
    ]
  },
  {
    icon: <Palette className="w-8 h-8" />,
    title: "Experience Design",
    description: "Creating immersive digital experiences that captivate audiences and deliver exceptional results.",
    capabilities: [
      { label: "UX/UI Design", value: "96%" },
      { label: "Service Design", value: "94%" },
      { label: "Brand Experience", value: "95%" }
    ]
  },
  {
    icon: <Code className="w-8 h-8" />,
    title: "Development",
    description: "Building robust digital solutions with cutting-edge technologies and best practices.",
    capabilities: [
      { label: "Web & Mobile", value: "97%" },
      { label: "Cloud Solutions", value: "96%" },
      { label: "Enterprise Apps", value: "94%" }
    ]
  },
  {
    icon: <Cpu className="w-8 h-8" />,
    title: "AI Integration",
    description: "Leveraging artificial intelligence to create smart, adaptive digital solutions.",
    capabilities: [
      { label: "Machine Learning", value: "95%" },
      { label: "NLP", value: "93%" },
      { label: "Computer Vision", value: "94%" }
    ]
  },
  {
    icon: <Users className="w-8 h-8" />,
    title: "Digital Culture",
    description: "Fostering digital transformation through organizational change and innovation.",
    capabilities: [
      { label: "Change Management", value: "92%" },
      { label: "Digital Training", value: "94%" },
      { label: "Culture Shift", value: "93%" }
    ]
  },
  {
    icon: <Rocket className="w-8 h-8" />,
    title: "Growth Acceleration",
    description: "Accelerating business growth through data-driven digital strategies and optimization.",
    capabilities: [
      { label: "Performance Marketing", value: "96%" },
      { label: "Analytics", value: "95%" },
      { label: "Optimization", value: "94%" }
    ]
  }
];

export default function Expertise() {
  return (
    <section className="py-32 bg-dark relative overflow-hidden">
      <motion.div 
        className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(255,107,0,0.05),rgba(10,10,10,0))]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />
      
      <div className="container mx-auto px-6 relative">
        <FadeIn>
          <div className="text-center mb-16">
            <span className="text-primary uppercase tracking-[0.2em] text-sm mb-4 block">
              Our Expertise
            </span>
            <h2 className="text-4xl md:text-5xl font-light mb-6">
              Comprehensive Digital
              <span className="block mt-2 gradient-text">Solutions</span>
            </h2>
            <p className="text-white/70 text-lg max-w-2xl mx-auto">
              We combine strategy, design, and technology to create transformative 
              digital experiences that drive business growth.
            </p>
          </div>
        </FadeIn>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {expertiseAreas.map((area, index) => (
            <FadeIn key={index} delay={0.1 * index}>
              <motion.div 
                className="group cursor-pointer"
                whileHover={{ y: -5 }}
                transition={{ duration: 0.3 }}
              >
                <div className="relative">
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-primary/20 to-primary-light/20 rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-500" />
                  
                  <div className="relative bg-dark-lighter p-8 rounded-lg">
                    <div className="text-primary/50 mb-6 group-hover:text-primary transition-colors duration-300">
                      {area.icon}
                    </div>
                    
                    <h3 className="text-2xl font-light mb-4 text-white/90 group-hover:text-primary transition-colors">
                      {area.title}
                    </h3>
                    
                    <p className="text-white/70 mb-6">
                      {area.description}
                    </p>
                    
                    <div className="space-y-4">
                      {area.capabilities.map((capability, i) => (
                        <div key={i} className="relative">
                          <div className="flex justify-between text-sm mb-1">
                            <span className="text-white/70">{capability.label}</span>
                            <span className="text-primary">{capability.value}</span>
                          </div>
                          <div className="h-1 bg-dark rounded-full overflow-hidden">
                            <motion.div
                              className="h-full bg-primary"
                              initial={{ width: 0 }}
                              whileInView={{ width: capability.value }}
                              viewport={{ once: true }}
                              transition={{ duration: 1, delay: 0.2 * i }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </motion.div>
            </FadeIn>
          ))}
        </div>
      </div>
    </section>
  );
}