import { Position } from '../types';

export const positions: Position[] = [
  {
    id: 'ai-engineer-1',
    title: 'Senior AI Engineer',
    department: 'Engineering',
    location: 'Paris, France',
    type: 'Full-time',
    experience: 'Senior',
    description: 'Join our AI team to develop cutting-edge machine learning solutions.',
    requirements: [
      'Masters or PhD in Computer Science, AI, or related field',
      '5+ years experience with deep learning frameworks',
      'Strong Python programming skills',
      'Experience with TensorFlow or PyTorch',
      'Background in computer vision or NLP'
    ],
    responsibilities: [
      'Design and implement AI/ML solutions',
      'Lead technical discussions and architecture decisions',
      'Mentor junior team members',
      'Collaborate with cross-functional teams'
    ],
    skills: ['Python', 'TensorFlow', 'PyTorch', 'Computer Vision', 'NLP'],
    salary: '€80,000 - €120,000',
    postedDate: '2024-03-10'
  },
  {
    id: 'ui-designer-1',
    title: 'Senior UI/UX Designer',
    department: 'Design',
    location: 'London, UK',
    type: 'Full-time',
    experience: 'Senior',
    description: 'Create exceptional user experiences for our digital products.',
    requirements: [
      '5+ years of UI/UX design experience',
      'Strong portfolio of web/mobile designs',
      'Experience with Figma and design systems',
      'Understanding of user research methodologies'
    ],
    responsibilities: [
      'Lead design projects from concept to implementation',
      'Create user-centered designs',
      'Develop and maintain design systems',
      'Conduct user research and testing'
    ],
    skills: ['Figma', 'Design Systems', 'User Research', 'Prototyping'],
    salary: '£65,000 - £85,000',
    postedDate: '2024-03-12'
  },
  {
    id: 'cloud-architect-1',
    title: 'Cloud Solutions Architect',
    department: 'Engineering',
    location: 'Berlin, Germany',
    type: 'Full-time',
    experience: 'Senior',
    description: 'Design and implement scalable cloud infrastructure solutions.',
    requirements: [
      '7+ years of cloud architecture experience',
      'Deep knowledge of AWS/Azure/GCP',
      'Experience with microservices architecture',
      'Strong background in DevOps practices'
    ],
    responsibilities: [
      'Design cloud architecture solutions',
      'Lead cloud migration projects',
      'Implement security best practices',
      'Optimize cloud infrastructure costs'
    ],
    skills: ['AWS', 'Azure', 'Kubernetes', 'Terraform', 'Docker'],
    salary: '€75,000 - €110,000',
    postedDate: '2024-03-15'
  },
  {
    id: 'data-scientist-1',
    title: 'Lead Data Scientist',
    department: 'Data Science',
    location: 'Amsterdam, Netherlands',
    type: 'Full-time',
    experience: 'Lead',
    description: 'Lead our data science initiatives and machine learning projects.',
    requirements: [
      'PhD in Data Science, Mathematics, or related field',
      '8+ years of data science experience',
      'Strong background in statistical analysis',
      'Experience leading data science teams'
    ],
    responsibilities: [
      'Lead data science projects',
      'Develop predictive models',
      'Guide team strategy and growth',
      'Collaborate with stakeholders'
    ],
    skills: ['Python', 'R', 'Machine Learning', 'Deep Learning', 'Statistics'],
    salary: '€90,000 - €130,000',
    postedDate: '2024-03-08'
  },
  {
    id: 'frontend-dev-1',
    title: 'Senior Frontend Developer',
    department: 'Engineering',
    location: 'Paris, France',
    type: 'Full-time',
    experience: 'Senior',
    description: 'Build modern web applications using cutting-edge technologies.',
    requirements: [
      '5+ years of frontend development experience',
      'Expert knowledge of React and TypeScript',
      'Experience with state management solutions',
      'Strong understanding of web performance'
    ],
    responsibilities: [
      'Develop responsive web applications',
      'Implement UI/UX designs',
      'Optimize application performance',
      'Mentor junior developers'
    ],
    skills: ['React', 'TypeScript', 'Next.js', 'Tailwind CSS'],
    salary: '€60,000 - €85,000',
    postedDate: '2024-03-14'
  },
  {
    id: 'backend-dev-1',
    title: 'Backend Developer',
    department: 'Engineering',
    location: 'London, UK',
    type: 'Full-time',
    experience: 'Mid Level',
    description: 'Develop scalable backend services and APIs.',
    requirements: [
      '3+ years of backend development experience',
      'Strong knowledge of Node.js and TypeScript',
      'Experience with SQL and NoSQL databases',
      'Understanding of microservices architecture'
    ],
    responsibilities: [
      'Design and implement APIs',
      'Optimize database performance',
      'Write automated tests',
      'Document technical solutions'
    ],
    skills: ['Node.js', 'TypeScript', 'PostgreSQL', 'MongoDB'],
    salary: '£45,000 - £65,000',
    postedDate: '2024-03-13'
  },
  {
    id: 'devops-engineer-1',
    title: 'DevOps Engineer',
    department: 'Operations',
    location: 'Berlin, Germany',
    type: 'Full-time',
    experience: 'Mid Level',
    description: 'Implement and maintain CI/CD pipelines and infrastructure.',
    requirements: [
      '3+ years of DevOps experience',
      'Strong knowledge of Linux systems',
      'Experience with containerization',
      'Understanding of cloud platforms'
    ],
    responsibilities: [
      'Maintain CI/CD pipelines',
      'Manage cloud infrastructure',
      'Implement monitoring solutions',
      'Automate deployment processes'
    ],
    skills: ['Docker', 'Kubernetes', 'Jenkins', 'AWS', 'Terraform'],
    salary: '€55,000 - €75,000',
    postedDate: '2024-03-11'
  },
  {
    id: 'product-manager-1',
    title: 'Senior Product Manager',
    department: 'Product',
    location: 'Amsterdam, Netherlands',
    type: 'Full-time',
    experience: 'Senior',
    description: 'Lead product strategy and development initiatives.',
    requirements: [
      '5+ years of product management experience',
      'Strong analytical and strategic thinking',
      'Experience with agile methodologies',
      'Excellent communication skills'
    ],
    responsibilities: [
      'Define product strategy',
      'Manage product roadmap',
      'Coordinate with stakeholders',
      'Analyze market trends'
    ],
    skills: ['Product Strategy', 'Agile', 'Data Analysis', 'User Research'],
    salary: '€70,000 - €95,000',
    postedDate: '2024-03-09'
  },
  {
    id: 'security-engineer-1',
    title: 'Security Engineer',
    department: 'Security',
    location: 'Paris, France',
    type: 'Full-time',
    experience: 'Mid Level',
    description: 'Implement and maintain security measures across our infrastructure.',
    requirements: [
      '3+ years of security engineering experience',
      'Knowledge of security protocols and standards',
      'Experience with security tools and frameworks',
      'Understanding of cloud security'
    ],
    responsibilities: [
      'Implement security measures',
      'Conduct security audits',
      'Respond to security incidents',
      'Develop security policies'
    ],
    skills: ['Security+', 'CISSP', 'Cloud Security', 'Penetration Testing'],
    salary: '€55,000 - €75,000',
    postedDate: '2024-03-16'
  },
  {
    id: 'data-engineer-1',
    title: 'Data Engineer',
    department: 'Data Science',
    location: 'Berlin, Germany',
    type: 'Full-time',
    experience: 'Mid Level',
    description: 'Build and maintain data pipelines and infrastructure.',
    requirements: [
      '3+ years of data engineering experience',
      'Strong SQL and Python skills',
      'Experience with ETL processes',
      'Knowledge of big data technologies'
    ],
    responsibilities: [
      'Design data architecture',
      'Implement ETL pipelines',
      'Optimize data workflows',
      'Maintain data quality'
    ],
    skills: ['Python', 'SQL', 'Apache Spark', 'Airflow'],
    salary: '€50,000 - €70,000',
    postedDate: '2024-03-15'
  },
  {
    id: 'ml-engineer-1',
    title: 'Machine Learning Engineer',
    department: 'Engineering',
    location: 'London, UK',
    type: 'Full-time',
    experience: 'Senior',
    description: 'Develop and deploy machine learning models to production.',
    requirements: [
      '5+ years of ML engineering experience',
      'Strong Python and ML framework knowledge',
      'Experience with ML ops',
      'Understanding of distributed systems'
    ],
    responsibilities: [
      'Deploy ML models',
      'Optimize model performance',
      'Implement ML pipelines',
      'Monitor model metrics'
    ],
    skills: ['Python', 'TensorFlow', 'MLOps', 'Kubernetes'],
    salary: '£60,000 - £85,000',
    postedDate: '2024-03-14'
  },
  {
    id: 'qa-engineer-1',
    title: 'QA Engineer',
    department: 'Engineering',
    location: 'Amsterdam, Netherlands',
    type: 'Full-time',
    experience: 'Mid Level',
    description: 'Ensure software quality through automated and manual testing.',
    requirements: [
      '3+ years of QA experience',
      'Experience with test automation',
      'Knowledge of testing frameworks',
      'Understanding of CI/CD'
    ],
    responsibilities: [
      'Develop test strategies',
      'Implement automated tests',
      'Perform manual testing',
      'Report and track bugs'
    ],
    skills: ['Selenium', 'Jest', 'Cypress', 'TestRail'],
    salary: '€45,000 - €65,000',
    postedDate: '2024-03-13'
  },
  {
    id: 'project-manager-1',
    title: 'Technical Project Manager',
    department: 'Product',
    location: 'Paris, France',
    type: 'Full-time',
    experience: 'Senior',
    description: 'Lead technical projects and coordinate development teams.',
    requirements: [
      '5+ years of project management experience',
      'Technical background',
      'Agile certification',
      'Strong leadership skills'
    ],
    responsibilities: [
      'Manage project delivery',
      'Coordinate teams',
      'Track project progress',
      'Manage stakeholders'
    ],
    skills: ['Agile', 'JIRA', 'Risk Management', 'Scrum'],
    salary: '€65,000 - €90,000',
    postedDate: '2024-03-12'
  },
  {
    id: 'solutions-architect-1',
    title: 'Solutions Architect',
    department: 'Engineering',
    location: 'Berlin, Germany',
    type: 'Full-time',
    experience: 'Senior',
    description: 'Design and implement enterprise-level technical solutions.',
    requirements: [
      '7+ years of architecture experience',
      'Strong system design skills',
      'Experience with enterprise architecture',
      'Knowledge of multiple technologies'
    ],
    responsibilities: [
      'Design system architecture',
      'Lead technical decisions',
      'Evaluate technologies',
      'Guide implementation'
    ],
    skills: ['System Design', 'Cloud Architecture', 'API Design', 'Microservices'],
    salary: '€80,000 - €120,000',
    postedDate: '2024-03-11'
  },
  {
    id: 'mobile-dev-1',
    title: 'Mobile Developer',
    department: 'Engineering',
    location: 'London, UK',
    type: 'Full-time',
    experience: 'Mid Level',
    description: 'Develop cross-platform mobile applications.',
    requirements: [
      '3+ years of mobile development experience',
      'Experience with React Native',
      'Knowledge of mobile platforms',
      'Understanding of UX principles'
    ],
    responsibilities: [
      'Develop mobile apps',
      'Implement UI/UX designs',
      'Optimize app performance',
      'Fix bugs and issues'
    ],
    skills: ['React Native', 'TypeScript', 'Mobile UI', 'App Store'],
    salary: '£45,000 - €65,000',
    postedDate: '2024-03-10'
  },
  {
    id: 'marketing-manager-1',
    title: 'Digital Marketing Manager',
    department: 'Marketing',
    location: 'Amsterdam, Netherlands',
    type: 'Full-time',
    experience: 'Senior',
    description: 'Lead digital marketing strategies and campaigns.',
    requirements: [
      '5+ years of digital marketing experience',
      'Strong analytical skills',
      'Experience with marketing tools',
      'Content strategy expertise'
    ],
    responsibilities: [
      'Develop marketing strategies',
      'Manage campaigns',
      'Analyze performance',
      'Create content plans'
    ],
    skills: ['SEO', 'Analytics', 'Content Marketing', 'Social Media'],
    salary: '€60,000 - €85,000',
    postedDate: '2024-03-09'
  },
  {
    id: 'business-analyst-1',
    title: 'Business Analyst',
    department: 'Product',
    location: 'Paris, France',
    type: 'Full-time',
    experience: 'Mid Level',
    description: 'Analyze business requirements and translate them into technical specifications.',
    requirements: [
      '3+ years of business analysis experience',
      'Strong analytical skills',
      'Experience with requirement gathering',
      'Knowledge of business processes'
    ],
    responsibilities: [
      'Gather requirements',
      'Create specifications',
      'Analyze processes',
      'Support implementation'
    ],
    skills: ['Business Analysis', 'Requirements Gathering', 'Process Modeling', 'Documentation'],
    salary: '€45,000 - €65,000',
    postedDate: '2024-03-08'
  },
  {
    id: 'research-scientist-1',
    title: 'AI Research Scientist',
    department: 'Research',
    location: 'Berlin, Germany',
    type: 'Full-time',
    experience: 'Senior',
    description: 'Conduct research in artificial intelligence and machine learning.',
    requirements: [
      'PhD in Computer Science or related field',
      'Strong research background',
      'Publication record',
      'Experience with deep learning'
    ],
    responsibilities: [
      'Conduct research',
      'Publish papers',
      'Develop prototypes',
      'Collaborate with teams'
    ],
    skills: ['Machine Learning', 'Deep Learning', 'Research', 'Python'],
    salary: '€70,000 - €100,000',
    postedDate: '2024-03-07'
  },
  {
    id: 'sales-manager-1',
    title: 'Enterprise Sales Manager',
    department: 'Sales',
    location: 'London, UK',
    type: 'Full-time',
    experience: 'Senior',
    description: 'Drive enterprise sales and build client relationships.',
    requirements: [
      '7+ years of enterprise sales experience',
      'Strong negotiation skills',
      'Experience in technology sales',
      'Track record of closing deals'
    ],
    responsibilities: [
      'Generate sales',
      'Build relationships',
      'Manage accounts',
      'Meet targets'
    ],
    skills: ['Enterprise Sales', 'Negotiation', 'CRM', 'Business Development'],
    salary: '£70,000 - £100,000 + Commission',
    postedDate: '2024-03-06'
  },
  {
    id: 'hr-manager-1',
    title: 'HR Manager',
    department: 'Human Resources',
    location: 'Amsterdam, Netherlands',
    type: 'Full-time',
    experience: 'Senior',
    description: 'Lead HR initiatives and manage talent acquisition.',
    requirements: [
      '5+ years of HR experience',
      'Strong people management skills',
      'Knowledge of HR practices',
      'Experience with HR systems'
    ],
    responsibilities: [
      'Manage recruitment',
      'Develop HR policies',
      'Handle employee relations',
      'Implement HR initiatives'
    ],
    skills: ['HR Management', 'Recruitment', 'Employee Relations', 'HR Systems'],
    salary: '€55,000 - €75,000',
    postedDate: '2024-03-05'
  },
  {
    id: 'content-strategist-1',
    title: 'Content Strategist',
    department: 'Marketing',
    location: 'Paris, France',
    type: 'Full-time',
    experience: 'Mid Level',
    description: 'Develop and execute content strategy across digital platforms.',
    requirements: [
      '3+ years of content strategy experience',
      'Strong writing and editing skills',
      'SEO knowledge',
      'Experience with content management'
    ],
    responsibilities: [
      'Develop content strategy',
      'Create content plans',
      'Manage content calendar',
      'Measure content performance'
    ],
    skills: ['Content Strategy', 'SEO', 'Content Management', 'Analytics'],
    salary: '€45,000 - €65,000',
    postedDate: '2024-03-04'
  }
];