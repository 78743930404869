import { FormData } from './types';

export async function submitForm(data: FormData, type: 'contact' | 'project') {
  const accessKey = import.meta.env.VITE_WEB3FORMS_KEY;
  
  if (!accessKey) {
    throw new Error('Web3Forms access key is not configured');
  }

  const response = await fetch('https://api.web3forms.com/submit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      access_key: accessKey,
      subject: type === 'contact' ? 'New Contact Form Submission' : 'New Project Request',
      from_name: data.fullName,
      to_email: 'tarek@infinitemind.tech',
      message: formatFormData(data, type),
    }),
  });

  if (!response.ok) {
    throw new Error(`Form submission failed: ${response.statusText}`);
  }

  return response.json();
}

function formatFormData(data: FormData, type: 'contact' | 'project'): string {
  const timestamp = new Date().toISOString();
  const commonFields = `
    Name: ${data.fullName}
    Email: ${data.email}
    Phone: ${data.phone}
    Timestamp: ${timestamp}
  `;

  if (type === 'contact') {
    return `${commonFields}
    Inquiry Type: ${data.inquiryType}
    Subject: ${data.subject}
    Message: ${data.message}
    Newsletter: ${data.newsletter ? 'Yes' : 'No'}`;
  }

  return `${commonFields}
    Company: ${data.company}
    Project Description: ${data.description}
    Budget Range: ${data.budget}
    Timeline: ${data.timeline}
    Source: ${data.source}`;
}