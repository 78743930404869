import React from 'react';
import { motion } from 'framer-motion';
import { RefreshCcw, Clock, Users, TrendingUp, LineChart, Zap, Heart, Layers } from 'lucide-react';
import FadeIn from '../../../components/animations/FadeIn';

const areas = [
  {
    icon: <RefreshCcw className="w-6 h-6" />,
    title: 'Business Model Reinvention',
    description: 'Innovative revenue streams and market adaptation',
    features: ['Subscription Models', 'Ecosystems', 'Strategic Partnerships']
  },
  {
    icon: <Clock className="w-6 h-6" />,
    title: 'Time-to-Value Optimization',
    description: 'Accelerated project execution and results',
    features: ['Agile Methods', 'Quick Deployment', 'Measurable Outcomes']
  },
  {
    icon: <Users className="w-6 h-6" />,
    title: 'Management Empowerment',
    description: 'Enhanced leadership and decision-making',
    features: ['Training', 'Communication', 'Delegation']
  },
  {
    icon: <TrendingUp className="w-6 h-6" />,
    title: 'Profitability Focus',
    description: 'Strategic restructuring and optimization',
    features: ['Resource Planning', 'Cost Control', 'Market Position']
  },
  {
    icon: <LineChart className="w-6 h-6" />,
    title: 'Sales Excellence',
    description: 'Revenue acceleration strategies',
    features: ['Sales Playbooks', 'Tech Integration', 'Cross-Selling']
  },
  {
    icon: <Zap className="w-6 h-6" />,
    title: 'Workforce Efficiency',
    description: 'Process automation and optimization',
    features: ['Automation', 'Workflows', 'Monitoring']
  },
  {
    icon: <Heart className="w-6 h-6" />,
    title: 'Customer Success',
    description: 'Loyalty and engagement optimization',
    features: ['Recovery Plans', 'Loyalty Programs', 'Engagement']
  },
  {
    icon: <Layers className="w-6 h-6" />,
    title: 'Process Simplification',
    description: 'Streamlined operations and communication',
    features: ['Process Design', 'System Integration', 'Clear Comms']
  }
];

export default function AreasOfFocus() {
  return (
    <section className="py-20 bg-dark relative overflow-hidden">
      <motion.div 
        className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(255,107,0,0.05),rgba(10,10,10,0))]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />
      
      <div className="container mx-auto px-6 relative">
        <FadeIn>
          <div className="text-center mb-16">
            <span className="text-primary uppercase tracking-[0.2em] text-sm mb-4 block">
              Areas of Focus
            </span>
            <h2 className="text-4xl md:text-5xl font-light mb-6">
              Expertise Across
              <span className="block mt-2 gradient-text">Key Business Areas</span>
            </h2>
          </div>
        </FadeIn>

        {/* Mind Map Layout */}
        <div className="relative">
          {/* Center Circle */}
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-32 h-32 rounded-full bg-primary/10 border border-primary/20 flex items-center justify-center">
            <span className="text-primary text-sm text-center font-light">
              Business Excellence
            </span>
          </div>

          {/* Areas Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {areas.map((area, index) => (
              <FadeIn key={index} delay={0.1 * index}>
                <motion.div
                  className="relative group"
                  whileHover={{ y: -3 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-primary/20 to-primary-light/20 rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-500" />
                  
                  <div className="relative bg-dark-lighter p-5 rounded-lg">
                    {/* Connector Line */}
                    <motion.div 
                      className="absolute inset-0 border border-primary/10 rounded-lg"
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ duration: 0.5, delay: 0.2 * index }}
                    />
                    
                    <div className="relative space-y-3">
                      <div className="flex items-center space-x-3">
                        <div className="p-2 bg-primary/10 rounded-lg text-primary">
                          {area.icon}
                        </div>
                        <h3 className="text-base font-light text-white/90 group-hover:text-primary transition-colors">
                          {area.title}
                        </h3>
                      </div>
                      
                      <p className="text-sm text-white/70">
                        {area.description}
                      </p>
                      
                      <div className="flex flex-wrap gap-2">
                        {area.features.map((feature, i) => (
                          <span 
                            key={i}
                            className="text-xs px-2 py-1 bg-dark rounded-full text-white/50"
                          >
                            {feature}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </motion.div>
              </FadeIn>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}