import React from 'react';
import { motion } from 'framer-motion';
import { LogoVertical } from '../../logo';
import { Github, Twitter, Linkedin, Instagram } from 'lucide-react';

const socialIcons = [
  { name: 'GitHub', icon: Github },
  { name: 'Twitter', icon: Twitter },
  { name: 'LinkedIn', icon: Linkedin },
  { name: 'Instagram', icon: Instagram }
];

export default function FooterBrand() {
  return (
    <div className="lg:col-span-3">
      <div className="flex justify-center lg:justify-start">
        <LogoVertical color="white" showTagline />
      </div>
      <div className="mt-6 flex space-x-3 justify-center lg:justify-start">
        {socialIcons.map((social) => (
          <motion.div
            key={social.name}
            className="p-2 bg-dark rounded-lg text-white/70 cursor-pointer hover:text-primary transition-colors duration-300"
            whileHover={{ y: -3 }}
          >
            <social.icon className="w-4 h-4" />
          </motion.div>
        ))}
      </div>
    </div>
  );
}