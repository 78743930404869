import React from 'react';
import { motion } from 'framer-motion';
import FadeIn from '../animations/FadeIn';
import { Code2, Lightbulb, Rocket, Send } from 'lucide-react';

const processSteps = [
  {
    icon: <Lightbulb className="w-8 h-8" />,
    title: "Discovery & Strategy",
    description: "Deep analysis of requirements and market opportunities to create a solid foundation for success.",
    number: "01"
  },
  {
    icon: <Code2 className="w-8 h-8" />,
    title: "Design & Development",
    description: "Creating intuitive interfaces and robust solutions using cutting-edge technologies.",
    number: "02"
  },
  {
    icon: <Send className="w-8 h-8" />,
    title: "Testing & Deployment",
    description: "Rigorous testing and seamless deployment to ensure optimal performance.",
    number: "03"
  },
  {
    icon: <Rocket className="w-8 h-8" />,
    title: "Launch & Growth",
    description: "Strategic launch and continuous optimization for sustainable growth.",
    number: "04"
  }
];

export default function Process() {
  return (
    <section className="py-32 bg-dark relative overflow-hidden">
      <motion.div 
        className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(255,107,0,0.05),rgba(10,10,10,0))]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />
      
      <div className="container mx-auto px-6 relative">
        <FadeIn>
          <div className="text-center mb-20">
            <span className="text-primary uppercase tracking-[0.2em] text-sm mb-4 block">
              Our Process
            </span>
            <h2 className="text-4xl md:text-5xl font-light mb-6">
              How We Deliver
              <span className="block mt-2 gradient-text">Excellence</span>
            </h2>
            <p className="text-white/70 text-lg max-w-2xl mx-auto">
              Our proven methodology ensures consistent delivery of high-quality 
              solutions that exceed expectations and drive business growth.
            </p>
          </div>
        </FadeIn>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {processSteps.map((step, index) => (
            <FadeIn key={index} delay={0.2 * index}>
              <motion.div 
                className="relative group"
                whileHover={{ y: -5 }}
                transition={{ duration: 0.3 }}
              >
                <div className="absolute -inset-0.5 bg-gradient-to-r from-primary/20 to-primary-light/20 rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-500" />
                
                <div className="relative bg-dark-lighter p-8 rounded-lg border border-primary/10 group-hover:border-primary/20 transition-colors duration-300">
                  <div className="text-primary/50 mb-6 group-hover:text-primary transition-colors duration-300">
                    {step.icon}
                  </div>
                  
                  <div className="mb-6 text-3xl font-light text-primary/30 group-hover:text-primary transition-colors duration-300">
                    {step.number}
                  </div>
                  
                  <h3 className="text-2xl font-light mb-4 text-white/90 group-hover:text-primary transition-colors duration-300">
                    {step.title}
                  </h3>
                  
                  <p className="text-white/70 leading-relaxed">
                    {step.description}
                  </p>
                </div>
              </motion.div>
            </FadeIn>
          ))}
        </div>
      </div>
    </section>
  );
}