import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight, Quote } from 'lucide-react';
import FadeIn from '../../../components/animations/FadeIn';

const testimonials = [
  {
    quote: "InfiniteMind transformed our business with their innovative AI solutions. The results exceeded our expectations.",
    author: "Sarah Johnson",
    role: "CTO, TechCorp Global",
    company: "TechCorp Global",
    image: "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?auto=format&fit=crop&q=80&w=200",
    logo: "https://images.unsplash.com/photo-1614680376593-902f74cf0d41?auto=format&fit=crop&q=80&w=100"
  },
  {
    quote: "Their expertise in digital transformation helped us achieve remarkable growth and efficiency.",
    author: "Michael Chen",
    role: "CEO, Innovation Labs",
    company: "Innovation Labs",
    image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&q=80&w=200",
    logo: "https://images.unsplash.com/photo-1614680376408-16e9652aabd5?auto=format&fit=crop&q=80&w=100"
  },
  {
    quote: "Working with InfiniteMind was a game-changer for our digital strategy and market presence.",
    author: "Emily Rodriguez",
    role: "Director of Digital, Future Brands",
    company: "Future Brands",
    image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?auto=format&fit=crop&q=80&w=200",
    logo: "https://images.unsplash.com/photo-1614680376573-df3480f0c6ff?auto=format&fit=crop&q=80&w=100"
  }
];

export default function TestimonialSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <section className="py-32 bg-dark relative overflow-hidden">
      <motion.div 
        className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(255,107,0,0.05),rgba(10,10,10,0))]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />
      
      <div className="container mx-auto px-6 relative">
        <FadeIn>
          <div className="text-center mb-16">
            <span className="text-primary uppercase tracking-[0.2em] text-sm mb-4 block">
              Testimonials
            </span>
            <h2 className="text-4xl md:text-5xl font-light mb-6">
              What Our Clients
              <span className="block mt-2 gradient-text">Say About Us</span>
            </h2>
          </div>
        </FadeIn>

        <div className="max-w-4xl mx-auto">
          <FadeIn delay={0.2}>
            <div className="relative">
              <div className="absolute -inset-0.5 bg-gradient-to-r from-primary/20 to-primary-light/20 rounded-lg blur opacity-75" />
              
              <div className="relative bg-dark-lighter p-8 md:p-12 rounded-lg">
                <div className="text-primary mb-8">
                  <Quote className="w-12 h-12" />
                </div>
                
                <motion.div
                  key={currentIndex}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5 }}
                  className="space-y-8"
                >
                  <blockquote className="text-2xl md:text-3xl font-light leading-relaxed">
                    {testimonials[currentIndex].quote}
                  </blockquote>
                  
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                      <img 
                        src={testimonials[currentIndex].image} 
                        alt={testimonials[currentIndex].author}
                        className="w-12 h-12 rounded-full"
                      />
                      <div>
                        <div className="font-light text-lg">
                          {testimonials[currentIndex].author}
                        </div>
                        <div className="text-white/70">
                          {testimonials[currentIndex].role}
                        </div>
                      </div>
                    </div>
                    
                    <img 
                      src={testimonials[currentIndex].logo} 
                      alt={testimonials[currentIndex].company}
                      className="h-8 w-auto opacity-70"
                    />
                  </div>
                </motion.div>
                
                <div className="absolute top-1/2 -translate-y-1/2 -left-4">
                  <button 
                    onClick={prevSlide}
                    className="p-2 rounded-full bg-dark text-white/70 hover:text-white hover:bg-primary/10 transition-colors"
                    aria-label="Previous testimonial"
                  >
                    <ChevronLeft className="w-6 h-6" />
                  </button>
                </div>
                
                <div className="absolute top-1/2 -translate-y-1/2 -right-4">
                  <button 
                    onClick={nextSlide}
                    className="p-2 rounded-full bg-dark text-white/70 hover:text-white hover:bg-primary/10 transition-colors"
                    aria-label="Next testimonial"
                  >
                    <ChevronRight className="w-6 h-6" />
                  </button>
                </div>
              </div>
            </div>
          </FadeIn>

          <FadeIn delay={0.4}>
            <div className="flex justify-center mt-8">
              {testimonials.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentIndex(index)}
                  className={`w-2 h-2 rounded-full mx-1 transition-colors ${
                    currentIndex === index ? 'bg-primary' : 'bg-white/20'
                  }`}
                  aria-label={`Go to testimonial ${index + 1}`}
                />
              ))}
            </div>
          </FadeIn>
        </div>
      </div>
    </section>
  );
}