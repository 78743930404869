import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Send } from 'lucide-react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { submitForm } from '../../utils/forms/formSubmission';
import type { FormData } from '../../utils/forms/types';

const formSchema = z.object({
  fullName: z.string().min(2, 'Name must be at least 2 characters'),
  email: z.string().email('Please enter a valid email address'),
  phone: z.string().regex(/^\+?[\d\s-]{10,}$/, 'Please enter a valid phone number'),
  company: z.string().min(2, 'Company name must be at least 2 characters'),
  description: z.string().min(100, 'Please provide at least 100 characters'),
  budget: z.enum(['under-5k', '5k-10k', '10k-25k', 'over-25k']),
  timeline: z.enum(['urgent', '1-3-months', '3-6-months', '6-plus-months']),
  source: z.string().min(1, 'Please tell us how you heard about us'),
  terms: z.literal(true, {
    errorMap: () => ({ message: 'You must accept the terms and conditions' }),
  }),
});

const projectTypes = [
  'Digital Transformation',
  'AI Integration',
  'Cloud Migration',
  'Custom Software Development',
  'UX/UI Design',
  'Data Analytics'
];

export default function StartProjectForm() {
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');
  
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (data: FormData) => {
    try {
      await submitForm(data, 'project');
      setSubmitStatus('success');
      reset();
      setTimeout(() => setSubmitStatus('idle'), 5000);
    } catch (error) {
      console.error('Form submission error:', error);
      setSubmitStatus('error');
      setTimeout(() => setSubmitStatus('idle'), 5000);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Full Name */}
        <div>
          <label htmlFor="fullName" className="block text-sm text-white/70 mb-2">
            Full Name <span className="text-primary">*</span>
          </label>
          <input
            {...register('fullName')}
            type="text"
            id="fullName"
            className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
            placeholder="Enter your full name"
          />
          {errors.fullName && (
            <p className="mt-1 text-sm text-red-400">{errors.fullName.message}</p>
          )}
        </div>

        {/* Email */}
        <div>
          <label htmlFor="email" className="block text-sm text-white/70 mb-2">
            Email <span className="text-primary">*</span>
          </label>
          <input
            {...register('email')}
            type="email"
            id="email"
            className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
            placeholder="Enter your email address"
          />
          {errors.email && (
            <p className="mt-1 text-sm text-red-400">{errors.email.message}</p>
          )}
        </div>

        {/* Phone */}
        <div>
          <label htmlFor="phone" className="block text-sm text-white/70 mb-2">
            Phone Number <span className="text-primary">*</span>
          </label>
          <input
            {...register('phone')}
            type="tel"
            id="phone"
            className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
            placeholder="Enter your phone number"
          />
          {errors.phone && (
            <p className="mt-1 text-sm text-red-400">{errors.phone.message}</p>
          )}
        </div>

        {/* Company */}
        <div>
          <label htmlFor="company" className="block text-sm text-white/70 mb-2">
            Company Name <span className="text-primary">*</span>
          </label>
          <input
            {...register('company')}
            type="text"
            id="company"
            className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
            placeholder="Enter your company name"
          />
          {errors.company && (
            <p className="mt-1 text-sm text-red-400">{errors.company.message}</p>
          )}
        </div>
      </div>

      {/* Project Description */}
      <div>
        <label htmlFor="description" className="block text-sm text-white/70 mb-2">
          Project Description <span className="text-primary">*</span>
        </label>
        <textarea
          {...register('description')}
          id="description"
          rows={4}
          className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors resize-none"
          placeholder="Tell us about your project (minimum 100 characters)"
        />
        {errors.description && (
          <p className="mt-1 text-sm text-red-400">{errors.description.message}</p>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Budget Range */}
        <div>
          <label htmlFor="budget" className="block text-sm text-white/70 mb-2">
            Budget Range <span className="text-primary">*</span>
          </label>
          <select
            {...register('budget')}
            id="budget"
            className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
          >
            <option value="">Select budget range</option>
            <option value="under-5k">Under $5,000</option>
            <option value="5k-10k">$5,000 - $10,000</option>
            <option value="10k-25k">$10,000 - $25,000</option>
            <option value="over-25k">$25,000+</option>
          </select>
          {errors.budget && (
            <p className="mt-1 text-sm text-red-400">{errors.budget.message}</p>
          )}
        </div>

        {/* Timeline */}
        <div>
          <label htmlFor="timeline" className="block text-sm text-white/70 mb-2">
            Timeline <span className="text-primary">*</span>
          </label>
          <select
            {...register('timeline')}
            id="timeline"
            className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
          >
            <option value="">Select timeline</option>
            <option value="urgent">Urgent - within 1 month</option>
            <option value="1-3-months">1-3 months</option>
            <option value="3-6-months">3-6 months</option>
            <option value="6-plus-months">6+ months</option>
          </select>
          {errors.timeline && (
            <p className="mt-1 text-sm text-red-400">{errors.timeline.message}</p>
          )}
        </div>
      </div>

      {/* Project Type */}
      <div>
        <label className="block text-sm text-white/70 mb-2">
          Project Type
        </label>
        <div className="grid grid-cols-2 gap-4">
          {projectTypes.map((type) => (
            <label 
              key={type}
              className="flex items-center space-x-3 cursor-pointer group"
            >
              <input
                type="checkbox"
                name="project-type"
                value={type}
                className="w-5 h-5 rounded border-primary/30 text-primary focus:ring-primary/30 bg-dark"
              />
              <span className="text-white/70 group-hover:text-white transition-colors">
                {type}
              </span>
            </label>
          ))}
        </div>
      </div>

      {/* How did you hear about us */}
      <div>
        <label htmlFor="source" className="block text-sm text-white/70 mb-2">
          How did you hear about us? <span className="text-primary">*</span>
        </label>
        <input
          {...register('source')}
          type="text"
          id="source"
          className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
          placeholder="Tell us how you found us"
        />
        {errors.source && (
          <p className="mt-1 text-sm text-red-400">{errors.source.message}</p>
        )}
      </div>

      {/* Terms and Conditions */}
      <div className="flex items-start space-x-3">
        <input
          {...register('terms')}
          type="checkbox"
          id="terms"
          className="mt-1 bg-dark border-primary/30 text-primary focus:ring-primary/30"
        />
        <label htmlFor="terms" className="text-sm text-white/70">
          I agree to the{' '}
          <a href="/terms" className="text-primary hover:text-primary-light">
            Terms and Conditions
          </a>{' '}
          and{' '}
          <a href="/privacy" className="text-primary hover:text-primary-light">
            Privacy Policy
          </a>
        </label>
      </div>
      {errors.terms && (
        <p className="mt-1 text-sm text-red-400">{errors.terms.message}</p>
      )}

      {/* Status Messages */}
      {submitStatus === 'success' && (
        <div className="p-4 bg-green-500/10 text-green-500 rounded-lg">
          Thank you for your project request. We will get back to you soon!
        </div>
      )}
      
      {submitStatus === 'error' && (
        <div className="p-4 bg-red-500/10 text-red-500 rounded-lg">
          There was an error sending your request. Please try again.
        </div>
      )}

      {/* Submit Button */}
      <motion.button
        type="submit"
        className="w-full px-8 py-4 bg-primary text-white hover:bg-primary-light transition-colors duration-300 flex items-center justify-center space-x-2 group disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={isSubmitting}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        <span>{isSubmitting ? 'Submitting...' : 'Start Your Project'}</span>
        <Send className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
      </motion.button>
    </form>
  );
}