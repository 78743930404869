import React from 'react';
import { useNavigate } from 'react-router-dom';
import VideoBackground from './hero/VideoBackground';
import AIAutomationFlow from '../animations/AIAutomationFlow';

export default function VideoHero() {
  const navigate = useNavigate();

  return (
    <section className="min-h-[40vh] relative overflow-hidden">
      <VideoBackground />
      
      <div className="relative h-full container mx-auto px-6 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center min-h-[calc(40vh-64px)]">
          {/* Content */}
          <div className="lg:mt-[-80px]">
            <h2 className="text-4xl md:text-5xl font-light leading-tight mb-4">
              Transforming Ideas into
              <span className="block mt-2 bg-gradient-to-r from-white via-white/90 to-white/50 bg-clip-text text-transparent">
                Digital Reality
              </span>
            </h2>
            
            <p className="text-lg text-white/70 max-w-xl leading-relaxed">
              Experience our journey of innovation and creativity through our latest projects 
              and achievements.
            </p>
          </div>

          {/* AI Automation Flow Preview */}
          <div className="hidden lg:block">
            <div className="relative aspect-video">
              <AIAutomationFlow preview />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}