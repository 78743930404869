import React from 'react';
import { motion } from 'framer-motion';

export default function ChatTypingIndicator() {
  return (
    <div className="flex items-center space-x-2">
      {[0, 1, 2].map((i) => (
        <motion.div
          key={i}
          className="w-2 h-2 bg-primary/50 rounded-full"
          animate={{
            y: [0, -5, 0],
            opacity: [0.5, 1, 0.5]
          }}
          transition={{
            duration: 0.6,
            repeat: Infinity,
            delay: i * 0.2
          }}
        />
      ))}
    </div>
  );
}