import React from 'react';
import { motion } from 'framer-motion';
import { Brain, Code, Globe, Target, LineChart, Shield } from 'lucide-react';
import FadeIn from '../animations/FadeIn';

const services = [
  {
    icon: <Brain className="w-5 h-5" />,
    title: "AI Strategy & Implementation",
    description: "Transform your business with cutting-edge AI solutions",
    features: ["AI Assessment", "Solution Design", "Implementation", "ROI Focus"]
  },
  {
    icon: <Globe className="w-5 h-5" />,
    title: "Digital Transformation",
    description: "End-to-end digital transformation consulting",
    features: ["Tech Assessment", "Strategy", "Optimization", "Change"]
  },
  {
    icon: <LineChart className="w-5 h-5" />,
    title: "Business Analytics",
    description: "Data-driven insights for strategic decisions",
    features: ["Performance", "Modeling", "BI", "Strategy"]
  },
  {
    icon: <Shield className="w-5 h-5" />,
    title: "Security & Compliance",
    description: "Enterprise security and regulatory compliance",
    features: ["Assessment", "Framework", "Risk", "Training"]
  }
];

export default function AutomationServices() {
  return (
    <section className="py-12 bg-dark relative overflow-hidden">
      <motion.div 
        className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(255,107,0,0.05),rgba(10,10,10,0))]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />
      
      <div className="container mx-auto px-6 relative">
        <FadeIn>
          <div className="text-center mb-8">
            <span className="text-primary uppercase tracking-[0.2em] text-sm mb-2 block">
              Our Services
            </span>
            <h2 className="text-3xl md:text-4xl font-light mb-3">
              Comprehensive Solutions for
              <span className="block mt-1 gradient-text">Digital Excellence</span>
            </h2>
            <p className="text-white/70 text-base max-w-2xl mx-auto">
              We deliver end-to-end consulting services that drive innovation, growth, 
              and digital transformation across your organization.
            </p>
          </div>
        </FadeIn>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {services.map((service, index) => (
            <FadeIn key={index} delay={0.1 * index}>
              <motion.div 
                className="group cursor-pointer"
                whileHover={{ y: -3 }}
                transition={{ duration: 0.2 }}
              >
                <div className="relative">
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-primary/20 to-primary-light/20 rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-500" />
                  
                  <div className="relative bg-dark-lighter p-4 rounded-lg">
                    <div className="text-primary/50 mb-3 group-hover:text-primary transition-colors duration-300">
                      {service.icon}
                    </div>
                    
                    <h3 className="text-base font-light mb-2 text-white/90 group-hover:text-primary transition-colors">
                      {service.title}
                    </h3>
                    
                    <p className="text-xs text-white/70 mb-3">
                      {service.description}
                    </p>
                    
                    <div className="flex flex-wrap gap-1">
                      {service.features.map((feature, i) => (
                        <span key={i} className="text-[10px] px-1.5 py-0.5 bg-dark rounded-full text-white/50">
                          {feature}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </motion.div>
            </FadeIn>
          ))}
        </div>
      </div>
    </section>
  );
}