import React from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import FadeIn from '../animations/FadeIn';
import Process from './Process';
import Expertise from './Expertise';
import Capabilities from './Capabilities';

export default function ComprehensiveOverview() {
  return (
    <section className="py-32 bg-dark relative overflow-hidden">
      <div className="container mx-auto px-6 relative">
        <FadeIn>
          <div className="text-center mb-16">
            <span className="text-primary uppercase tracking-[0.2em] text-sm mb-4 block">
              Our Approach
            </span>
            <h2 className="text-4xl md:text-5xl font-light mb-6">
              Comprehensive Digital
              <span className="block mt-2 gradient-text">Excellence</span>
            </h2>
            <p className="text-white/70 text-lg max-w-2xl mx-auto">
              Discover our methodology, expertise, and capabilities that drive 
              transformative solutions and innovation.
            </p>
          </div>
        </FadeIn>

        <Tabs.Root defaultValue="expertise" className="space-y-12">
          <Tabs.List className="flex flex-wrap justify-center gap-4 mb-12">
            {[
              { value: 'expertise', label: 'Our Expertise' },
              { value: 'capabilities', label: 'Our Capabilities' },
              { value: 'process', label: 'Our Process' }
            ].map((tab) => (
              <Tabs.Trigger
                key={tab.value}
                value={tab.value}
                className="px-6 py-3 rounded-lg text-white/70 hover:text-white data-[state=active]:bg-primary data-[state=active]:text-white transition-colors"
              >
                {tab.label}
              </Tabs.Trigger>
            ))}
          </Tabs.List>

          <Tabs.Content value="expertise" className="focus:outline-none">
            <Expertise />
          </Tabs.Content>

          <Tabs.Content value="capabilities" className="focus:outline-none">
            <Capabilities />
          </Tabs.Content>

          <Tabs.Content value="process" className="focus:outline-none">
            <Process />
          </Tabs.Content>
        </Tabs.Root>
      </div>
    </section>
  );
}