import React from 'react';
import { motion } from 'framer-motion';
import { Code, Palette, Lightbulb, Rocket, Cpu, Users, Cloud, Shield, Database } from 'lucide-react';
import FadeIn from '../../../components/animations/FadeIn';

const services = [
  {
    icon: <Code className="w-8 h-8" />,
    title: "Custom Software Development",
    description: "End-to-end development of scalable software solutions tailored to your business needs.",
    features: [
      "Web & Mobile Applications",
      "Enterprise Software",
      "Legacy System Modernization"
    ]
  },
  {
    icon: <Cpu className="w-8 h-8" />,
    title: "AI & Machine Learning",
    description: "Advanced AI solutions to automate processes and gain actionable insights.",
    features: [
      "Predictive Analytics",
      "Computer Vision",
      "Natural Language Processing"
    ]
  },
  {
    icon: <Cloud className="w-8 h-8" />,
    title: "Cloud Solutions",
    description: "Comprehensive cloud services for digital transformation and scalability.",
    features: [
      "Cloud Migration",
      "Cloud-Native Development",
      "DevOps Implementation"
    ]
  },
  {
    icon: <Database className="w-8 h-8" />,
    title: "Big Data & Analytics",
    description: "Transform raw data into valuable business insights through advanced analytics.",
    features: [
      "Data Engineering",
      "Business Intelligence",
      "Real-time Analytics"
    ]
  },
  {
    icon: <Shield className="w-8 h-8" />,
    title: "Cybersecurity",
    description: "Comprehensive security solutions to protect your digital assets.",
    features: [
      "Security Assessment",
      "Threat Detection",
      "Compliance Management"
    ]
  },
  {
    icon: <Users className="w-8 h-8" />,
    title: "IT Consulting",
    description: "Strategic technology consulting to drive digital transformation.",
    features: [
      "Digital Strategy",
      "Technology Assessment",
      "Process Optimization"
    ]
  }
];

export default function ServiceGrid() {
  return (
    <section className="py-32 bg-dark relative overflow-hidden">
      <motion.div 
        className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(255,107,0,0.05),rgba(10,10,10,0))]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />
      
      <div className="container mx-auto px-6 relative">
        <FadeIn>
          <div className="text-center mb-16">
            <span className="text-primary uppercase tracking-[0.2em] text-sm mb-4 block">
              Our Services
            </span>
            <h2 className="text-4xl md:text-5xl font-light mb-6">
              Comprehensive Digital
              <span className="block mt-2 gradient-text">Solutions</span>
            </h2>
            <p className="text-white/70 text-lg max-w-2xl mx-auto">
              We deliver end-to-end technology solutions that drive innovation and 
              business growth across industries.
            </p>
          </div>
        </FadeIn>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <FadeIn key={index} delay={0.1 * index}>
              <motion.div 
                className="group cursor-pointer"
                whileHover={{ y: -5 }}
                transition={{ duration: 0.3 }}
              >
                <div className="relative">
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-primary/20 to-primary-light/20 rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-500" />
                  
                  <div className="relative bg-dark-lighter p-8 rounded-lg border border-primary/10 group-hover:border-primary/20 transition-colors duration-300">
                    <div className="text-primary/50 mb-6 group-hover:text-primary transition-colors duration-300">
                      {service.icon}
                    </div>
                    
                    <h3 className="text-2xl font-light mb-4 text-white/90 group-hover:text-primary transition-colors">
                      {service.title}
                    </h3>
                    
                    <p className="text-white/70 mb-6">
                      {service.description}
                    </p>
                    
                    <ul className="space-y-3">
                      {service.features.map((feature, i) => (
                        <li key={i} className="flex items-center text-white/50">
                          <div className="w-1.5 h-1.5 rounded-full bg-primary mr-3" />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </motion.div>
            </FadeIn>
          ))}
        </div>
      </div>
    </section>
  );
}