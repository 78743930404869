import { ProcessNode, createProcessNode } from './ProcessNode';

export interface FlowConfig {
  nodes: ProcessNode[];
  name: string;
  description: string;
}

export const getFlowConfig = (
  industry: string,
  processType: string,
  technology: string,
  padding: number = 100,
  spacing: number = 200
): FlowConfig => {
  // Retail - Customer Service with NLP
  if (industry === 'Retail' && processType === 'Customer Service' && technology === 'Natural Language Processing') {
    return {
      name: 'Intelligent Customer Service',
      description: 'NLP-powered customer interaction and support system',
      nodes: [
        // Customer Touchpoints
        createProcessNode('chat', 'input', padding, padding, 'Chat', ['intent_analysis']),
        createProcessNode('email', 'input', padding, padding + spacing, 'Email', ['sentiment_analysis']),
        createProcessNode('social', 'input', padding, padding + spacing * 2, 'Social Media', ['feedback_analysis']),
        
        // NLP Processing
        createProcessNode('intent_analysis', 'ai', padding + spacing, padding, 'Intent Analysis', ['response_gen', 'routing']),
        createProcessNode('sentiment_analysis', 'ai', padding + spacing, padding + spacing, 'Sentiment Analysis', ['priority_engine']),
        createProcessNode('feedback_analysis', 'ai', padding + spacing, padding + spacing * 2, 'Feedback Analysis', ['insight_engine']),
        
        // Decision Layer
        createProcessNode('response_gen', 'ai', padding + spacing * 2, padding, 'Response Generator', ['personalization']),
        createProcessNode('routing', 'process', padding + spacing * 2, padding + spacing * 0.5, 'Smart Routing', ['agent_assignment']),
        createProcessNode('priority_engine', 'process', padding + spacing * 2, padding + spacing, 'Priority Engine', ['queue_management']),
        createProcessNode('insight_engine', 'ai', padding + spacing * 2, padding + spacing * 2, 'Insight Engine', ['analytics']),
        
        // Action Layer
        createProcessNode('personalization', 'process', padding + spacing * 3, padding, 'Personalization', ['response']),
        createProcessNode('agent_assignment', 'process', padding + spacing * 3, padding + spacing * 0.5, 'Agent Assignment', ['handoff']),
        createProcessNode('queue_management', 'process', padding + spacing * 3, padding + spacing, 'Queue Management', ['notification']),
        createProcessNode('analytics', 'process', padding + spacing * 3, padding + spacing * 2, 'Analytics', ['dashboard']),
        
        // Outputs
        createProcessNode('response', 'output', padding + spacing * 4, padding, 'Auto Response', []),
        createProcessNode('handoff', 'output', padding + spacing * 4, padding + spacing * 0.5, 'Agent Handoff', []),
        createProcessNode('notification', 'output', padding + spacing * 4, padding + spacing, 'Notifications', []),
        createProcessNode('dashboard', 'output', padding + spacing * 4, padding + spacing * 2, 'Dashboard', [])
      ]
    };
  }

  // Default to a generic flow
  return {
    name: 'General AI Automation',
    description: 'Standard AI automation workflow',
    nodes: [
      createProcessNode('data_input', 'input', padding, padding, 'Data Input', ['preprocessing']),
      createProcessNode('external_data', 'input', padding, padding + spacing, 'External Data', ['data_validation']),
      createProcessNode('preprocessing', 'process', padding + spacing, padding, 'Preprocessing', ['ai_analysis']),
      createProcessNode('data_validation', 'process', padding + spacing, padding + spacing, 'Validation', ['ai_analysis']),
      createProcessNode('ai_analysis', 'ai', padding + spacing * 2, padding + spacing * 0.5, 'AI Analysis', ['decision_engine']),
      createProcessNode('decision_engine', 'process', padding + spacing * 3, padding + spacing * 0.5, 'Decision Engine', ['output']),
      createProcessNode('output', 'output', padding + spacing * 4, padding + spacing * 0.5, 'Output', [])
    ]
  };
};