import React from 'react';
import { motion } from 'framer-motion';
import { Target, TrendingUp, Shield, Users, Brain, Rocket } from 'lucide-react';
import FadeIn from '../../../components/animations/FadeIn';

const services = [
  {
    icon: <Target className="w-8 h-8" />,
    title: "Unlocking Strategic Potential",
    description: "Achieve Measurable Growth and Lasting Impact",
    content: "We deliver actionable strategies that align with your vision and translate into tangible results across all facets of your business.",
    outcomes: [
      "Uncover growth opportunities and optimize decision-making processes",
      "Enable agility and responsiveness to market shifts and challenges",
      "Drive measurable ROI and long-term sustainability"
    ]
  },
  {
    icon: <TrendingUp className="w-8 h-8" />,
    title: "Enhancing Operational Resilience",
    description: "Streamline and Scale with Confidence",
    content: "Transform operations to reduce inefficiencies, enhance productivity, and position your business for scalable success.",
    outcomes: [
      "Seamlessly integrate technology to drive operational excellence",
      "Reduce costs and increase efficiency across key processes",
      "Enable faster, smarter execution to deliver consistent value"
    ]
  },
  {
    icon: <Brain className="w-8 h-8" />,
    title: "Empowering Data-Driven Decisions",
    description: "Insights That Drive Competitive Advantage",
    content: "Harness the power of data to identify trends, mitigate risks, and capitalize on opportunities.",
    outcomes: [
      "Improve strategic planning with accurate, actionable insights",
      "Predict market shifts and make informed decisions that enhance performance",
      "Unlock new revenue streams and optimize resource allocation"
    ]
  },
  {
    icon: <Shield className="w-8 h-8" />,
    title: "Building Trust and Resilience",
    description: "Protecting Your Business and Enabling Confidence",
    content: "Establish robust frameworks to secure your organization's assets, ensure compliance, and foster trust among stakeholders.",
    outcomes: [
      "Build customer confidence through unwavering commitment to data security",
      "Ensure operational continuity and minimize risk exposure",
      "Navigate complex regulatory landscapes with ease and expertise"
    ]
  },
  {
    icon: <Users className="w-8 h-8" />,
    title: "Elevating Team Performance",
    description: "Aligning People with Purpose",
    content: "Empower teams to thrive by cultivating strong leadership, fostering collaboration, and enhancing cultural alignment.",
    outcomes: [
      "Increase employee engagement and retention through meaningful development",
      "Align team performance with organizational objectives to maximize impact",
      "Foster a culture of excellence that drives long-term success"
    ]
  },
  {
    icon: <Rocket className="w-8 h-8" />,
    title: "Innovation as a Competitive Edge",
    description: "Turning Vision into Reality",
    content: "Fuel your organization's growth by embedding innovation into your strategy and operations.",
    outcomes: [
      "Accelerate time-to-market with structured innovation processes",
      "Develop groundbreaking solutions that meet evolving customer needs",
      "Create measurable, repeatable success through a robust innovation framework"
    ]
  }
];

export default function ConsultingServices() {
  return (
    <section className="py-32 bg-dark relative overflow-hidden">
      <motion.div 
        className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(255,107,0,0.05),rgba(10,10,10,0))]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />
      
      <div className="container mx-auto px-6 relative">
        <FadeIn>
          <div className="text-center mb-16">
            <span className="text-primary uppercase tracking-[0.2em] text-sm mb-4 block">
              Our Services
            </span>
            <h2 className="text-4xl md:text-5xl font-light mb-6">
              Driving Strategic Impact and
              <span className="block mt-2 gradient-text">Business Excellence</span>
            </h2>
            <p className="text-white/70 text-lg max-w-3xl mx-auto">
              At InfiniteMind, we enable organizations to achieve their most ambitious goals by focusing on transformative outcomes. 
              From accelerating innovation to enhancing operational resilience and fostering cultural alignment, our services are 
              designed to unlock growth, optimize performance, and future-proof your business.
            </p>
          </div>
        </FadeIn>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <FadeIn key={index} delay={0.1 * index}>
              <motion.div 
                className="group cursor-pointer"
                whileHover={{ y: -5 }}
                transition={{ duration: 0.3 }}
              >
                <div className="relative">
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-primary/20 to-primary-light/20 rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-500" />
                  
                  <div className="relative bg-dark-lighter p-8 rounded-lg">
                    <div className="text-primary/50 mb-6 group-hover:text-primary transition-colors duration-300">
                      {service.icon}
                    </div>
                    
                    <h3 className="text-2xl font-light mb-2 text-white/90 group-hover:text-primary transition-colors">
                      {service.title}
                    </h3>
                    
                    <p className="text-primary/70 text-sm mb-4">
                      {service.description}
                    </p>

                    <p className="text-white/70 mb-6">
                      {service.content}
                    </p>
                    
                    <div className="space-y-3">
                      <h4 className="text-sm font-medium text-white/90">Business Outcomes</h4>
                      {service.outcomes.map((outcome, i) => (
                        <div key={i} className="flex items-center text-white/50">
                          <div className="w-1.5 h-1.5 rounded-full bg-primary mr-3" />
                          {outcome}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </motion.div>
            </FadeIn>
          ))}
        </div>

        <FadeIn delay={0.6}>
          <div className="mt-16 text-center">
            <p className="text-white/70 max-w-3xl mx-auto">
              InfiniteMind Business Co-Pilot redefines how businesses achieve excellence by focusing on 
              the outcomes that matter most—growth, efficiency, and impact. Together, we'll craft the 
              future of your success.
            </p>
          </div>
        </FadeIn>
      </div>
    </section>
  );
}