import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';

interface ActionButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  icon?: boolean;
}

export default function ActionButton({ children, onClick, className = '', icon = true }: ActionButtonProps) {
  return (
    <motion.button
      onClick={onClick}
      className={`px-6 py-2 border border-primary text-primary hover:bg-primary hover:text-white transition-all duration-300 flex items-center space-x-2 group ${className}`}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
    >
      <span>{children}</span>
      {icon && <ArrowRight className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />}
    </motion.button>
  );
}