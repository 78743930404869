import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Shield, AlertTriangle, Lock, Eye, Network, Activity } from 'lucide-react';

interface SecurityFeature {
  id: string;
  title: string;
  description: string;
  icon: React.ReactNode;
  screens: {
    title: string;
    content: React.ReactNode;
  }[];
  stats: {
    label: string;
    value: string;
    trend: number;
  }[];
}

const features: SecurityFeature[] = [
  {
    id: 'threat-detection',
    title: 'AI Threat Detection',
    description: 'Real-time threat monitoring and analysis',
    icon: <AlertTriangle className="w-6 h-6" />,
    screens: [
      {
        title: 'Security Dashboard',
        content: (
          <div className="space-y-4">
            <div className="bg-dark/50 p-4 rounded-lg">
              <div className="flex items-center justify-between mb-4">
                <span className="text-primary">Threat Monitor</span>
                <span className="text-sm text-white/50">Live Feed</span>
              </div>
              <div className="space-y-4">
                {[
                  { type: 'DDoS Attack', status: 'Blocked', severity: 'High', time: '2m ago' },
                  { type: 'SQL Injection', status: 'Prevented', severity: 'Critical', time: '5m ago' },
                  { type: 'Suspicious Login', status: 'Investigating', severity: 'Medium', time: '8m ago' },
                  { type: 'Data Breach Attempt', status: 'Blocked', severity: 'High', time: '12m ago' }
                ].map((alert, i) => (
                  <motion.div
                    key={i}
                    className="flex items-center justify-between bg-dark/30 p-3 rounded"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: i * 0.1 }}
                  >
                    <div>
                      <div className="text-sm font-medium">{alert.type}</div>
                      <div className="text-xs text-white/50">{alert.time}</div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <span className={`px-2 py-1 rounded text-xs ${
                        alert.severity === 'Critical' ? 'bg-red-500/20 text-red-500' :
                        alert.severity === 'High' ? 'bg-orange-500/20 text-orange-500' :
                        'bg-yellow-500/20 text-yellow-500'
                      }`}>
                        {alert.severity}
                      </span>
                      <span className={`text-sm ${
                        alert.status === 'Blocked' ? 'text-green-500' :
                        alert.status === 'Investigating' ? 'text-yellow-500' :
                        'text-blue-500'
                      }`}>
                        {alert.status}
                      </span>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        )
      }
    ],
    stats: [
      { label: 'Threats Detected', value: '1.2K/day', trend: -8.4 },
      { label: 'Response Time', value: '1.5ms', trend: -25.3 },
      { label: 'False Positives', value: '0.01%', trend: -45.2 }
    ]
  },
  {
    id: 'network-security',
    title: 'Network Protection',
    description: 'AI-powered network security and monitoring',
    icon: <Network className="w-6 h-6" />,
    screens: [
      {
        title: 'Network Analysis',
        content: (
          <div className="space-y-4">
            <div className="bg-dark/50 p-4 rounded-lg">
              <div className="flex items-center justify-between mb-4">
                <span className="text-primary">Network Status</span>
                <span className="text-sm text-white/50">Real-time</span>
              </div>
              <div className="space-y-4">
                {[
                  { segment: 'External Gateway', traffic: '2.8 TB/day', status: 'Protected' },
                  { segment: 'Internal Network', traffic: '5.2 TB/day', status: 'Secured' },
                  { segment: 'Cloud Services', traffic: '8.5 TB/day', status: 'Monitored' },
                  { segment: 'Data Center', traffic: '12.1 TB/day', status: 'Protected' }
                ].map((segment, i) => (
                  <motion.div
                    key={i}
                    className="flex items-center justify-between bg-dark/30 p-3 rounded"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: i * 0.1 }}
                  >
                    <div>
                      <div className="text-sm font-medium">{segment.segment}</div>
                      <div className="text-xs text-white/50">{segment.traffic}</div>
                    </div>
                    <span className="text-green-500">{segment.status}</span>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        )
      }
    ],
    stats: [
      { label: 'Network Coverage', value: '99.99%', trend: 0.5 },
      { label: 'Throughput', value: '28.6 TB/day', trend: 12.4 },
      { label: 'Security Score', value: '98/100', trend: 2.1 }
    ]
  },
  {
    id: 'access-control',
    title: 'Smart Access Control',
    description: 'Intelligent identity and access management',
    icon: <Lock className="w-6 h-6" />,
    screens: [
      {
        title: 'Access Management',
        content: (
          <div className="space-y-4">
            <div className="bg-dark/50 p-4 rounded-lg">
              <div className="flex items-center justify-between mb-4">
                <span className="text-primary">Access Events</span>
                <span className="text-sm text-white/50">Last Hour</span>
              </div>
              <div className="space-y-4">
                {[
                  { user: 'Admin Portal', action: 'MFA Verified', risk: 'Low' },
                  { user: 'API Gateway', action: 'Token Issued', risk: 'Low' },
                  { user: 'Database', action: 'Access Denied', risk: 'High' },
                  { user: 'Cloud Console', action: 'Session Started', risk: 'Medium' }
                ].map((event, i) => (
                  <motion.div
                    key={i}
                    className="flex items-center justify-between bg-dark/30 p-3 rounded"
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: i * 0.1 }}
                  >
                    <div>
                      <div className="text-sm font-medium">{event.user}</div>
                      <div className="text-xs text-white/50">{event.action}</div>
                    </div>
                    <span className={`px-2 py-1 rounded text-xs ${
                      event.risk === 'High' ? 'bg-red-500/20 text-red-500' :
                      event.risk === 'Medium' ? 'bg-yellow-500/20 text-yellow-500' :
                      'bg-green-500/20 text-green-500'
                    }`}>
                      {event.risk}
                    </span>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        )
      }
    ],
    stats: [
      { label: 'Authentication Success', value: '99.9%', trend: 1.2 },
      { label: 'Avg Response Time', value: '0.2s', trend: -18.5 },
      { label: 'Security Events', value: '2.4K/hr', trend: -5.3 }
    ]
  }
];

export default function AICybersecurity() {
  const [selectedFeature, setSelectedFeature] = useState(features[0]);

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {features.map((feature) => (
          <button
            key={feature.id}
            onClick={() => setSelectedFeature(feature)}
            className={`p-4 rounded-lg transition-colors ${
              selectedFeature.id === feature.id
                ? 'bg-primary text-white'
                : 'bg-dark text-white/70 hover:bg-primary/10'
            }`}
          >
            <div className="flex items-center space-x-3">
              {feature.icon}
              <span>{feature.title}</span>
            </div>
          </button>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Security Interface Preview */}
        <div className="bg-dark p-8 rounded-lg">
          <div className="max-w-2xl mx-auto">
            <div className="relative border-8 border-dark-lighter rounded-lg overflow-hidden">
              <div className="p-4 space-y-4">
                {/* Interface Header */}
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-lg font-light">{selectedFeature.title}</h3>
                    <p className="text-sm text-white/50">{selectedFeature.description}</p>
                  </div>
                  {selectedFeature.icon}
                </div>

                {/* Interface Content */}
                <div className="mt-4">
                  {selectedFeature.screens.map((screen, index) => (
                    <div key={index}>
                      <h4 className="text-sm text-white/70 mb-2">{screen.title}</h4>
                      {screen.content}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Security Analytics */}
        <div className="space-y-6">
          <div className="bg-dark p-6 rounded-lg">
            <h3 className="text-xl font-light mb-4">Security Metrics</h3>
            <div className="space-y-4">
              {selectedFeature.stats.map((stat, index) => (
                <div key={index} className="flex items-center justify-between">
                  <span className="text-white/70">{stat.label}</span>
                  <div className="flex items-center space-x-3">
                    <span className="text-lg">{stat.value}</span>
                    <span className={stat.trend > 0 ? 'text-green-500' : 'text-red-500'}>
                      {stat.trend > 0 ? '+' : ''}{stat.trend}%
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-dark p-6 rounded-lg">
            <h3 className="text-xl font-light mb-4">Security Overview</h3>
            <div className="grid grid-cols-2 gap-4">
              {[
                { label: 'Security Score', value: '98/100', trend: '+2.5%' },
                { label: 'Incidents', value: '0', trend: '-100%' },
                { label: 'Compliance', value: '100%', trend: '+5%' },
                { label: 'Uptime', value: '99.99%', trend: '+0.01%' }
              ].map((metric, index) => (
                <div key={index} className="bg-dark-lighter p-4 rounded-lg">
                  <div className="text-white/50 text-sm mb-1">{metric.label}</div>
                  <div className="text-xl">{metric.value}</div>
                  <div className="text-sm text-green-500">{metric.trend}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}