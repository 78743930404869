import React from 'react';
import { useTranslation } from 'react-i18next';
import AutomationsHero from '../../components/sections/AutomationsHero';
import VideoHero from '../../components/sections/VideoHero';
import RealizationsShowcase from '../../components/sections/RealizationsShowcase';
import ComprehensiveOverview from '../../components/sections/ComprehensiveOverview';
import AutomationServices from '../../components/sections/AutomationServices';
import FooterSection from '../../components/sections/FooterSection';

export default function HomePage() {
  const { t } = useTranslation();

  return (
    <main>
      <AutomationsHero />
      <VideoHero />
      <AutomationServices />
      <RealizationsShowcase />
      <ComprehensiveOverview />
      <FooterSection />
    </main>
  );
}