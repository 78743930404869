import { useState, useCallback } from 'react';
import OpenAI from 'openai';

export interface Message {
  role: 'user' | 'assistant';
  content: string;
}

const FALLBACK_RESPONSES = [
  "I apologize, but I'm currently experiencing high demand. Please try again in a moment.",
  "I'm temporarily unavailable. You can also reach our team at contact@infinitemind.tech",
  "Our systems are quite busy right now. Please try again shortly."
];

let retryCount = 0;
const MAX_RETRIES = 3;

export function useChat() {
  const [messages, setMessages] = useState<Message[]>([
    {
      role: 'assistant',
      content: 'Hello! I\'m the InfiniteMind AI Assistant. How can I help you today?'
    }
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const sendMessage = useCallback(async (content: string) => {
    try {
      setIsLoading(true);
      setMessages(prev => [...prev, { role: 'user', content }]);

      // Check if OpenAI API key is configured
      if (!import.meta.env.VITE_OPENAI_API_KEY) {
        throw new Error('API key not configured');
      }

      const openai = new OpenAI({
        apiKey: import.meta.env.VITE_OPENAI_API_KEY,
        dangerouslyAllowBrowser: true
      });

      const response = await openai.chat.completions.create({
        model: 'gpt-3.5-turbo',
        messages: [
          {
            role: 'system',
            content: `You are an AI assistant for InfiniteMind, a digital consulting company specializing in AI, digital transformation, and business innovation. Be professional, knowledgeable, and helpful. Keep responses concise and focused.`
          },
          ...messages.map(msg => ({ role: msg.role, content: msg.content })),
          { role: 'user', content }
        ],
        temperature: 0.7,
        max_tokens: 150,
        presence_penalty: 0.6
      });

      const reply = response.choices[0]?.message?.content;
      if (reply) {
        setMessages(prev => [...prev, { role: 'assistant', content: reply }]);
        retryCount = 0; // Reset retry count on successful response
      }
    } catch (error: any) {
      console.error('Chat error:', error);
      
      // Handle specific error cases
      let errorMessage: string;
      
      if (error.code === 'insufficient_quota') {
        errorMessage = "I'm currently experiencing high demand. Please try again later or contact our team directly.";
      } else if (error.code === 'rate_limit_exceeded') {
        if (retryCount < MAX_RETRIES) {
          retryCount++;
          // Wait and retry with exponential backoff
          await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, retryCount)));
          return sendMessage(content);
        }
        errorMessage = "I'm receiving too many requests right now. Please try again in a moment.";
      } else {
        // Use rotating fallback responses for other errors
        errorMessage = FALLBACK_RESPONSES[Math.floor(Math.random() * FALLBACK_RESPONSES.length)];
      }

      setMessages(prev => [...prev, { role: 'assistant', content: errorMessage }]);
    } finally {
      setIsLoading(false);
    }
  }, [messages]);

  return { messages, isLoading, sendMessage };
}