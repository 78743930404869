import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

interface Node {
  x: number;
  y: number;
  vx: number;
  vy: number;
  radius: number;
  connections: number[];
}

export default function AINetworkAnimation() {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const setCanvasSize = () => {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    };
    setCanvasSize();
    window.addEventListener('resize', setCanvasSize);

    // Create nodes
    const nodes: Node[] = Array.from({ length: 30 }, () => ({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      vx: (Math.random() - 0.5) * 0.5,
      vy: (Math.random() - 0.5) * 0.5,
      radius: Math.random() * 2 + 1,
      connections: []
    }));

    // Create connections
    nodes.forEach((node, i) => {
      const connectionCount = Math.floor(Math.random() * 3) + 1;
      for (let j = 0; j < connectionCount; j++) {
        const targetIndex = Math.floor(Math.random() * nodes.length);
        if (targetIndex !== i && !node.connections.includes(targetIndex)) {
          node.connections.push(targetIndex);
        }
      }
    });

    let time = 0;
    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      time += 0.001;

      // Update and draw nodes
      nodes.forEach((node, i) => {
        // Update position with smooth wave motion
        node.x += node.vx + Math.sin(time + i) * 0.5;
        node.y += node.vy + Math.cos(time + i) * 0.5;

        // Bounce off edges
        if (node.x < 0 || node.x > canvas.width) node.vx *= -1;
        if (node.y < 0 || node.y > canvas.height) node.vy *= -1;

        // Keep within bounds
        node.x = Math.max(0, Math.min(canvas.width, node.x));
        node.y = Math.max(0, Math.min(canvas.height, node.y));

        // Draw connections with gradient and pulse effect
        node.connections.forEach(targetIndex => {
          const target = nodes[targetIndex];
          const dx = target.x - node.x;
          const dy = target.y - node.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < 200) {
            const gradient = ctx.createLinearGradient(node.x, node.y, target.x, target.y);
            const alpha = (1 - distance / 200) * 0.5 * (Math.sin(time * 1000 + i) * 0.5 + 0.5);
            
            gradient.addColorStop(0, `rgba(255, 107, 0, ${alpha})`);
            gradient.addColorStop(1, `rgba(255, 133, 51, ${alpha})`);

            ctx.beginPath();
            ctx.strokeStyle = gradient;
            ctx.lineWidth = 1;
            ctx.moveTo(node.x, node.y);
            ctx.lineTo(target.x, target.y);
            ctx.stroke();

            // Draw data flow particles
            const particleCount = 3;
            for (let p = 0; p < particleCount; p++) {
              const t = (time * 2 + p / particleCount) % 1;
              const px = node.x + dx * t;
              const py = node.y + dy * t;

              const particleGradient = ctx.createRadialGradient(px, py, 0, px, py, 3);
              particleGradient.addColorStop(0, `rgba(255, 107, 0, ${alpha * 2})`);
              particleGradient.addColorStop(1, 'rgba(255, 107, 0, 0)');

              ctx.beginPath();
              ctx.fillStyle = particleGradient;
              ctx.arc(px, py, 3, 0, Math.PI * 2);
              ctx.fill();
            }
          }
        });

        // Draw node with glow effect
        const gradient = ctx.createRadialGradient(node.x, node.y, 0, node.x, node.y, node.radius * 4);
        gradient.addColorStop(0, `rgba(255, 107, 0, ${0.8 * (Math.sin(time * 1000 + i) * 0.5 + 0.5)})`);
        gradient.addColorStop(1, 'rgba(255, 107, 0, 0)');

        ctx.beginPath();
        ctx.fillStyle = gradient;
        ctx.arc(node.x, node.y, node.radius * 4, 0, Math.PI * 2);
        ctx.fill();

        ctx.beginPath();
        ctx.fillStyle = '#FF6B00';
        ctx.arc(node.x, node.y, node.radius, 0, Math.PI * 2);
        ctx.fill();
      });

      requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener('resize', setCanvasSize);
    };
  }, []);

  return (
    <div className="absolute inset-0 pointer-events-none">
      <canvas
        ref={canvasRef}
        className="w-full h-full opacity-30"
      />
    </div>
  );
}