import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { AnimationLoop } from './automation-flow/core/AnimationLoop';
import { FlowRenderer } from './automation-flow/renderers/FlowRenderer';
import { FlowSimulation } from './automation-flow/simulation/FlowSimulation';
import { getFlowConfig } from './automation-flow/FlowConfigs';

interface AIAutomationFlowProps {
  preview?: boolean;
  industry?: string;
  processType?: string;
  technology?: string;
}

export default function AIAutomationFlow({ 
  preview = false,
  industry = 'All Industries',
  processType = 'All Processes',
  technology = 'All Technologies'
}: AIAutomationFlowProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const setCanvasSize = () => {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    };
    setCanvasSize();
    window.addEventListener('resize', setCanvasSize);

    const padding = preview ? 50 : 100;
    const spacing = preview ? 150 : 200;

    const flowConfig = getFlowConfig(industry, processType, technology, padding, spacing);
    const renderer = new FlowRenderer(ctx);
    const simulation = new FlowSimulation(flowConfig.nodes);
    const animation = new AnimationLoop(renderer, simulation, canvas);

    animation.start();

    return () => {
      window.removeEventListener('resize', setCanvasSize);
      animation.stop();
    };
  }, [preview, industry, processType, technology]);

  return (
    <motion.div 
      className={`relative ${preview ? 'h-[400px]' : 'h-[600px]'} overflow-hidden`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <canvas
        ref={canvasRef}
        className="absolute inset-0 w-full h-full"
      />
    </motion.div>
  );
}