import React from 'react';

const sections = {
  Company: [
    'About Us',
    'Careers',
    'Contact'
  ],
  Resources: [
    'Blog',
    'Case Studies',
    'Documentation',
    'Research'
  ]
};

export default function FooterLinks() {
  return (
    <div className="lg:col-span-6 grid grid-cols-2 md:grid-cols-2 gap-4">
      {Object.entries(sections).map(([category, items]) => (
        <div key={category}>
          <h3 className="text-base font-light text-white mb-2">{category}</h3>
          <ul className="space-y-1">
            {items.map((item) => (
              <li key={item}>
                <span className="text-xs text-white/70 cursor-default">
                  {item}
                </span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}