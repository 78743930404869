import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { TrendingUp, Brain, Target, LineChart } from 'lucide-react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const metrics = [
  {
    title: 'Revenue Growth',
    value: '+28.5%',
    trend: 12.4,
    icon: <TrendingUp className="w-5 h-5" />
  },
  {
    title: 'AI Accuracy',
    value: '98.2%',
    trend: 2.1,
    icon: <Brain className="w-5 h-5" />
  },
  {
    title: 'Market Share',
    value: '32%',
    trend: 5.3,
    icon: <Target className="w-5 h-5" />
  },
  {
    title: 'ROI',
    value: '285%',
    trend: 15.2,
    icon: <LineChart className="w-5 h-5" />
  }
];

export default function CEODashboard() {
  const [period] = useState('24H');

  const lineChartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [{
      label: 'Performance',
      data: [65, 78, 85, 82, 90, 95],
      borderColor: '#FF6B00',
      tension: 0.4,
      fill: false
    }]
  };

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: { 
      legend: { display: false },
      tooltip: {
        backgroundColor: '#000000',
        titleColor: '#ffffff',
        bodyColor: '#ffffff',
        borderColor: '#FF6B00',
        borderWidth: 1,
        padding: 12
      }
    },
    scales: {
      x: { 
        grid: { display: false },
        ticks: { color: '#ffffff80' }
      },
      y: { 
        grid: { color: '#ffffff10' },
        ticks: { color: '#ffffff80' }
      }
    }
  };

  const barChartData = {
    labels: ['Product', 'Services', 'Consulting', 'Support'],
    datasets: [{
      data: [35, 25, 25, 15],
      backgroundColor: [
        'rgba(255, 107, 0, 0.8)',
        'rgba(255, 107, 0, 0.6)',
        'rgba(255, 107, 0, 0.4)',
        'rgba(255, 107, 0, 0.2)'
      ]
    }]
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: { 
      legend: { display: false },
      tooltip: {
        backgroundColor: '#000000',
        titleColor: '#ffffff',
        bodyColor: '#ffffff',
        borderColor: '#FF6B00',
        borderWidth: 1,
        padding: 12
      }
    },
    scales: {
      x: { 
        grid: { display: false },
        ticks: { color: '#ffffff80' }
      },
      y: { 
        grid: { color: '#ffffff10' },
        ticks: { color: '#ffffff80' }
      }
    }
  };

  return (
    <div className="space-y-8">
      {/* Key Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {metrics.map((metric, index) => (
          <motion.div
            key={index}
            className="bg-dark p-6 rounded-lg border border-primary/10"
            whileHover={{ y: -5 }}
            transition={{ duration: 0.2 }}
          >
            <div className="flex items-center justify-between mb-4">
              <div className="p-2 bg-primary/10 rounded-lg text-primary">
                {metric.icon}
              </div>
              <span className={`text-sm ${metric.trend > 0 ? 'text-green-500' : 'text-red-500'}`}>
                {metric.trend > 0 ? '+' : ''}{metric.trend}%
              </span>
            </div>
            <div className="text-2xl font-light mb-1">{metric.value}</div>
            <div className="text-sm text-white/50">{metric.title}</div>
          </motion.div>
        ))}
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="bg-dark p-6 rounded-lg border border-primary/10">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-lg font-light">Performance Trends</h3>
            <div className="flex space-x-2">
              {['24H', '7D', '30D'].map((p) => (
                <button
                  key={p}
                  className={`px-3 py-1 text-sm rounded-full ${
                    period === p 
                      ? 'bg-primary text-white' 
                      : 'text-white/50 hover:text-white'
                  }`}
                >
                  {p}
                </button>
              ))}
            </div>
          </div>
          <div className="h-[300px]">
            <Line data={lineChartData} options={lineChartOptions} />
          </div>
        </div>

        <div className="bg-dark p-6 rounded-lg border border-primary/10">
          <h3 className="text-lg font-light mb-6">Revenue Distribution</h3>
          <div className="h-[300px]">
            <Bar data={barChartData} options={barChartOptions} />
          </div>
        </div>
      </div>

      {/* Additional Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {['Projects', 'Team Size', 'Client Satisfaction'].map((metric, index) => (
          <motion.div
            key={metric}
            className="bg-dark p-6 rounded-lg border border-primary/10"
            whileHover={{ y: -5 }}
            transition={{ duration: 0.2 }}
          >
            <div className="text-sm text-white/50 mb-2">{metric}</div>
            <div className="text-2xl font-light">
              {index === 0 ? '45 Active' : index === 1 ? '128' : '94%'}
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}