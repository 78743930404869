import React from 'react';
import { motion } from 'framer-motion';
import { Flag, Award, Users, Milestone } from 'lucide-react';
import FadeIn from '../../../components/animations/FadeIn';

const milestones = [
  {
    year: '2018',
    title: 'Foundation',
    description: 'InfiniteMind was founded with a vision to transform digital landscapes.',
    icon: <Flag className="w-6 h-6" />
  },
  {
    year: '2019',
    title: 'First Major Project',
    description: 'Successfully delivered AI-powered solutions for Fortune 500 companies.',
    icon: <Award className="w-6 h-6" />
  },
  {
    year: '2021',
    title: 'Global Expansion',
    description: 'Expanded operations to multiple continents with diverse talent.',
    icon: <Users className="w-6 h-6" />
  },
  {
    year: '2024',
    title: 'Innovation Hub',
    description: 'Launched cutting-edge research facility for AI and emerging tech.',
    icon: <Milestone className="w-6 h-6" />
  }
];

export default function StoryTimeline() {
  return (
    <section className="py-32 bg-dark relative overflow-hidden">
      <motion.div 
        className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(255,107,0,0.05),rgba(10,10,10,0))]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />
      
      <div className="container mx-auto px-6 relative">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          <FadeIn delay={0.2}>
            <div className="relative">
              <div className="aspect-[4/3] relative rounded-lg overflow-hidden">
                <img 
                  src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?auto=format&fit=crop&q=80&w=2000" 
                  alt="Our team collaboration"
                  className="object-cover w-full h-full"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-dark via-dark/50 to-transparent opacity-60" />
              </div>
              
              <div className="absolute -bottom-8 -right-8 bg-dark-lighter p-6 rounded-lg shadow-xl">
                <div className="grid grid-cols-2 gap-6">
                  <div className="text-center">
                    <div className="text-3xl font-light text-primary mb-2">200+</div>
                    <div className="text-sm text-white/70">Team Members</div>
                  </div>
                  <div className="text-center">
                    <div className="text-3xl font-light text-primary mb-2">50+</div>
                    <div className="text-sm text-white/70">Countries</div>
                  </div>
                </div>
              </div>
            </div>
          </FadeIn>

          <FadeIn delay={0.4}>
            <div className="space-y-8">
              {milestones.map((milestone, index) => (
                <div 
                  key={index}
                  className="relative pl-8 before:content-[''] before:absolute before:left-[7px] before:top-10 before:w-[2px] before:h-full before:bg-primary/20 last:before:h-0"
                >
                  <div className="absolute left-0 top-2 w-4 h-4 rounded-full bg-primary/20 border-2 border-primary" />
                  <div className="text-primary mb-2">{milestone.year}</div>
                  <div className="flex items-start space-x-4">
                    <div className="p-3 bg-primary/10 rounded-lg text-primary">
                      {milestone.icon}
                    </div>
                    <div>
                      <h3 className="text-xl font-light mb-2">{milestone.title}</h3>
                      <p className="text-white/70">{milestone.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </FadeIn>
        </div>
      </div>
    </section>
  );
}