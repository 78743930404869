import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import FadeIn from '../../../components/animations/FadeIn';

export default function WorkHero() {
  return (
    <section className="pt-32 pb-20 relative overflow-hidden">
      <motion.div 
        className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,rgba(255,107,0,0.08),rgba(10,10,10,0))]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />
      
      <div className="container mx-auto px-6 relative">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          <FadeIn>
            <div>
              <span className="text-primary uppercase tracking-[0.2em] text-sm mb-6 block">
                Our Work
              </span>
              <h1 className="text-5xl md:text-6xl font-light mb-6">
                Transforming Ideas into
                <span className="block mt-2 gradient-text">
                  Digital Reality
                </span>
              </h1>
              <p className="text-xl text-white/70 leading-relaxed mb-8">
                Explore our portfolio of innovative solutions that have helped businesses 
                achieve digital excellence and transform their operations.
              </p>
              
              <div className="flex flex-col sm:flex-row items-start space-y-4 sm:space-y-0 sm:space-x-6">
                <button className="px-8 py-4 bg-primary text-white hover:bg-primary-light transition-colors duration-300 flex items-center space-x-2 group">
                  <span>View Case Studies</span>
                  <ArrowRight className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
                </button>
                <button className="group flex items-center space-x-2 text-lg px-8 py-4">
                  <span className="text-white/70 group-hover:text-white transition-colors">
                    Our Process
                  </span>
                  <ArrowRight className="w-5 h-5 transform group-hover:translate-x-2 transition-transform text-primary" />
                </button>
              </div>
            </div>
          </FadeIn>

          <FadeIn delay={0.2}>
            <div className="relative">
              <div className="aspect-[4/3] relative rounded-lg overflow-hidden">
                <img 
                  src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80&w=2000" 
                  alt="Digital transformation visualization"
                  className="object-cover w-full h-full"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-dark via-dark/50 to-transparent opacity-60" />
              </div>
              
              <div className="absolute -bottom-8 -right-8 bg-dark-lighter p-6 rounded-lg shadow-xl">
                <div className="grid grid-cols-2 gap-6">
                  <div className="text-center">
                    <div className="text-3xl font-light text-primary mb-2">500+</div>
                    <div className="text-sm text-white/70">Projects</div>
                  </div>
                  <div className="text-center">
                    <div className="text-3xl font-light text-primary mb-2">98%</div>
                    <div className="text-sm text-white/70">Success Rate</div>
                  </div>
                </div>
              </div>
            </div>
          </FadeIn>
        </div>
      </div>
    </section>
  );
}