import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import FadeIn from '../../../../components/animations/FadeIn';

interface CaseStudyCardProps {
  study: {
    title: string;
    category: string;
    description: string;
    image: string;
    stats: Array<{ label: string; value: string }>;
    technologies: string[];
    link: string;
  };
  index: number;
}

export default function CaseStudyCard({ study, index }: CaseStudyCardProps) {
  return (
    <FadeIn delay={0.1 * index}>
      <motion.div 
        className="group cursor-pointer"
        whileHover={{ y: -5 }}
        transition={{ duration: 0.3 }}
      >
        <div className="relative">
          <div className="absolute -inset-0.5 bg-gradient-to-r from-primary/20 to-primary-light/20 rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-500" />
          
          <div className="relative bg-dark-lighter rounded-lg overflow-hidden">
            <div className="aspect-[16/9] relative overflow-hidden">
              <img 
                src={study.image} 
                alt={study.title}
                className="object-cover w-full h-full transform group-hover:scale-110 transition-transform duration-700"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-dark-lighter via-dark-lighter/50 to-transparent opacity-60 group-hover:opacity-80 transition-opacity duration-300" />
            </div>
            
            <div className="p-8">
              <div className="flex items-center justify-between mb-4">
                <span className="text-primary text-sm">{study.category}</span>
                <motion.div 
                  className="flex items-center text-primary"
                  initial={{ opacity: 0, x: 20 }}
                  whileHover={{ opacity: 1, x: 0 }}
                >
                  <span className="mr-2">View Case Study</span>
                  <ArrowRight className="w-4 h-4" />
                </motion.div>
              </div>
              
              <h3 className="text-2xl font-light mb-4 group-hover:text-primary transition-colors">
                {study.title}
              </h3>
              
              <p className="text-white/70 mb-6">{study.description}</p>
              
              <div className="grid grid-cols-3 gap-4 mb-6">
                {study.stats.map((stat, i) => (
                  <div key={i}>
                    <div className="text-xl text-primary mb-1">{stat.value}</div>
                    <div className="text-sm text-white/50">{stat.label}</div>
                  </div>
                ))}
              </div>
              
              <div className="flex flex-wrap gap-2">
                {study.technologies.map((tech, i) => (
                  <span 
                    key={i}
                    className="px-3 py-1 bg-dark rounded-full text-sm text-white/50"
                  >
                    {tech}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </FadeIn>
  );
}