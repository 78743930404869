import { ProcessNode } from '../types/ProcessNode';

export class ConnectionRenderer {
  constructor(private ctx: CanvasRenderingContext2D) {}

  draw(source: ProcessNode, target: ProcessNode, time: number) {
    const progress = (Math.sin(time * 2) + 1) / 2;
    
    // Draw connection path with curve
    this.ctx.beginPath();
    const controlPointX = (source.x + target.x) / 2;
    this.ctx.moveTo(source.x, source.y);
    this.ctx.quadraticCurveTo(controlPointX, source.y, target.x, target.y);
    this.ctx.strokeStyle = 'rgba(255, 107, 0, 0.2)';
    this.ctx.lineWidth = 2;
    this.ctx.stroke();

    // Draw flowing particles
    if (source.status === 'processing' || source.status === 'complete') {
      this.drawParticles(source, target, controlPointX, progress);
    }
  }

  private drawParticles(source: ProcessNode, target: ProcessNode, controlPointX: number, progress: number) {
    const particleCount = 3;
    for (let i = 0; i < particleCount; i++) {
      const offset = ((progress + i / particleCount) % 1);
      const t = offset;
      
      const x = Math.pow(1 - t, 2) * source.x + 
               2 * (1 - t) * t * controlPointX + 
               Math.pow(t, 2) * target.x;
      const y = Math.pow(1 - t, 2) * source.y + 
               2 * (1 - t) * t * source.y + 
               Math.pow(t, 2) * target.y;

      const gradient = this.ctx.createRadialGradient(x, y, 0, x, y, 6);
      gradient.addColorStop(0, 'rgba(255, 107, 0, 0.8)');
      gradient.addColorStop(1, 'rgba(255, 107, 0, 0)');

      this.ctx.beginPath();
      this.ctx.arc(x, y, 6, 0, Math.PI * 2);
      this.ctx.fillStyle = gradient;
      this.ctx.fill();
    }
  }
}