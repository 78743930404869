import React from 'react';
import { ArrowRight } from 'lucide-react';
import FadeIn from '../../../components/animations/FadeIn';

const blogPosts = [
  {
    title: 'The Future of Software Development with AI',
    excerpt: 'Exploring how artificial intelligence is revolutionizing software development practices...',
    image: 'https://images.unsplash.com/photo-1677442136019-21780ecad995?auto=format&fit=crop&q=80&w=1200',
    category: 'Technology Trends',
    date: 'Mar 15, 2024',
    readTime: '7 min'
  },
  {
    title: 'Cloud-Native Development Best Practices',
    excerpt: 'Key strategies for building scalable and resilient cloud applications...',
    image: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80&w=1200',
    category: 'Cloud Computing',
    date: 'Mar 12, 2024',
    readTime: '5 min'
  },
  {
    title: 'Cybersecurity in the Age of AI',
    excerpt: 'How artificial intelligence is transforming cybersecurity landscape...',
    image: 'https://images.unsplash.com/photo-1620712943543-bcc4688e7485?auto=format&fit=crop&q=80&w=1200',
    category: 'Security',
    date: 'Mar 10, 2024',
    readTime: '6 min'
  }
];

export default function BlogGrid() {
  return (
    <section className="py-20 bg-dark-lighter relative">
      <div className="container mx-auto px-6">
        <FadeIn>
          <div className="flex justify-between items-center mb-12">
            <h2 className="text-3xl font-light">Latest Insights</h2>
            <a href="/blog" className="group flex items-center text-white/70 hover:text-white">
              <span>View All</span>
              <ArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" />
            </a>
          </div>
        </FadeIn>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogPosts.map((post, index) => (
            <FadeIn key={index} delay={0.1 * index}>
              <article className="group cursor-pointer">
                <div className="relative aspect-[16/9] overflow-hidden rounded-lg mb-6">
                  <img 
                    src={post.image} 
                    alt={post.title}
                    className="object-cover w-full h-full transform group-hover:scale-110 transition-transform duration-700"
                  />
                </div>
                <div className="space-y-4">
                  <div className="flex items-center text-sm text-white/50 space-x-4">
                    <span>{post.category}</span>
                    <span>•</span>
                    <span>{post.date}</span>
                    <span>•</span>
                    <span>{post.readTime}</span>
                  </div>
                  <h3 className="text-xl font-light group-hover:text-primary transition-colors">
                    {post.title}
                  </h3>
                  <p className="text-white/70">{post.excerpt}</p>
                </div>
              </article>
            </FadeIn>
          ))}
        </div>
      </div>
    </section>
  );
}