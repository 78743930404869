export interface ProcessNode {
  id: string;
  type: 'input' | 'process' | 'output' | 'ai';
  x: number;
  y: number;
  label: string;
  connections: string[];
  status: 'idle' | 'processing' | 'complete';
}

export const createProcessNode = (
  id: string,
  type: ProcessNode['type'],
  x: number,
  y: number,
  label: string,
  connections: string[] = []
): ProcessNode => ({
  id,
  type,
  x,
  y,
  label,
  connections,
  status: 'idle'
});