import React, { useState } from 'react';
import { motion } from 'framer-motion';
import CaseStudyHero from './components/CaseStudyHero';
import CaseStudyFilter from './components/CaseStudyFilter';
import CaseStudyCard from './components/CaseStudyCard';
import ContactCTA from '../../../components/shared/ContactCTA';

const categories = ['All', 'AI & ML', 'Digital Transformation', 'Experience Design', 'Development', 'Cloud Solutions'];

const caseStudies = [
  {
    title: 'AI-Powered Healthcare Platform',
    category: 'AI & ML',
    description: 'Revolutionizing patient care through intelligent diagnostics and predictive analytics.',
    image: 'https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?auto=format&fit=crop&q=80&w=2000',
    stats: [
      { label: 'Diagnostic Accuracy', value: '98%' },
      { label: 'Processing Time', value: '-60%' },
      { label: 'Patient Satisfaction', value: '95%' }
    ],
    technologies: ['TensorFlow', 'Python', 'React', 'AWS'],
    link: '#case-study-1'
  },
  {
    title: 'Global Financial Services Transformation',
    category: 'Digital Transformation',
    description: 'Modernizing legacy systems and implementing cloud-native solutions for a Fortune 500 bank.',
    image: 'https://images.unsplash.com/photo-1563986768609-322da13575f3?auto=format&fit=crop&q=80&w=2000',
    stats: [
      { label: 'Transaction Speed', value: '+300%' },
      { label: 'Operating Costs', value: '-40%' },
      { label: 'System Uptime', value: '99.99%' }
    ],
    technologies: ['Kubernetes', 'Microservices', 'Azure', 'Node.js'],
    link: '#case-study-2'
  },
  {
    title: 'E-commerce UX Redesign',
    category: 'Experience Design',
    description: 'Complete redesign of user experience leading to significant increase in conversion rates.',
    image: 'https://images.unsplash.com/photo-1661956602116-aa6865609028?auto=format&fit=crop&q=80&w=2000',
    stats: [
      { label: 'Conversion Rate', value: '+75%' },
      { label: 'User Engagement', value: '+120%' },
      { label: 'Cart Value', value: '+45%' }
    ],
    technologies: ['Figma', 'React', 'Next.js', 'TailwindCSS'],
    link: '#case-study-3'
  },
  {
    title: 'Enterprise IoT Platform',
    category: 'Development',
    description: 'Scalable IoT platform managing millions of connected devices in real-time.',
    image: 'https://images.unsplash.com/photo-1558346490-a72e53ae2d4f?auto=format&fit=crop&q=80&w=2000',
    stats: [
      { label: 'Connected Devices', value: '1M+' },
      { label: 'Data Processing', value: '500TB/day' },
      { label: 'Response Time', value: '<50ms' }
    ],
    technologies: ['Rust', 'gRPC', 'TimescaleDB', 'Kafka'],
    link: '#case-study-4'
  },
  {
    title: 'Smart City Infrastructure',
    category: 'Digital Transformation',
    description: 'Implementing IoT sensors and AI analytics for urban infrastructure management.',
    image: 'https://images.unsplash.com/photo-1620825937374-87fc7d6bddc2?auto=format&fit=crop&q=80&w=2000',
    stats: [
      { label: 'Energy Savings', value: '35%' },
      { label: 'Maintenance Costs', value: '-45%' },
      { label: 'Incident Response', value: '-60%' }
    ],
    technologies: ['IoT Sensors', 'AI/ML', 'Big Data', 'Cloud'],
    link: '#case-study-5'
  },
  {
    title: 'Supply Chain Optimization',
    category: 'AI & ML',
    description: 'AI-driven supply chain optimization reducing costs and improving efficiency.',
    image: 'https://images.unsplash.com/photo-1586528116311-ad8dd3c8310d?auto=format&fit=crop&q=80&w=2000',
    stats: [
      { label: 'Inventory Costs', value: '-30%' },
      { label: 'Delivery Time', value: '-25%' },
      { label: 'Accuracy', value: '99.9%' }
    ],
    technologies: ['Machine Learning', 'Python', 'AWS', 'Blockchain'],
    link: '#case-study-6'
  },
  {
    title: 'Cloud Migration Strategy',
    category: 'Cloud Solutions',
    description: 'Enterprise-wide cloud migration and modernization for a global manufacturer.',
    image: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80&w=2000',
    stats: [
      { label: 'Cost Reduction', value: '45%' },
      { label: 'Performance', value: '+200%' },
      { label: 'Deployment Time', value: '-70%' }
    ],
    technologies: ['AWS', 'Terraform', 'Docker', 'Kubernetes'],
    link: '#case-study-7'
  },
  {
    title: 'Digital Banking Platform',
    category: 'Development',
    description: 'Next-generation digital banking platform with AI-powered personalization.',
    image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80&w=2000',
    stats: [
      { label: 'User Growth', value: '+150%' },
      { label: 'Transaction Volume', value: '10M/day' },
      { label: 'Customer Satisfaction', value: '94%' }
    ],
    technologies: ['React Native', 'Node.js', 'MongoDB', 'AI'],
    link: '#case-study-8'
  },
  {
    title: 'Retail Analytics Platform',
    category: 'AI & ML',
    description: 'Real-time retail analytics and inventory optimization using computer vision.',
    image: 'https://images.unsplash.com/photo-1441986300917-64674bd600d8?auto=format&fit=crop&q=80&w=2000',
    stats: [
      { label: 'Sales Increase', value: '+40%' },
      { label: 'Inventory Accuracy', value: '99.5%' },
      { label: 'Shrinkage Reduction', value: '-60%' }
    ],
    technologies: ['Computer Vision', 'TensorFlow', 'Edge Computing', 'React'],
    link: '#case-study-9'
  },
  {
    title: 'Cybersecurity Enhancement',
    category: 'Digital Transformation',
    description: 'Implementation of AI-powered threat detection and response system.',
    image: 'https://images.unsplash.com/photo-1550751827-4bd374c3f58b?auto=format&fit=crop&q=80&w=2000',
    stats: [
      { label: 'Threat Detection', value: '<1min' },
      { label: 'False Positives', value: '-85%' },
      { label: 'Response Time', value: '-90%' }
    ],
    technologies: ['AI/ML', 'SIEM', 'Blockchain', 'Zero Trust'],
    link: '#case-study-10'
  }
];

export default function CaseStudiesPage() {
  const [selectedCategory, setSelectedCategory] = useState('All');

  const filteredStudies = selectedCategory === 'All'
    ? caseStudies
    : caseStudies.filter(study => study.category === selectedCategory);

  return (
    <div className="bg-dark text-white min-h-screen">
      <CaseStudyHero />
      
      <section className="py-20 relative">
        <motion.div 
          className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(255,107,0,0.05),rgba(10,10,10,0))]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
        />
        
        <div className="container mx-auto px-6 relative">
          <CaseStudyFilter
            categories={categories}
            selectedCategory={selectedCategory}
            onCategorySelect={setSelectedCategory}
          />
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {filteredStudies.map((study, index) => (
              <CaseStudyCard key={index} study={study} index={index} />
            ))}
          </div>
        </div>
      </section>

      <ContactCTA />
    </div>
  );
}