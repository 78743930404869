import React from 'react';
import InsightsHero from './components/InsightsHero';
import BlogGrid from './components/BlogGrid';
import ResearchSection from './components/ResearchSection';
import ContactCTA from '../../components/shared/ContactCTA';

export default function InsightsPage() {
  return (
    <div className="bg-dark text-white">
      <InsightsHero />
      <BlogGrid />
      <ResearchSection />
      <ContactCTA />
    </div>
  );
}