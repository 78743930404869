import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Send, Upload } from 'lucide-react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { submitForm } from '../../utils/forms/formSubmission';

const formSchema = z.object({
  fullName: z.string().min(2, 'Name must be at least 2 characters'),
  email: z.string().email('Please enter a valid email address'),
  phone: z.string().regex(/^\+?[\d\s-]{10,}$/, 'Please enter a valid phone number'),
  coverLetter: z.string().min(100, 'Cover letter must be at least 100 characters'),
  portfolio: z.string().url().optional(),
  linkedin: z.string().url().optional(),
  cv: z.any()
    .refine((file) => file?.length === 1, 'CV is required')
    .refine(
      (file) => file?.[0]?.size <= 5000000,
      'Max file size is 5MB'
    )
    .refine(
      (file) => ['application/pdf'].includes(file?.[0]?.type),
      'Only PDF files are allowed'
    ),
});

type FormData = z.infer<typeof formSchema>;

interface ApplicationFormProps {
  position: {
    id: string;
    title: string;
    department: string;
    location: string;
  };
  onClose: () => void;
}

export default function ApplicationForm({ position, onClose }: ApplicationFormProps) {
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');
  
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (data: FormData) => {
    try {
      const formData = new FormData();
      formData.append('position', position.title);
      formData.append('department', position.department);
      formData.append('location', position.location);
      formData.append('fullName', data.fullName);
      formData.append('email', data.email);
      formData.append('phone', data.phone);
      formData.append('coverLetter', data.coverLetter);
      if (data.portfolio) formData.append('portfolio', data.portfolio);
      if (data.linkedin) formData.append('linkedin', data.linkedin);
      formData.append('cv', data.cv[0]);

      await submitForm(formData, 'application');
      setSubmitStatus('success');
      reset();
      setTimeout(() => {
        setSubmitStatus('idle');
        onClose();
      }, 3000);
    } catch (error) {
      console.error('Form submission error:', error);
      setSubmitStatus('error');
      setTimeout(() => setSubmitStatus('idle'), 5000);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Full Name */}
        <div>
          <label htmlFor="fullName" className="block text-sm text-white/70 mb-2">
            Full Name <span className="text-primary">*</span>
          </label>
          <input
            {...register('fullName')}
            type="text"
            id="fullName"
            className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
            placeholder="Enter your full name"
          />
          {errors.fullName && (
            <p className="mt-1 text-sm text-red-400">{errors.fullName.message}</p>
          )}
        </div>

        {/* Email */}
        <div>
          <label htmlFor="email" className="block text-sm text-white/70 mb-2">
            Email <span className="text-primary">*</span>
          </label>
          <input
            {...register('email')}
            type="email"
            id="email"
            className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
            placeholder="Enter your email address"
          />
          {errors.email && (
            <p className="mt-1 text-sm text-red-400">{errors.email.message}</p>
          )}
        </div>
      </div>

      {/* Phone */}
      <div>
        <label htmlFor="phone" className="block text-sm text-white/70 mb-2">
          Phone Number <span className="text-primary">*</span>
        </label>
        <input
          {...register('phone')}
          type="tel"
          id="phone"
          className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
          placeholder="Enter your phone number"
        />
        {errors.phone && (
          <p className="mt-1 text-sm text-red-400">{errors.phone.message}</p>
        )}
      </div>

      {/* CV Upload */}
      <div>
        <label htmlFor="cv" className="block text-sm text-white/70 mb-2">
          CV (PDF) <span className="text-primary">*</span>
        </label>
        <div className="relative">
          <input
            {...register('cv')}
            type="file"
            id="cv"
            accept=".pdf"
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          />
          <div className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 border border-primary/10 flex items-center justify-between">
            <span className="text-white/30">Upload your CV</span>
            <Upload className="w-5 h-5 text-primary" />
          </div>
        </div>
        {errors.cv && (
          <p className="mt-1 text-sm text-red-400">{errors.cv.message as string}</p>
        )}
      </div>

      {/* Cover Letter */}
      <div>
        <label htmlFor="coverLetter" className="block text-sm text-white/70 mb-2">
          Cover Letter <span className="text-primary">*</span>
        </label>
        <textarea
          {...register('coverLetter')}
          id="coverLetter"
          rows={4}
          className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors resize-none"
          placeholder="Tell us why you're interested in this position"
        />
        {errors.coverLetter && (
          <p className="mt-1 text-sm text-red-400">{errors.coverLetter.message}</p>
        )}
      </div>

      {/* Portfolio URL */}
      <div>
        <label htmlFor="portfolio" className="block text-sm text-white/70 mb-2">
          Portfolio URL
        </label>
        <input
          {...register('portfolio')}
          type="url"
          id="portfolio"
          className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
          placeholder="https://your-portfolio.com"
        />
        {errors.portfolio && (
          <p className="mt-1 text-sm text-red-400">{errors.portfolio.message}</p>
        )}
      </div>

      {/* LinkedIn URL */}
      <div>
        <label htmlFor="linkedin" className="block text-sm text-white/70 mb-2">
          LinkedIn Profile
        </label>
        <input
          {...register('linkedin')}
          type="url"
          id="linkedin"
          className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
          placeholder="https://linkedin.com/in/your-profile"
        />
        {errors.linkedin && (
          <p className="mt-1 text-sm text-red-400">{errors.linkedin.message}</p>
        )}
      </div>

      {/* Status Messages */}
      {submitStatus === 'success' && (
        <div className="p-4 bg-green-500/10 text-green-500 rounded-lg">
          Your application has been submitted successfully! We'll be in touch soon.
        </div>
      )}
      
      {submitStatus === 'error' && (
        <div className="p-4 bg-red-500/10 text-red-500 rounded-lg">
          There was an error submitting your application. Please try again.
        </div>
      )}

      {/* Submit Button */}
      <motion.button
        type="submit"
        className="w-full px-8 py-4 bg-primary text-white hover:bg-primary-light transition-colors duration-300 flex items-center justify-center space-x-2 group disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={isSubmitting}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        <span>{isSubmitting ? 'Submitting...' : 'Submit Application'}</span>
        <Send className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
      </motion.button>
    </form>
  );
}