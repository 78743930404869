import { ProcessNode } from '../types/ProcessNode';
import { ConnectionRenderer } from './ConnectionRenderer';
import { NodeRenderer } from './NodeRenderer';

export class FlowRenderer {
  private time: number = 0;
  private connectionRenderer: ConnectionRenderer;
  private nodeRenderer: NodeRenderer;

  constructor(private ctx: CanvasRenderingContext2D) {
    this.connectionRenderer = new ConnectionRenderer(ctx);
    this.nodeRenderer = new NodeRenderer(ctx);
  }

  drawConnection(source: ProcessNode, target: ProcessNode) {
    this.connectionRenderer.draw(source, target, this.time);
  }

  drawNode(node: ProcessNode) {
    this.nodeRenderer.draw(node, this.time);
  }

  updateTime(delta: number) {
    this.time += delta;
  }

  clear(width: number, height: number) {
    this.ctx.clearRect(0, 0, width, height);
  }
}