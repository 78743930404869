import React from 'react';
import AboutHero from './components/AboutHero';
import StoryTimeline from './components/StoryTimeline';
import CultureSection from './components/CultureSection';
import CareersSection from '../../components/sections/about/CareersSection';
import ContactCTA from '../../components/shared/ContactCTA';

export default function AboutPage() {
  return (
    <div className="bg-dark text-white">
      <AboutHero />
      <StoryTimeline />
      <CultureSection />
      <CareersSection />
      <ContactCTA />
    </div>
  );
}