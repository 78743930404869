import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Brain, Code, Globe, Target, LineChart, Shield, Zap, Users, Layers, ChevronDown } from 'lucide-react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import AINetworkAnimation from '../animations/AINetworkAnimation';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const departments = [
  {
    id: 'engineering',
    icon: <Code className="w-6 h-6" />,
    title: 'Engineering',
    metrics: {
      productivity: 92,
      quality: 95,
      efficiency: 88
    }
  },
  {
    id: 'research',
    icon: <Brain className="w-6 h-6" />,
    title: 'Research & Development',
    metrics: {
      productivity: 94,
      quality: 96,
      efficiency: 90
    }
  },
  {
    id: 'operations',
    icon: <Zap className="w-6 h-6" />,
    title: 'Operations',
    metrics: {
      productivity: 89,
      quality: 92,
      efficiency: 87
    }
  }
];

export default function AIDepartmentDashboard() {
  const [selectedDepartment, setSelectedDepartment] = useState(departments[0]);

  const performanceData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [{
      label: 'Performance',
      data: [65, 78, 85, 82, 90, 95],
      borderColor: '#FF6B00',
      backgroundColor: 'rgba(255, 107, 0, 0.1)',
      tension: 0.4,
      fill: true
    }]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: { 
      legend: { display: false },
      tooltip: {
        backgroundColor: '#000000',
        titleColor: '#ffffff',
        bodyColor: '#ffffff',
        borderColor: '#FF6B00',
        borderWidth: 1,
        padding: 12,
        displayColors: false
      }
    },
    scales: {
      x: { 
        grid: { 
          display: false,
          drawBorder: false
        },
        ticks: { color: '#ffffff80' }
      },
      y: { 
        grid: { 
          color: '#ffffff10',
          drawBorder: false
        },
        ticks: { color: '#ffffff80' }
      }
    }
  };

  return (
    <div className="space-y-8 relative">
      <AINetworkAnimation />
      
      <div className="relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
          {departments.map((dept) => (
            <motion.button
              key={dept.id}
              onClick={() => setSelectedDepartment(dept)}
              className={`p-4 rounded-lg transition-colors ${
                selectedDepartment.id === dept.id
                  ? 'bg-primary text-white'
                  : 'bg-dark text-white/70 hover:bg-primary/10'
              }`}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <div className="flex items-center space-x-3">
                {dept.icon}
                <span>{dept.title}</span>
              </div>
            </motion.button>
          ))}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Performance Dashboard */}
          <div className="bg-dark/50 backdrop-blur-sm p-8 rounded-lg border border-primary/10">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-lg font-light">Department Performance</h3>
              <div className="p-2 bg-primary/10 rounded-lg">
                {selectedDepartment.icon}
              </div>
            </div>

            <div className="h-[300px]">
              <Line 
                data={performanceData} 
                options={chartOptions}
                key={selectedDepartment.id} // Force re-render on department change
              />
            </div>
          </div>

          {/* Department Metrics */}
          <div className="space-y-6">
            <div className="bg-dark/50 backdrop-blur-sm p-6 rounded-lg border border-primary/10">
              <h3 className="text-lg font-light mb-6">Key Metrics</h3>
              <div className="space-y-6">
                {Object.entries(selectedDepartment.metrics).map(([key, value]) => (
                  <div key={key} className="flex items-center justify-between">
                    <span className="text-white/70 capitalize">
                      {key.replace(/([A-Z])/g, ' $1').trim()}
                    </span>
                    <div className="flex items-center space-x-3">
                      <div className="w-48 h-2 bg-dark rounded-full overflow-hidden">
                        <motion.div
                          className="h-full bg-primary"
                          initial={{ width: 0 }}
                          animate={{ width: `${value}%` }}
                          transition={{ duration: 1, delay: 0.2 }}
                        />
                      </div>
                      <span className="text-lg min-w-[3ch] text-right">{value}%</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              {[
                { label: 'AI Models', value: '45+' },
                { label: 'Processing Power', value: '12.5 TFLOPS' },
                { label: 'Data Processed', value: '8.2 TB/day' },
                { label: 'Accuracy Rate', value: '99.2%' }
              ].map((metric, index) => (
                <motion.div
                  key={index}
                  className="bg-dark/50 backdrop-blur-sm p-4 rounded-lg border border-primary/10"
                  whileHover={{ y: -2 }}
                >
                  <div className="text-white/50 text-sm mb-1">{metric.label}</div>
                  <div className="text-xl">{metric.value}</div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}