import React from 'react';
import { motion } from 'framer-motion';
import FadeIn from '../animations/FadeIn';
import { Brain, Code, Globe, Lightbulb, Rocket, Users } from 'lucide-react';

const capabilities = [
  {
    icon: <Brain className="w-8 h-8" />,
    title: "AI & Machine Learning",
    description: "Leveraging artificial intelligence to create intelligent and adaptive solutions.",
    stats: ["50+ AI Models", "98% Accuracy", "Real-time Processing"]
  },
  {
    icon: <Code className="w-8 h-8" />,
    title: "Digital Engineering",
    description: "Building robust digital platforms with cutting-edge technologies.",
    stats: ["Cloud-native", "Microservices", "DevOps Excellence"]
  },
  {
    icon: <Globe className="w-8 h-8" />,
    title: "Global Delivery",
    description: "Delivering excellence across continents with our distributed team.",
    stats: ["24/7 Support", "Global Presence", "Agile Teams"]
  },
  {
    icon: <Lightbulb className="w-8 h-8" />,
    title: "Innovation Lab",
    description: "Exploring emerging technologies to shape the future of digital.",
    stats: ["R&D Focus", "Rapid Prototyping", "Future-ready"]
  },
  {
    icon: <Users className="w-8 h-8" />,
    title: "Digital Culture",
    description: "Fostering digital transformation through organizational change.",
    stats: ["Change Management", "Digital Training", "Culture Shift"]
  },
  {
    icon: <Rocket className="w-8 h-8" />,
    title: "Growth Acceleration",
    description: "Accelerating business growth through data-driven strategies.",
    stats: ["ROI Focus", "Market Analysis", "Growth Hacking"]
  }
];

export default function Capabilities() {
  return (
    <section className="py-32 bg-dark-lighter relative overflow-hidden">
      <motion.div 
        className="absolute inset-0 bg-[radial-gradient(circle_at_70%_50%,rgba(255,107,0,0.05),rgba(10,10,10,0))]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />

      <div className="container mx-auto px-6 relative">
        <FadeIn>
          <div className="text-center mb-20">
            <span className="text-primary uppercase tracking-[0.2em] text-sm mb-4 block">
              Core Capabilities
            </span>
            <h2 className="text-4xl md:text-5xl font-light mb-6">
              Driving Digital Excellence
            </h2>
            <p className="text-white/70 text-lg max-w-2xl mx-auto">
              Our comprehensive suite of capabilities enables us to deliver 
              transformative solutions across industries.
            </p>
          </div>
        </FadeIn>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {capabilities.map((capability, index) => (
            <FadeIn key={index} delay={0.2 * index}>
              <motion.div 
                className="group cursor-pointer"
                whileHover={{ y: -5 }}
                transition={{ duration: 0.3 }}
              >
                <div className="relative">
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-primary/20 to-primary-light/20 rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-500" />
                  
                  <div className="relative bg-dark p-8 rounded-lg border border-primary/10 group-hover:border-primary/20 transition-colors duration-300">
                    <div className="text-primary/50 mb-6 group-hover:text-primary transition-colors duration-300">
                      {capability.icon}
                    </div>
                    
                    <h3 className="text-2xl font-light mb-4 text-white/90 group-hover:text-primary transition-colors duration-300">
                      {capability.title}
                    </h3>
                    
                    <p className="text-white/70 mb-8 leading-relaxed">
                      {capability.description}
                    </p>
                    
                    <div className="grid grid-cols-3 gap-4">
                      {capability.stats.map((stat, i) => (
                        <div key={i} className="text-sm text-white/50">{stat}</div>
                      ))}
                    </div>
                  </div>
                </div>
              </motion.div>
            </FadeIn>
          ))}
        </div>
      </div>
    </section>
  );
}