import React from 'react';
import { motion } from 'framer-motion';

interface DiagonalArrowProps {
  className?: string;
  size?: number;
  color?: string;
  strokeWidth?: number;
  animate?: boolean;
}

export default function DiagonalArrow({
  className = '',
  size = 40,
  color = '#FF6B00',
  strokeWidth = 3,
  animate = true
}: DiagonalArrowProps) {
  const arrowVariants = {
    initial: { pathLength: 0, opacity: 0 },
    animate: {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { duration: 0.8, ease: "easeInOut" },
        opacity: { duration: 0.2 }
      }
    }
  };

  return (
    <div className={`inline-flex items-center justify-center ${className}`}>
      <motion.svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        initial={animate ? "initial" : undefined}
        animate={animate ? "animate" : undefined}
      >
        {/* Arrow Border/Background */}
        <motion.path
          d="M10 30L30 10"
          stroke={color}
          strokeWidth={strokeWidth + 2}
          strokeLinecap="round"
          strokeLinejoin="round"
          opacity={0.2}
        />
        
        {/* Main Arrow Line */}
        <motion.path
          d="M10 30L30 10"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          variants={arrowVariants}
        />
        
        {/* Arrowhead */}
        <motion.path
          d="M30 10L20 10M30 10L30 20"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          variants={arrowVariants}
        />
      </motion.svg>
    </div>
  );
}