import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import FadeIn from '../animations/FadeIn';
import FormDialog from '../forms/FormDialog';
import PreFooterAnimation from '../animations/PreFooterAnimation';

export default function ContactCTA() {
  const [formType, setFormType] = useState<'start-project' | 'contact' | null>(null);

  return (
    <>
      <section className="py-24 bg-dark-lighter relative overflow-hidden">
        <PreFooterAnimation />
        
        <div className="container mx-auto px-6 relative">
          <FadeIn>
            <div className="text-center max-w-3xl mx-auto">
              <h2 className="text-4xl md:text-5xl font-light mb-6">
                Ready to Transform Your
                <span className="block mt-2 gradient-text">Digital Future?</span>
              </h2>
              <p className="text-white/70 text-lg max-w-2xl mx-auto">
                Let's discuss how we can help you achieve your digital transformation goals.
              </p>
              <motion.button 
                onClick={() => setFormType('start-project')}
                className="mt-8 px-8 py-4 bg-primary text-white hover:bg-primary-light transition-colors duration-300 flex items-center space-x-2 group mx-auto"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <span>Start a Project</span>
                <ArrowRight className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
              </motion.button>
            </div>
          </FadeIn>
        </div>
      </section>

      <FormDialog 
        isOpen={formType !== null}
        onClose={() => setFormType(null)}
        type={formType || 'contact'}
      />
    </>
  );
}