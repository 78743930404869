import React from 'react';
import { motion } from 'framer-motion';
import { Target, Rocket, Shield, Layers } from 'lucide-react';
import FadeIn from '../animations/FadeIn';

const steps = [
  {
    icon: <Target className="w-8 h-8" />,
    number: '01',
    title: 'Measurable Impact',
    description: 'Every engagement is tied to clear KPIs and outcomes, ensuring tangible business value.',
    color: 'from-primary/20 to-primary-light/20'
  },
  {
    icon: <Rocket className="w-8 h-8" />,
    number: '02',
    title: 'Hands-On Execution',
    description: 'Our senior partners work alongside your team to ensure flawless implementation.',
    color: 'from-primary/30 to-primary-light/30'
  },
  {
    icon: <Shield className="w-8 h-8" />,
    number: '03',
    title: 'Future-Proof Solutions',
    description: 'We focus on innovation and resilience, equipping businesses to thrive in dynamic markets.',
    color: 'from-primary/40 to-primary-light/40'
  },
  {
    icon: <Layers className="w-8 h-8" />,
    number: '04',
    title: 'Holistic Transformation',
    description: 'From leadership to technology, InfiniteMind addresses every aspect of growth.',
    color: 'from-primary/50 to-primary-light/50'
  }
];

export default function RoadmapSection() {
  return (
    <section className="py-16 bg-dark relative overflow-hidden">
      <motion.div 
        className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(255,107,0,0.05),rgba(10,10,10,0))]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />
      
      <div className="container mx-auto px-6 relative">
        <FadeIn>
          <div className="text-center mb-12">
            <span className="text-primary uppercase tracking-[0.2em] text-sm mb-3 block">
              Our Approach
            </span>
            <h2 className="text-4xl md:text-5xl font-light mb-4">
              The Path to
              <span className="block mt-2 gradient-text">Business Excellence & Performance</span>
            </h2>
            <p className="text-white/70 text-lg max-w-2xl mx-auto">
              A proven methodology that ensures consistent delivery of high-quality 
              solutions that exceed expectations and drive business growth.
            </p>
          </div>
        </FadeIn>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {steps.map((step, index) => (
            <FadeIn key={index} delay={0.1 * index}>
              <motion.div 
                className="group cursor-pointer"
                whileHover={{ y: -5 }}
                transition={{ duration: 0.3 }}
              >
                <div className="relative">
                  <div className={`absolute -inset-0.5 bg-gradient-to-br ${step.color} rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-500`} />
                  
                  <div className="relative bg-dark-lighter p-6 rounded-lg">
                    <div className="text-primary/50 mb-4 group-hover:text-primary transition-colors duration-300">
                      {step.icon}
                    </div>
                    
                    <div className="text-3xl font-light text-primary/30 mb-4 group-hover:text-primary transition-colors duration-300">
                      {step.number}
                    </div>
                    
                    <h3 className="text-lg font-light mb-2 text-white/90 group-hover:text-primary transition-colors">
                      {step.title}
                    </h3>
                    
                    <p className="text-sm text-white/70">
                      {step.description}
                    </p>
                  </div>
                </div>
              </motion.div>
            </FadeIn>
          ))}
        </div>
      </div>
    </section>
  );
}