import React, { useState, useEffect } from 'react';
import { Menu, X, Cpu } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { LogoHorizontal } from '../logo';
import ActionModal from '../forms/ActionModals';
import CareersModal from '../careers/CareersModal';
import LocationsModal from '../locations/LocationsModal';
import TeamModal from '../team/TeamModal';
import DiagonalArrow from '../ui/DiagonalArrow';
import ConsultingNav from '../navigation/ConsultingNav';
import VerticalNav from '../navigation/VerticalNav';

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [actionType, setActionType] = useState<'contact' | 'project' | 'work' | null>(null);
  const [isCareersModalOpen, setCareersModalOpen] = useState(false);
  const [isLocationsModalOpen, setLocationsModalOpen] = useState(false);
  const [isTeamModalOpen, setTeamModalOpen] = useState(false);
  const [isConsultingOpen, setConsultingOpen] = useState(false);
  const [isAutomationsOpen, setAutomationsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <header 
        className={`fixed w-full z-50 transition-all duration-500 ${
          isScrolled ? 'bg-dark/90 backdrop-blur-sm' : 'bg-transparent'
        }`}
        role="banner"
      >
        <div className="container mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <Link to="/">
              <LogoHorizontal color="white" />
            </Link>

            <nav className="hidden md:flex items-center">
              {/* Primary Menu Group */}
              <div className="flex items-center space-x-8 mr-12 border-r border-white pr-12">
                <div
                  className="relative"
                  onMouseEnter={() => setAutomationsOpen(true)}
                  onMouseLeave={() => setAutomationsOpen(false)}
                >
                  <Link 
                    to="/" 
                    className="flex items-center space-x-2 text-base text-white hover:text-white/80 transition-colors duration-300"
                  >
                    <span>Business Automations</span>
                    <Cpu className="w-4 h-4" />
                  </Link>
                </div>
                <div
                  className="relative"
                  onMouseEnter={() => setConsultingOpen(true)}
                  onMouseLeave={() => setConsultingOpen(false)}
                >
                  <Link 
                    to="/consulting" 
                    className="flex items-center space-x-2 text-base text-primary hover:text-primary-light transition-colors duration-300"
                  >
                    <span>Business Co-Pilot</span>
                    <DiagonalArrow size={16} className="transform group-hover:translate-x-1 transition-transform" />
                  </Link>
                </div>
              </div>

              {/* Secondary Menu Group */}
              <div className="flex items-center space-x-4">
                <button 
                  onClick={() => setLocationsModalOpen(true)}
                  className="text-sm text-white/70 hover:text-white transition-colors duration-300"
                >
                  Locations
                </button>

                <button 
                  onClick={() => setCareersModalOpen(true)}
                  className="text-sm text-white/70 hover:text-white transition-colors duration-300"
                >
                  Careers
                </button>

                <button 
                  onClick={() => setTeamModalOpen(true)}
                  className="text-sm text-white/70 hover:text-white transition-colors duration-300"
                >
                  Team
                </button>

                <button 
                  onClick={() => setActionType('contact')}
                  className="ml-4 px-4 py-1.5 text-sm border border-primary text-primary hover:bg-primary hover:text-white transition-all duration-300"
                  aria-label="Contact us"
                >
                  Let's Talk
                </button>
              </div>
            </nav>

            <button 
              className="md:hidden text-primary p-2"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-expanded={isMenuOpen}
              aria-controls="mobile-menu"
              aria-label={isMenuOpen ? "Close menu" : "Open menu"}
            >
              {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
            </button>
          </div>
        </div>
      </header>

      <ConsultingNav isOpen={isConsultingOpen} />
      <VerticalNav isOpen={isAutomationsOpen} />

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            id="mobile-menu"
            className="md:hidden fixed inset-0 bg-dark z-40"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
          >
            <div className="container mx-auto px-6 py-20">
              <nav className="flex flex-col">
                {/* Primary Menu Group - Mobile */}
                <div className="space-y-4 mb-6 border-b border-white/10 pb-6">
                  <Link 
                    to="/" 
                    className="flex items-center space-x-2 text-base text-white hover:text-white/80 transition-colors duration-300"
                  >
                    <span>Business Automations</span>
                    <Cpu className="w-4 h-4" />
                  </Link>
                  <Link 
                    to="/consulting" 
                    className="flex items-center space-x-2 text-base text-primary hover:text-primary-light transition-colors duration-300"
                  >
                    <span>Business Co-Pilot</span>
                    <DiagonalArrow size={16} className="transform group-hover:translate-x-1 transition-transform" />
                  </Link>
                </div>

                {/* Secondary Menu Group - Mobile */}
                <div className="space-y-4">
                  <button 
                    onClick={() => {
                      setIsMenuOpen(false);
                      setLocationsModalOpen(true);
                    }}
                    className="text-sm text-white/70 hover:text-white transition-colors duration-300 text-left w-full"
                  >
                    Locations
                  </button>

                  <button 
                    onClick={() => {
                      setIsMenuOpen(false);
                      setCareersModalOpen(true);
                    }}
                    className="text-sm text-white/70 hover:text-white transition-colors duration-300 text-left w-full"
                  >
                    Careers
                  </button>

                  <button 
                    onClick={() => {
                      setIsMenuOpen(false);
                      setTeamModalOpen(true);
                    }}
                    className="text-sm text-white/70 hover:text-white transition-colors duration-300 text-left w-full"
                  >
                    Team
                  </button>

                  <button 
                    onClick={() => {
                      setIsMenuOpen(false);
                      setActionType('contact');
                    }}
                    className="w-full px-4 py-1.5 text-sm border border-primary text-primary hover:bg-primary hover:text-white transition-all duration-300 mt-4"
                  >
                    Let's Talk
                  </button>
                </div>
              </nav>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <ActionModal 
        isOpen={actionType !== null}
        onClose={() => setActionType(null)}
        type={actionType || 'contact'}
      />

      <CareersModal
        isOpen={isCareersModalOpen}
        onClose={() => setCareersModalOpen(false)}
      />

      <TeamModal
        isOpen={isTeamModalOpen}
        onClose={() => setTeamModalOpen(false)}
      />

      <LocationsModal
        isOpen={isLocationsModalOpen}
        onClose={() => setLocationsModalOpen(false)}
      />
    </>
  );
}