import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import * as Dialog from '@radix-ui/react-dialog';
import FadeIn from '../animations/FadeIn';
import StartProjectForm from './StartProjectForm';
import ContactForm from './ContactForm';

interface FormDialogProps {
  isOpen: boolean;
  onClose: () => void;
  type: 'start-project' | 'contact' | null;
}

export default function FormDialog({ isOpen, onClose, type }: FormDialogProps) {
  const title = type === 'start-project' ? 'Start Your Project' : "Let's Talk";
  const description = type === 'start-project' 
    ? 'Fill out the form below to start your project with InfiniteMind.'
    : 'Get in touch with us to discuss your needs or just say hello.';

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay asChild>
          <motion.div
            className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        </Dialog.Overlay>
        
        <Dialog.Content asChild>
          <motion.div
            className="fixed inset-4 overflow-y-auto md:inset-auto md:left-1/2 md:top-1/2 md:-translate-x-1/2 md:-translate-y-1/2 md:max-w-4xl md:w-full bg-dark-lighter rounded-lg shadow-xl z-50 max-h-[90vh]"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div className="relative">
              {/* Header */}
              <div className="sticky top-0 z-10 bg-dark-lighter p-6 md:p-8 border-b border-primary/10">
                <div className="flex items-center justify-between">
                  <FadeIn>
                    <Dialog.Title className="text-2xl md:text-3xl font-light">
                      {title}
                    </Dialog.Title>
                  </FadeIn>
                  <Dialog.Close asChild>
                    <button 
                      className="text-white/50 hover:text-white transition-colors"
                      aria-label="Close dialog"
                    >
                      <X className="w-6 h-6" />
                    </button>
                  </Dialog.Close>
                </div>
                <Dialog.Description className="mt-2 text-white/70">
                  {description}
                </Dialog.Description>
              </div>

              {/* Content */}
              <div className="p-6 md:p-8">
                <div className="max-w-2xl mx-auto">
                  {type === 'start-project' ? <StartProjectForm /> : <ContactForm />}
                </div>
              </div>
            </div>
          </motion.div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}