import React from 'react';
import ServiceHero from './components/ServiceHero';
import ServiceGrid from './components/ServiceGrid';
import ContactCTA from '../../components/shared/ContactCTA';

export default function ServicesPage() {
  return (
    <div className="bg-dark text-white">
      <ServiceHero />
      <ServiceGrid />
      <ContactCTA />
    </div>
  );
}