import React from 'react';
import { motion } from 'framer-motion';
import { X, Code, Users, Rocket, Heart, Globe, Book, Coffee } from 'lucide-react';
import * as Dialog from '@radix-ui/react-dialog';
import { useNavigate } from 'react-router-dom';
import FadeIn from '../animations/FadeIn';

const benefits = [
  {
    icon: <Code className="w-5 h-5" />,
    title: 'Innovation',
    description: 'Work on cutting-edge technologies'
  },
  {
    icon: <Users className="w-5 h-5" />,
    title: 'Great Team',
    description: 'Collaborate with global talent'
  },
  {
    icon: <Heart className="w-5 h-5" />,
    title: 'Work-Life Balance',
    description: 'Flexible working arrangements'
  },
  {
    icon: <Globe className="w-5 h-5" />,
    title: 'Global Impact',
    description: 'Transform businesses worldwide'
  },
  {
    icon: <Book className="w-5 h-5" />,
    title: 'Learning',
    description: 'Continuous development'
  },
  {
    icon: <Coffee className="w-5 h-5" />,
    title: 'Culture',
    description: 'Inclusive environment'
  }
];

interface CareersModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function CareersModal({ isOpen, onClose }: CareersModalProps) {
  const navigate = useNavigate();

  const handleViewPositions = () => {
    onClose();
    navigate('/careers/positions');
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay asChild>
          <motion.div
            className="fixed inset-0 bg-black/90 backdrop-blur-sm z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        </Dialog.Overlay>
        
        <Dialog.Content asChild>
          <motion.div
            className="fixed inset-4 md:inset-8 bg-dark-lighter rounded-lg shadow-xl z-50 overflow-hidden"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div className="h-full flex flex-col relative z-10">
              {/* Header */}
              <div className="p-6 md:p-8 flex items-center justify-between">
                <FadeIn>
                  <Dialog.Title className="text-2xl font-light">
                    Join Our Team
                  </Dialog.Title>
                </FadeIn>
                <Dialog.Close asChild>
                  <button 
                    className="text-white/50 hover:text-white transition-colors"
                    aria-label="Close dialog"
                  >
                    <X className="w-6 h-6" />
                  </button>
                </Dialog.Close>
              </div>

              {/* Content */}
              <div className="flex-1 overflow-y-auto p-6 md:p-8">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                  {benefits.map((benefit, index) => (
                    <FadeIn key={index} delay={0.1 * index}>
                      <motion.div
                        className="relative group"
                        whileHover={{ y: -5 }}
                        transition={{ duration: 0.3 }}
                      >
                        {/* Artistic Background Element */}
                        <div className="absolute -inset-2 bg-gradient-to-r from-primary/5 via-primary/10 to-primary/5 rounded-lg blur-lg opacity-0 group-hover:opacity-100 transition-all duration-700" />
                        
                        <div className="relative space-y-4">
                          {/* Minimal Line Decoration */}
                          <div className="w-12 h-[1px] bg-primary/30 group-hover:w-full transition-all duration-700" />
                          
                          <div className="p-3 bg-primary/10 rounded-lg w-fit">
                            <div className="text-primary/70">
                              {benefit.icon}
                            </div>
                          </div>
                          
                          <div className="space-y-2">
                            <h3 className="text-lg font-light text-white/90 group-hover:text-primary transition-colors duration-300">
                              {benefit.title}
                            </h3>
                            <p className="text-sm text-white/50">
                              {benefit.description}
                            </p>
                          </div>
                        </div>
                      </motion.div>
                    </FadeIn>
                  ))}
                </div>

                <FadeIn delay={0.6}>
                  <div className="mt-16 pt-8 border-t border-white/5 text-center">
                    <motion.button
                      onClick={handleViewPositions}
                      className="px-8 py-3 bg-primary text-white hover:bg-primary-light transition-colors duration-300 inline-flex items-center space-x-2"
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <span>View Open Positions</span>
                      <Rocket className="w-4 h-4" />
                    </motion.button>
                  </div>
                </FadeIn>
              </div>
            </div>
          </motion.div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}