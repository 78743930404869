import React, { useState } from 'react';
import { motion } from 'framer-motion';
import TextTransitionAutomations from '../animations/TextTransitionAutomations';
import FadeIn from '../animations/FadeIn';
import StartProjectModal from '../project/StartProjectModal';
import ActionButton from '../ui/ActionButton';

export default function AutomationsHero() {
  const [isProjectModalOpen, setProjectModalOpen] = useState(false);

  return (
    <>
      <section className="min-h-screen bg-black text-white relative overflow-hidden">
        {/* Hero Background Image */}
        <div className="absolute inset-0">
          <img 
            src="https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?auto=format&fit=crop&q=80&w=2000" 
            alt="Modern business district at night"
            className="object-cover w-full h-full opacity-50"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-black via-black/95 to-black/90" />
          {/* Orange and Blue Accent Overlays */}
          <div className="absolute inset-0 bg-gradient-radial from-orange-500/10 via-transparent to-transparent opacity-30" />
          <div className="absolute inset-0 bg-gradient-radial from-blue-500/10 via-transparent to-transparent opacity-30" style={{ transform: 'translate(50%, 50%)' }} />
        </div>
        
        <div className="container mx-auto px-6 relative">
          <div className="min-h-screen grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            {/* Content Side */}
            <FadeIn>
              <div className="max-w-2xl mx-auto lg:mx-0">
                <h1 className="text-5xl md:text-6xl font-light mb-8">
                  We Shape your
                  <span className="block mt-4 gradient-text">
                    Digital Future
                  </span>
                </h1>
                
                <div className="mb-12">
                  <TextTransitionAutomations />
                </div>
                
                <ActionButton 
                  onClick={() => setProjectModalOpen(true)}
                  className="px-8 py-4 text-lg"
                >
                  Transform Your Business
                </ActionButton>
              </div>
            </FadeIn>
          </div>
        </div>
      </section>

      <StartProjectModal 
        isOpen={isProjectModalOpen}
        onClose={() => setProjectModalOpen(false)}
      />
    </>
  );
}