import React from 'react';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import * as Dialog from '@radix-ui/react-dialog';
import FadeIn from '../animations/FadeIn';
import ApplicationForm from './ApplicationForm';

interface ApplicationModalProps {
  isOpen: boolean;
  onClose: () => void;
  position: {
    id: string;
    title: string;
    department: string;
    location: string;
  };
}

export default function ApplicationModal({ isOpen, onClose, position }: ApplicationModalProps) {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay asChild>
          <motion.div
            className="fixed inset-0 bg-black/90 backdrop-blur-sm z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        </Dialog.Overlay>
        
        <Dialog.Content asChild>
          <motion.div
            className="fixed inset-4 md:inset-8 bg-dark-lighter rounded-lg shadow-xl z-50 overflow-hidden"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div className="h-full flex flex-col relative z-10">
              {/* Header */}
              <div className="p-6 md:p-8 border-b border-primary/10">
                <div className="flex items-center justify-between">
                  <FadeIn>
                    <div>
                      <Dialog.Title className="text-2xl md:text-3xl font-light">
                        Apply for {position.title}
                      </Dialog.Title>
                      <Dialog.Description className="mt-2 text-white/70">
                        {position.department} • {position.location}
                      </Dialog.Description>
                    </div>
                  </FadeIn>
                  <Dialog.Close asChild>
                    <button 
                      className="text-white/50 hover:text-white transition-colors"
                      aria-label="Close dialog"
                    >
                      <X className="w-6 h-6" />
                    </button>
                  </Dialog.Close>
                </div>
              </div>

              {/* Content */}
              <div className="flex-1 overflow-y-auto p-6 md:p-8">
                <div className="max-w-2xl mx-auto">
                  <ApplicationForm position={position} onClose={onClose} />
                </div>
              </div>
            </div>
          </motion.div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}