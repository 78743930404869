import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Send } from 'lucide-react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { submitForm } from '../../utils/forms/formSubmission';
import type { FormData } from '../../utils/forms/types';

const formSchema = z.object({
  fullName: z.string().min(2, 'Name must be at least 2 characters'),
  email: z.string().email('Please enter a valid email address'),
  phone: z.string().regex(/^\+?[\d\s-]{10,}$/, 'Please enter a valid phone number'),
  subject: z.string().min(2, 'Subject must be at least 2 characters'),
  message: z.string().min(10, 'Message must be at least 10 characters'),
  inquiryType: z.enum(['general', 'partnership', 'career', 'other']),
  newsletter: z.boolean().optional(),
});

export default function ContactForm() {
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');
  
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (data: FormData) => {
    try {
      await submitForm(data, 'contact');
      setSubmitStatus('success');
      reset();
      setTimeout(() => setSubmitStatus('idle'), 5000);
    } catch (error) {
      console.error('Form submission error:', error);
      setSubmitStatus('error');
      setTimeout(() => setSubmitStatus('idle'), 5000);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Full Name */}
        <div>
          <label htmlFor="fullName" className="block text-sm text-white/70 mb-2">
            Full Name <span className="text-primary">*</span>
          </label>
          <input
            {...register('fullName')}
            type="text"
            id="fullName"
            className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
            placeholder="Enter your full name"
          />
          {errors.fullName && (
            <p className="mt-1 text-sm text-red-400">{errors.fullName.message}</p>
          )}
        </div>

        {/* Email */}
        <div>
          <label htmlFor="email" className="block text-sm text-white/70 mb-2">
            Email <span className="text-primary">*</span>
          </label>
          <input
            {...register('email')}
            type="email"
            id="email"
            className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
            placeholder="Enter your email address"
          />
          {errors.email && (
            <p className="mt-1 text-sm text-red-400">{errors.email.message}</p>
          )}
        </div>
      </div>

      {/* Phone */}
      <div>
        <label htmlFor="phone" className="block text-sm text-white/70 mb-2">
          Phone Number <span className="text-primary">*</span>
        </label>
        <input
          {...register('phone')}
          type="tel"
          id="phone"
          className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
          placeholder="Enter your phone number"
        />
        {errors.phone && (
          <p className="mt-1 text-sm text-red-400">{errors.phone.message}</p>
        )}
      </div>

      {/* Inquiry Type */}
      <div>
        <label htmlFor="inquiryType" className="block text-sm text-white/70 mb-2">
          Inquiry Type <span className="text-primary">*</span>
        </label>
        <select
          {...register('inquiryType')}
          id="inquiryType"
          className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
        >
          <option value="">Select inquiry type</option>
          <option value="general">General Question</option>
          <option value="partnership">Partnership</option>
          <option value="career">Career</option>
          <option value="other">Other</option>
        </select>
        {errors.inquiryType && (
          <p className="mt-1 text-sm text-red-400">{errors.inquiryType.message}</p>
        )}
      </div>

      {/* Subject */}
      <div>
        <label htmlFor="subject" className="block text-sm text-white/70 mb-2">
          Subject <span className="text-primary">*</span>
        </label>
        <input
          {...register('subject')}
          type="text"
          id="subject"
          className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
          placeholder="Enter message subject"
        />
        {errors.subject && (
          <p className="mt-1 text-sm text-red-400">{errors.subject.message}</p>
        )}
      </div>

      {/* Message */}
      <div>
        <label htmlFor="message" className="block text-sm text-white/70 mb-2">
          Message <span className="text-primary">*</span>
        </label>
        <textarea
          {...register('message')}
          id="message"
          rows={4}
          className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors resize-none"
          placeholder="Enter your message"
        />
        {errors.message && (
          <p className="mt-1 text-sm text-red-400">{errors.message.message}</p>
        )}
      </div>

      {/* Newsletter Subscription */}
      <div className="flex items-start space-x-3">
        <input
          {...register('newsletter')}
          type="checkbox"
          id="newsletter"
          className="mt-1 bg-dark border-primary/30 text-primary focus:ring-primary/30"
        />
        <label htmlFor="newsletter" className="text-sm text-white/70">
          Subscribe to our newsletter for updates and insights
        </label>
      </div>

      {/* Status Messages */}
      {submitStatus === 'success' && (
        <div className="p-4 bg-green-500/10 text-green-500 rounded-lg">
          Thank you for your message. We will get back to you soon!
        </div>
      )}
      
      {submitStatus === 'error' && (
        <div className="p-4 bg-red-500/10 text-red-500 rounded-lg">
          There was an error sending your message. Please try again.
        </div>
      )}

      {/* Submit Button */}
      <motion.button
        type="submit"
        className="w-full px-8 py-4 bg-primary text-white hover:bg-primary-light transition-colors duration-300 flex items-center justify-center space-x-2 group disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={isSubmitting}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        <span>{isSubmitting ? 'Sending...' : 'Send Message'}</span>
        <Send className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
      </motion.button>
    </form>
  );
}