import React from 'react';
import { motion } from 'framer-motion';
import { X, Linkedin, Twitter, Github } from 'lucide-react';
import * as Dialog from '@radix-ui/react-dialog';
import FadeIn from '../animations/FadeIn';
import AINetworkAnimation from '../animations/AINetworkAnimation';

const teamMembers = [
  {
    name: 'Tarek Bougroug',
    role: 'CEO & Founder',
    image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&q=80&w=600',
    bio: 'Visionary leader with 20+ years in digital transformation.',
    social: {
      linkedin: '#',
      twitter: '#',
      github: '#'
    }
  },
  {
    name: 'Michael Rodriguez',
    role: 'CTO',
    image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&q=80&w=600',
    bio: 'Tech innovator specializing in AI and cloud architecture.',
    social: {
      linkedin: '#',
      twitter: '#',
      github: '#'
    }
  },
  {
    name: 'Emily Watson',
    role: 'Design Director',
    image: 'https://images.unsplash.com/photo-1580489944761-15a19d654956?auto=format&fit=crop&q=80&w=600',
    bio: 'Award-winning designer with a passion for user experience.',
    social: {
      linkedin: '#',
      twitter: '#'
    }
  },
  {
    name: 'David Kim',
    role: 'Head of AI',
    image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&q=80&w=600',
    bio: 'AI researcher and practitioner driving innovation.',
    social: {
      linkedin: '#',
      github: '#'
    }
  },
  {
    name: 'Sarah Chen',
    role: 'Product Director',
    image: 'https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?auto=format&fit=crop&q=80&w=600',
    bio: 'Product strategist focused on user-centric solutions.',
    social: {
      linkedin: '#',
      twitter: '#'
    }
  },
  {
    name: 'James Wilson',
    role: 'Cloud Architect',
    image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&q=80&w=600',
    bio: 'Expert in cloud infrastructure and scalable systems.',
    social: {
      linkedin: '#',
      github: '#'
    }
  }
];

interface TeamModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function TeamModal({ isOpen, onClose }: TeamModalProps) {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay asChild>
          <motion.div
            className="fixed inset-0 bg-black/90 backdrop-blur-sm z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        </Dialog.Overlay>
        
        <Dialog.Content asChild>
          <motion.div
            className="fixed inset-4 md:inset-8 bg-dark-lighter rounded-lg shadow-xl z-50 overflow-hidden"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div className="h-full flex flex-col relative z-10">
              {/* Header */}
              <div className="p-6 md:p-8 flex items-center justify-between">
                <FadeIn>
                  <Dialog.Title className="text-2xl font-light">
                    Our Team
                  </Dialog.Title>
                </FadeIn>
                <Dialog.Close asChild>
                  <button 
                    className="text-white/50 hover:text-white transition-colors"
                    aria-label="Close dialog"
                  >
                    <X className="w-6 h-6" />
                  </button>
                </Dialog.Close>
              </div>

              {/* Content */}
              <div className="flex-1 overflow-y-auto p-6 md:p-8">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                  {teamMembers.map((member, index) => (
                    <FadeIn key={index} delay={0.1 * index}>
                      <motion.div
                        className="relative group"
                        whileHover={{ y: -5 }}
                        transition={{ duration: 0.3 }}
                      >
                        <div className="absolute -inset-2 bg-gradient-to-r from-primary/5 via-primary/10 to-primary/5 rounded-lg blur-lg opacity-0 group-hover:opacity-100 transition-all duration-700" />
                        
                        <div className="relative p-4">
                          <div className="flex items-center space-x-4">
                            <div className="w-16 h-16 rounded-lg overflow-hidden flex-shrink-0">
                              <img 
                                src={member.image}
                                alt={member.name}
                                className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-700"
                              />
                            </div>
                            
                            <div className="flex-1 min-w-0">
                              <h3 className="text-lg font-light text-white/90 group-hover:text-primary transition-colors duration-300 truncate">
                                {member.name}
                              </h3>
                              <p className="text-sm text-primary/70 truncate">{member.role}</p>
                              <p className="text-sm text-white/50 mt-1 line-clamp-2">
                                {member.bio}
                              </p>
                              
                              <div className="flex space-x-3 mt-2">
                                {member.social.linkedin && (
                                  <a 
                                    href={member.social.linkedin}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-white/30 hover:text-primary transition-colors duration-300"
                                  >
                                    <Linkedin className="w-4 h-4" />
                                  </a>
                                )}
                                {member.social.twitter && (
                                  <a 
                                    href={member.social.twitter}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-white/30 hover:text-primary transition-colors duration-300"
                                  >
                                    <Twitter className="w-4 h-4" />
                                  </a>
                                )}
                                {member.social.github && (
                                  <a 
                                    href={member.social.github}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-white/30 hover:text-primary transition-colors duration-300"
                                  >
                                    <Github className="w-4 h-4" />
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    </FadeIn>
                  ))}
                </div>

                <FadeIn delay={0.4}>
                  <div className="mt-16 pt-8 border-t border-white/5">
                    <p className="text-center text-sm text-white/50">
                      Join our team of innovators and shape the future of digital transformation.
                    </p>
                  </div>
                </FadeIn>
              </div>
            </div>
          </motion.div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}