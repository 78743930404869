import React from 'react';
import { motion } from 'framer-motion';
import FadeIn from '../../../components/animations/FadeIn';

export default function AboutHero() {
  return (
    <section className="pt-32 pb-20 relative overflow-hidden">
      <motion.div 
        className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,rgba(255,107,0,0.08),rgba(10,10,10,0))]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />
      
      <div className="container mx-auto px-6 relative">
        <FadeIn>
          <div className="max-w-3xl">
            <span className="text-primary uppercase tracking-[0.2em] text-sm mb-6 block">
              About Us
            </span>
            <h1 className="text-5xl md:text-6xl font-light mb-6">
              Pioneering the Future of
              <span className="block mt-2 gradient-text">
                Digital Innovation
              </span>
            </h1>
            <p className="text-xl text-white/70 leading-relaxed">
              We are a team of visionaries, innovators, and technologists dedicated 
              to transforming businesses through cutting-edge digital solutions.
            </p>
          </div>
        </FadeIn>
      </div>
    </section>
  );
}