import { ProcessNode } from '../types/ProcessNode';

export class NodeRenderer {
  constructor(private ctx: CanvasRenderingContext2D) {}

  draw(node: ProcessNode, time: number) {
    const radius = 30;
    const glowIntensity = node.status === 'processing' ? 0.4 : 0.2;
    
    this.drawGlowEffect(node, radius, glowIntensity);
    this.drawNodeBody(node, radius);
    this.drawNodeBorder(node, radius, time);
    this.drawTypeIndicator(node);
    this.drawLabel(node, radius);
  }

  private drawGlowEffect(node: ProcessNode, radius: number, intensity: number) {
    const gradient = this.ctx.createRadialGradient(
      node.x, node.y, 0,
      node.x, node.y, radius * 2
    );
    gradient.addColorStop(0, `rgba(255, 107, 0, ${intensity})`);
    gradient.addColorStop(0.5, `rgba(255, 107, 0, ${intensity * 0.5})`);
    gradient.addColorStop(1, 'rgba(255, 107, 0, 0)');
    
    this.ctx.beginPath();
    this.ctx.arc(node.x, node.y, radius * 2, 0, Math.PI * 2);
    this.ctx.fillStyle = gradient;
    this.ctx.fill();
  }

  private drawNodeBody(node: ProcessNode, radius: number) {
    this.ctx.beginPath();
    this.ctx.arc(node.x, node.y, radius, 0, Math.PI * 2);
    this.ctx.fillStyle = node.status === 'complete' ? '#FF6B00' : '#1A1A1A';
    this.ctx.fill();
  }

  private drawNodeBorder(node: ProcessNode, radius: number, time: number) {
    this.ctx.beginPath();
    this.ctx.arc(node.x, node.y, radius, 0, Math.PI * 2);
    this.ctx.strokeStyle = `rgba(255, 107, 0, ${0.5 + Math.sin(time * 3) * 0.2})`;
    this.ctx.lineWidth = 2;
    this.ctx.stroke();
  }

  private drawTypeIndicator(node: ProcessNode) {
    const typeIndicator = {
      input: '→',
      process: '⚙',
      ai: '🧠',
      output: '✓'
    }[node.type];

    this.ctx.font = '14px Inter';
    this.ctx.fillStyle = 'rgba(255, 255, 255, 0.9)';
    this.ctx.textAlign = 'center';
    this.ctx.fillText(typeIndicator, node.x, node.y + 5);
  }

  private drawLabel(node: ProcessNode, radius: number) {
    this.ctx.font = '12px Inter';
    const labelWidth = this.ctx.measureText(node.label).width;
    const padding = 8;

    // Label background
    this.ctx.fillStyle = 'rgba(0, 0, 0, 0.7)';
    this.ctx.fillRect(
      node.x - labelWidth/2 - padding,
      node.y + radius + 10,
      labelWidth + padding * 2,
      20
    );

    // Label text
    this.ctx.fillStyle = 'rgba(255, 255, 255, 0.9)';
    this.ctx.textAlign = 'center';
    this.ctx.fillText(node.label, node.x, node.y + radius + 24);
  }
}