import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Code, Users, Globe, Rocket } from 'lucide-react';
import { Link } from 'react-router-dom';
import FadeIn from '../../animations/FadeIn';

const benefits = [
  {
    icon: <Code className="w-8 h-8" />,
    title: 'Innovation',
    description: 'Work on cutting-edge technologies and shape the future'
  },
  {
    icon: <Users className="w-8 h-8" />,
    title: 'Great Team',
    description: 'Collaborate with talented professionals worldwide'
  },
  {
    icon: <Globe className="w-8 h-8" />,
    title: 'Remote Work',
    description: 'Flexible work arrangements and global opportunities'
  },
  {
    icon: <Rocket className="w-8 h-8" />,
    title: 'Growth',
    description: 'Continuous learning and career development'
  }
];

export default function CareersSection() {
  return (
    <section id="careers" className="py-32 bg-dark relative overflow-hidden">
      <motion.div 
        className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(255,107,0,0.05),rgba(10,10,10,0))]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />
      
      <div className="container mx-auto px-6 relative">
        <FadeIn>
          <div className="text-center mb-16">
            <span className="text-primary uppercase tracking-[0.2em] text-sm mb-4 block">
              Careers
            </span>
            <h2 className="text-4xl md:text-5xl font-light mb-6">
              Join Our Mission to
              <span className="block mt-2 gradient-text">Shape the Future</span>
            </h2>
            <p className="text-white/70 text-lg max-w-2xl mx-auto">
              Be part of a team that's pushing the boundaries of technology and
              creating meaningful impact through innovation.
            </p>
          </div>
        </FadeIn>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          {benefits.map((benefit, index) => (
            <FadeIn key={index} delay={0.1 * index}>
              <motion.div 
                className="group cursor-pointer"
                whileHover={{ y: -5 }}
                transition={{ duration: 0.3 }}
              >
                <div className="relative">
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-primary/20 to-primary-light/20 rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-500" />
                  
                  <div className="relative bg-dark-lighter p-8 rounded-lg">
                    <div className="text-primary/50 mb-6 group-hover:text-primary transition-colors duration-300">
                      {benefit.icon}
                    </div>
                    
                    <h3 className="text-2xl font-light mb-4 text-white/90 group-hover:text-primary transition-colors">
                      {benefit.title}
                    </h3>
                    
                    <p className="text-white/70">
                      {benefit.description}
                    </p>
                  </div>
                </div>
              </motion.div>
            </FadeIn>
          ))}
        </div>

        <FadeIn delay={0.4}>
          <div className="text-center">
            <Link to="/careers/positions">
              <motion.button 
                className="px-8 py-4 bg-primary text-white hover:bg-primary-light transition-colors duration-300 flex items-center space-x-2 group mx-auto"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <span>View All Positions</span>
                <ArrowRight className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
              </motion.button>
            </Link>
          </div>
        </FadeIn>
      </div>
    </section>
  );
}