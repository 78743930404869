import React from 'react';
import { motion } from 'framer-motion';
import { X, MapPin, Phone, Mail, Clock } from 'lucide-react';
import * as Dialog from '@radix-ui/react-dialog';
import FadeIn from '../animations/FadeIn';

const locations = [
  {
    city: 'Paris',
    country: 'France',
    type: 'Global Headquarters',
    address: 'Champs Elysées',
    phone: '+33 6 75 35 39 65',
    email: 'contact@infinitemind.tech',
    hours: 'Mon-Fri: 9:00 - 18:00',
    coordinates: '48.8566° N, 2.3522° E'
  },
  {
    city: 'Brussels',
    country: 'Belgium',
    type: 'Regional Office',
    address: 'Avenue Louise 65',
    phone: '+32 2 123 4567',
    email: 'belgium@infinitemind.tech',
    hours: 'Mon-Fri: 9:00 - 17:30',
    coordinates: '50.8503° N, 4.3517° E'
  },
  {
    city: 'Amsterdam',
    country: 'Netherlands',
    type: 'Regional Office',
    address: 'Zuidas Business District',
    phone: '+31 20 123 4567',
    email: 'netherlands@infinitemind.tech',
    hours: 'Mon-Fri: 9:00 - 17:30',
    coordinates: '52.3676° N, 4.9041° E'
  },
  {
    city: 'Berlin',
    country: 'Germany',
    type: 'Regional Office',
    address: 'Potsdamer Platz',
    phone: '+49 30 123 4567',
    email: 'germany@infinitemind.tech',
    hours: 'Mon-Fri: 9:00 - 18:00',
    coordinates: '52.5200° N, 13.4050° E'
  },
  {
    city: 'Madrid',
    country: 'Spain',
    type: 'Regional Office',
    address: 'Paseo de la Castellana',
    phone: '+34 91 123 4567',
    email: 'spain@infinitemind.tech',
    hours: 'Mon-Fri: 9:00 - 18:00',
    coordinates: '40.4168° N, 3.7038° W'
  },
  {
    city: 'Lisbon',
    country: 'Portugal',
    type: 'Regional Office',
    address: 'Parque das Nações',
    phone: '+351 21 123 4567',
    email: 'portugal@infinitemind.tech',
    hours: 'Mon-Fri: 9:00 - 18:00',
    coordinates: '38.7223° N, 9.1393° W'
  },
  {
    city: 'Milan',
    country: 'Italy',
    type: 'Regional Office',
    address: 'Porta Nuova District',
    phone: '+39 02 123 4567',
    email: 'italy@infinitemind.tech',
    hours: 'Mon-Fri: 9:00 - 18:00',
    coordinates: '45.4642° N, 9.1900° E'
  }
];

interface LocationsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function LocationsModal({ isOpen, onClose }: LocationsModalProps) {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay asChild>
          <motion.div
            className="fixed inset-0 bg-black/90 backdrop-blur-sm z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        </Dialog.Overlay>
        
        <Dialog.Content asChild>
          <motion.div
            className="fixed inset-4 md:inset-8 bg-dark-lighter rounded-lg shadow-xl z-50 overflow-hidden"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div className="h-full flex flex-col relative z-10">
              {/* Header */}
              <div className="p-6 md:p-8 flex items-center justify-between">
                <FadeIn>
                  <Dialog.Title className="text-2xl font-light">
                    Global Presence
                  </Dialog.Title>
                </FadeIn>
                <Dialog.Close asChild>
                  <button 
                    className="text-white/50 hover:text-white transition-colors"
                    aria-label="Close dialog"
                  >
                    <X className="w-6 h-6" />
                  </button>
                </Dialog.Close>
              </div>

              {/* Content */}
              <div className="flex-1 overflow-y-auto p-6 md:p-8">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                  {locations.map((location, index) => (
                    <FadeIn key={index} delay={0.1 * index}>
                      <motion.div
                        className="relative group"
                        whileHover={{ y: -5 }}
                        transition={{ duration: 0.3 }}
                      >
                        <div className="absolute -inset-2 bg-gradient-to-r from-primary/5 via-primary/10 to-primary/5 rounded-lg blur-lg opacity-0 group-hover:opacity-100 transition-all duration-700" />
                        
                        <div className="relative p-4">
                          <div className="flex items-center space-x-4">
                            <div className="w-16 h-16 rounded-lg overflow-hidden flex-shrink-0">
                              <div className="w-full h-full bg-primary/10 flex items-center justify-center">
                                <MapPin className="w-8 h-8 text-primary" />
                              </div>
                            </div>
                            
                            <div className="flex-1 min-w-0">
                              <h3 className="text-lg font-light text-white/90 group-hover:text-primary transition-colors duration-300 truncate">
                                {location.city}
                              </h3>
                              <p className="text-sm text-primary/70 truncate">{location.country}</p>
                              <p className="text-sm text-white/50 mt-1">{location.type}</p>
                            </div>
                          </div>
                          
                          <div className="mt-4 space-y-3">
                            <div className="flex items-center space-x-3 text-white/50">
                              <MapPin className="w-4 h-4 text-primary/50" />
                              <span>{location.address}</span>
                            </div>
                            
                            <a 
                              href={`tel:${location.phone}`}
                              className="flex items-center space-x-3 text-white/50 hover:text-white transition-colors"
                            >
                              <Phone className="w-4 h-4 text-primary/50" />
                              <span>{location.phone}</span>
                            </a>
                            
                            <a 
                              href={`mailto:${location.email}`}
                              className="flex items-center space-x-3 text-white/50 hover:text-white transition-colors"
                            >
                              <Mail className="w-4 h-4 text-primary/50" />
                              <span>{location.email}</span>
                            </a>
                            
                            <div className="flex items-center space-x-3 text-white/50">
                              <Clock className="w-4 h-4 text-primary/50" />
                              <span>{location.hours}</span>
                            </div>
                          </div>
                          
                          <div className="mt-4 pt-4 border-t border-white/5">
                            <p className="text-xs text-white/30">
                              {location.coordinates}
                            </p>
                          </div>
                        </div>
                      </motion.div>
                    </FadeIn>
                  ))}
                </div>

                <FadeIn delay={0.4}>
                  <div className="mt-16 pt-8 border-t border-white/5">
                    <p className="text-center text-sm text-white/50">
                      Join our global network of innovation hubs and shape the future of digital transformation.
                    </p>
                  </div>
                </FadeIn>
              </div>
            </div>
          </motion.div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}