import React from 'react';
import { motion } from 'framer-motion';
import { Heart, Zap, Target, Users, Lightbulb, Globe } from 'lucide-react';
import FadeIn from '../../../components/animations/FadeIn';

const values = [
  {
    icon: <Heart className="w-8 h-8" />,
    title: 'Passion',
    description: 'We are driven by our passion for innovation and excellence in everything we do.'
  },
  {
    icon: <Target className="w-8 h-8" />,
    title: 'Impact',
    description: 'We measure our success by the tangible impact we create for our clients and society.'
  },
  {
    icon: <Users className="w-8 h-8" />,
    title: 'Collaboration',
    description: 'We believe in the power of diverse teams working together towards common goals.'
  },
  {
    icon: <Lightbulb className="w-8 h-8" />,
    title: 'Innovation',
    description: 'We constantly push boundaries and explore new possibilities in technology.'
  },
  {
    icon: <Globe className="w-8 h-8" />,
    title: 'Global Mindset',
    description: 'We embrace diversity and think globally while acting locally.'
  },
  {
    icon: <Zap className="w-8 h-8" />,
    title: 'Excellence',
    description: 'We strive for excellence in our work, our relationships, and our impact.'
  }
];

export default function CultureSection() {
  return (
    <section className="py-32 bg-dark relative overflow-hidden">
      <motion.div 
        className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(255,107,0,0.05),rgba(10,10,10,0))]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />
      
      <div className="container mx-auto px-6 relative">
        <FadeIn>
          <div className="text-center mb-16">
            <span className="text-primary uppercase tracking-[0.2em] text-sm mb-4 block">
              Our Culture
            </span>
            <h2 className="text-4xl md:text-5xl font-light mb-6">
              Values that Drive
              <span className="block mt-2 gradient-text">Our Innovation</span>
            </h2>
            <p className="text-white/70 text-lg max-w-2xl mx-auto">
              Our culture is built on core values that inspire us to create, innovate,
              and deliver exceptional results for our clients.
            </p>
          </div>
        </FadeIn>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {values.map((value, index) => (
            <FadeIn key={index} delay={0.1 * index}>
              <motion.div 
                className="group cursor-pointer"
                whileHover={{ y: -5 }}
                transition={{ duration: 0.3 }}
              >
                <div className="relative">
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-primary/20 to-primary-light/20 rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-500" />
                  
                  <div className="relative bg-dark-lighter p-8 rounded-lg">
                    <div className="text-primary/50 mb-6 group-hover:text-primary transition-colors duration-300">
                      {value.icon}
                    </div>
                    
                    <h3 className="text-2xl font-light mb-4 text-white/90 group-hover:text-primary transition-colors">
                      {value.title}
                    </h3>
                    
                    <p className="text-white/70">
                      {value.description}
                    </p>
                  </div>
                </div>
              </motion.div>
            </FadeIn>
          ))}
        </div>

        <FadeIn delay={0.6}>
          <div className="mt-20 relative">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-primary/20 to-primary-light/20 rounded-lg blur opacity-75" />
            
            <div className="relative bg-dark-lighter p-8 md:p-12 rounded-lg">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
                <div>
                  <h3 className="text-3xl font-light mb-6">
                    Join Our Culture of
                    <span className="block mt-2 gradient-text">Innovation</span>
                  </h3>
                  <p className="text-white/70 mb-8 leading-relaxed">
                    We're always looking for passionate individuals who share our values
                    and want to make a difference in the digital world. Join us in
                    shaping the future of technology.
                  </p>
                  <button className="px-8 py-4 bg-primary text-white hover:bg-primary-light transition-colors duration-300">
                    View Open Positions
                  </button>
                </div>
                
                <div className="relative aspect-[4/3]">
                  <img 
                    src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?auto=format&fit=crop&q=80&w=2000" 
                    alt="Team collaboration"
                    className="rounded-lg object-cover w-full h-full"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-dark-lighter/80 to-transparent rounded-lg" />
                </div>
              </div>
            </div>
          </div>
        </FadeIn>
      </div>
    </section>
  );
}