import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Building2, MapPin, Clock, Search, ArrowRight } from 'lucide-react';
import FadeIn from '../../../components/animations/FadeIn';
import { positions } from '../data';
import ApplicationModal from '../../../components/forms/ApplicationModal';

const departments = ['All Departments', 'Engineering', 'Design', 'Product', 'Marketing', 'Sales', 'Operations'];
const locations = ['All Locations', 'Paris', 'London', 'Berlin', 'Amsterdam', 'Remote'];
const types = ['All Types', 'Full-time', 'Part-time', 'Contract'];
const experiences = ['All Levels', 'Entry Level', 'Mid Level', 'Senior', 'Lead'];

export default function PositionsPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('All Departments');
  const [selectedLocation, setSelectedLocation] = useState('All Locations');
  const [selectedType, setSelectedType] = useState('All Types');
  const [selectedExperience, setSelectedExperience] = useState('All Levels');
  const [selectedPosition, setSelectedPosition] = useState<{
    id: string;
    title: string;
    department: string;
    location: string;
  } | null>(null);

  const filteredPositions = positions.filter(position => {
    const matchesSearch = position.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        position.description.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesDepartment = selectedDepartment === 'All Departments' || position.department === selectedDepartment;
    const matchesLocation = selectedLocation === 'All Locations' || position.location.includes(selectedLocation);
    const matchesType = selectedType === 'All Types' || position.type === selectedType;
    const matchesExperience = selectedExperience === 'All Levels' || position.experience === selectedExperience;
    
    return matchesSearch && matchesDepartment && matchesLocation && matchesType && matchesExperience;
  });

  return (
    <div className="min-h-screen bg-dark">
      <section className="pt-32 pb-20">
        <div className="container mx-auto px-6">
          <FadeIn>
            <h1 className="text-5xl md:text-6xl font-light mb-6">
              Join Our Team of
              <span className="block mt-2 gradient-text">
                Digital Innovators
              </span>
            </h1>
            <p className="text-xl text-white/70 max-w-2xl">
              Be part of a team that's pushing the boundaries of technology and
              creating meaningful impact through innovation.
            </p>
          </FadeIn>
        </div>
      </section>

      <section className="py-12 bg-dark-lighter">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-6">
            <div className="lg:col-span-2 relative">
              <Search className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-white/30" />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search positions..."
                className="w-full bg-dark rounded-lg pl-12 pr-4 py-3 text-white/90 placeholder:text-white/30 focus:outline-none focus:ring-2 focus:ring-primary/50"
              />
            </div>

            <select
              value={selectedDepartment}
              onChange={(e) => setSelectedDepartment(e.target.value)}
              className="bg-dark rounded-lg px-4 py-3 text-white/90 focus:outline-none focus:ring-2 focus:ring-primary/50"
            >
              {departments.map(dept => (
                <option key={dept} value={dept}>{dept}</option>
              ))}
            </select>

            <select
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.target.value)}
              className="bg-dark rounded-lg px-4 py-3 text-white/90 focus:outline-none focus:ring-2 focus:ring-primary/50"
            >
              {locations.map(loc => (
                <option key={loc} value={loc}>{loc}</option>
              ))}
            </select>

            <select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              className="bg-dark rounded-lg px-4 py-3 text-white/90 focus:outline-none focus:ring-2 focus:ring-primary/50"
            >
              {types.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>

            <select
              value={selectedExperience}
              onChange={(e) => setSelectedExperience(e.target.value)}
              className="bg-dark rounded-lg px-4 py-3 text-white/90 focus:outline-none focus:ring-2 focus:ring-primary/50"
            >
              {experiences.map(exp => (
                <option key={exp} value={exp}>{exp}</option>
              ))}
            </select>
          </div>
        </div>
      </section>

      <section className="py-20">
        <div className="container mx-auto px-6">
          <div className="space-y-8">
            {filteredPositions.map((position, index) => (
              <FadeIn key={position.id} delay={0.1 * index}>
                <motion.div 
                  className="group cursor-pointer"
                  whileHover={{ y: -2 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="relative">
                    <div className="absolute -inset-0.5 bg-gradient-to-r from-primary/20 to-primary-light/20 rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-500" />
                    
                    <div className="relative bg-dark-lighter p-8 rounded-lg">
                      <div className="flex flex-col md:flex-row md:items-center justify-between mb-6">
                        <div>
                          <h2 className="text-2xl font-light mb-2 group-hover:text-primary transition-colors">
                            {position.title}
                          </h2>
                          <div className="flex flex-wrap gap-4 text-white/70">
                            <span className="flex items-center">
                              <Building2 className="w-4 h-4 mr-2" />
                              {position.department}
                            </span>
                            <span className="flex items-center">
                              <MapPin className="w-4 h-4 mr-2" />
                              {position.location}
                            </span>
                            <span className="flex items-center">
                              <Clock className="w-4 h-4 mr-2" />
                              {position.type}
                            </span>
                            <span className="text-primary">{position.salary}</span>
                          </div>
                        </div>
                        <button 
                          onClick={() => setSelectedPosition({
                            id: position.id,
                            title: position.title,
                            department: position.department,
                            location: position.location
                          })}
                          className="mt-4 md:mt-0 px-6 py-2 border border-primary text-primary hover:bg-primary hover:text-white transition-all duration-300 flex items-center space-x-2"
                        >
                          <span>Apply Now</span>
                          <ArrowRight className="w-4 h-4" />
                        </button>
                      </div>
                      
                      <p className="text-white/70 mb-6">{position.description}</p>
                      
                      <div className="flex flex-wrap gap-2">
                        {position.skills.map((skill, i) => (
                          <span 
                            key={i}
                            className="px-3 py-1 bg-dark rounded-full text-sm text-white/50"
                          >
                            {skill}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </motion.div>
              </FadeIn>
            ))}
          </div>
        </div>
      </section>

      {selectedPosition && (
        <ApplicationModal
          isOpen={!!selectedPosition}
          onClose={() => setSelectedPosition(null)}
          position={selectedPosition}
        />
      )}
    </div>
  );
}