import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const messages = [
  {
    text: "Navigate Business Growth",
    subtext: "Strategic guidance for sustainable success"
  },
  {
    text: "Optimize Performance",
    subtext: "Data-driven insights for better decisions"
  },
  {
    text: "Accelerate Results",
    subtext: "Transform challenges into opportunities"
  }
];

export default function TextTransitionHero() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % messages.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="h-32 flex flex-col justify-center overflow-hidden">
      <AnimatePresence mode="wait">
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -40 }}
          transition={{ 
            duration: 0.8,
            ease: [0.21, 0.47, 0.32, 0.98]
          }}
          className="text-center"
        >
          <h2 className="text-4xl md:text-5xl font-light mb-3">
            <span className="bg-gradient-to-r from-[#FF6B00] via-[#FF8533] to-white/50 text-transparent bg-clip-text">
              {messages[currentIndex].text}
            </span>
          </h2>
          <p className="text-lg md:text-xl text-white/70">
            {messages[currentIndex].subtext}
          </p>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}