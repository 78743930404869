import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import { Brain, Bot, Workflow, Factory, FileSearch, Network, Cpu, Database, LineChart, Settings, ChevronDown } from 'lucide-react';

const automationCategories = [
  {
    title: 'AI & Intelligence',
    items: [
      {
        icon: <Brain className="w-5 h-5" />,
        title: 'AI Process Automation',
        description: 'Intelligent automation powered by advanced AI',
        href: '/automations/ai-process'
      },
      {
        icon: <Bot className="w-5 h-5" />,
        title: 'Robotic Process Automation',
        description: 'End-to-end process automation solutions',
        href: '/automations/rpa'
      }
    ]
  },
  {
    title: 'Business & Operations',
    items: [
      {
        icon: <Workflow className="w-5 h-5" />,
        title: 'Workflow Automation',
        description: 'Streamlined business process workflows',
        href: '/automations/workflow'
      },
      {
        icon: <Factory className="w-5 h-5" />,
        title: 'Industrial Automation',
        description: 'Smart manufacturing and IoT solutions',
        href: '/automations/industrial'
      },
      {
        icon: <FileSearch className="w-5 h-5" />,
        title: 'Document Processing',
        description: 'Intelligent document handling and analysis',
        href: '/automations/document'
      }
    ]
  },
  {
    title: 'Infrastructure & Networks',
    items: [
      {
        icon: <Network className="w-5 h-5" />,
        title: 'Network Automation',
        description: 'Automated network management and optimization',
        href: '/automations/network'
      },
      {
        icon: <Cpu className="w-5 h-5" />,
        title: 'Infrastructure Automation',
        description: 'Cloud and infrastructure automation',
        href: '/automations/infrastructure'
      }
    ]
  },
  {
    title: 'Data & Analytics',
    items: [
      {
        icon: <Database className="w-5 h-5" />,
        title: 'Data Pipeline Automation',
        description: 'Automated data processing and integration',
        href: '/automations/data-pipeline'
      },
      {
        icon: <LineChart className="w-5 h-5" />,
        title: 'Analytics Automation',
        description: 'Automated reporting and analytics',
        href: '/automations/analytics'
      },
      {
        icon: <Settings className="w-5 h-5" />,
        title: 'DevOps Automation',
        description: 'CI/CD and development automation',
        href: '/automations/devops'
      }
    ]
  }
];

interface VerticalNavProps {
  isOpen: boolean;
}

export default function VerticalNav({ isOpen }: VerticalNavProps) {
  const [expandedCategories, setExpandedCategories] = useState<string[]>(automationCategories.map(cat => cat.title));
  const location = useLocation();

  const toggleCategory = (title: string) => {
    setExpandedCategories(prev => 
      prev.includes(title) 
        ? prev.filter(cat => cat !== title)
        : [...prev, title]
    );
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed top-[72px] left-0 h-[calc(100vh-72px)] bg-dark-lighter border-r border-primary/10 w-[300px] overflow-y-auto z-40"
          initial={{ opacity: 0, x: -300 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -300 }}
          transition={{ duration: 0.3 }}
        >
          <div className="p-4">
            {automationCategories.map((category) => (
              <div key={category.title} className="mb-8">
                <button
                  onClick={() => toggleCategory(category.title)}
                  className="w-full flex items-center justify-between text-sm font-medium text-primary border-b border-primary/20 pb-2 mb-3 hover:text-primary-light transition-colors group"
                >
                  <span>{category.title}</span>
                  <motion.div
                    className="bg-primary/10 rounded-full p-1 group-hover:bg-primary/20 transition-colors"
                    animate={{ 
                      rotate: expandedCategories.includes(category.title) ? 180 : 0,
                      backgroundColor: expandedCategories.includes(category.title) ? 'rgba(255, 107, 0, 0.2)' : 'rgba(255, 107, 0, 0.1)'
                    }}
                    transition={{ duration: 0.2 }}
                  >
                    <ChevronDown className="w-4 h-4" />
                  </motion.div>
                </button>
                <AnimatePresence>
                  {expandedCategories.includes(category.title) && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="space-y-2 overflow-hidden"
                    >
                      {category.items.map((item) => (
                        <Link
                          key={item.href}
                          to={item.href}
                          className={`group flex items-start p-3 rounded-lg transition-all duration-300 ${
                            location.pathname === item.href 
                              ? 'bg-primary/10 text-primary'
                              : 'hover:bg-dark text-white/70 hover:text-white'
                          }`}
                        >
                          <div className={`p-2 rounded-lg ${
                            location.pathname === item.href
                              ? 'bg-primary/20 text-primary'
                              : 'bg-dark/50 text-primary/70 group-hover:text-primary group-hover:bg-dark-lighter'
                          } transition-colors`}>
                            {item.icon}
                          </div>
                          <div className="ml-3">
                            <h4 className="text-sm font-medium">
                              {item.title}
                            </h4>
                            <p className="text-xs text-white/50 group-hover:text-white/70 mt-0.5 transition-colors">
                              {item.description}
                            </p>
                          </div>
                        </Link>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}