import React from 'react';
import { motion } from 'framer-motion';

export default function VideoBackground() {
  return (
    <div className="absolute inset-0 w-full h-full">
      <video 
        autoPlay 
        loop 
        muted 
        playsInline
        className="absolute inset-0 w-full h-full object-cover scale-105"
        style={{ filter: 'brightness(0.4) saturate(1.2)' }}
      >
        <source 
          src="https://player.vimeo.com/progressive_redirect/playback/814800555/rendition/1080p/file.mp4?loc=external&signature=2d9d4004f2c5f6e4e7c4479f3a6e4ab2b9c4a6b88e8d5c1c21c6b0b0f0c0b0a" 
          type="video/mp4" 
        />
      </video>

      <motion.div 
        className="absolute inset-0 bg-gradient-radial from-transparent via-black/40 to-black opacity-80"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.8 }}
        transition={{ duration: 1.5 }}
      />
      
      <motion.div 
        className="absolute inset-0 bg-gradient-to-t from-dark via-dark/50 to-transparent"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5, delay: 0.5 }}
      />

      <div className="absolute inset-0 opacity-30">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#000_1px,transparent_1px),linear-gradient(to_bottom,#000_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_80%_50%_at_50%_50%,#000_70%,transparent_100%)]" />
      </div>
    </div>
  );
}