import React from 'react';
import { motion } from 'framer-motion';
import { Brain, Target, Rocket, Shield, Zap, Users } from 'lucide-react';
import FadeIn from '../animations/FadeIn';

const pillars = [
  {
    icon: <Target className="w-6 h-6" />,
    title: "Strategic Growth",
    description: "Data-driven strategies for market expansion and business growth",
    features: ["Market Analysis", "Growth Planning", "Optimization", "Scaling"]
  },
  {
    icon: <Rocket className="w-6 h-6" />,
    title: "Performance",
    description: "Enhance efficiency through strategic process improvements",
    features: ["Process Design", "Resource Mgmt", "Cost Control", "Metrics"]
  },
  {
    icon: <Users className="w-6 h-6" />,
    title: "Leadership",
    description: "Strengthen capabilities for sustainable success",
    features: ["Team Growth", "Performance", "Change", "Culture"]
  },
  {
    icon: <Shield className="w-6 h-6" />,
    title: "Risk Management",
    description: "Identify and mitigate risks while ensuring compliance",
    features: ["Assessment", "Compliance", "Continuity", "Planning"]
  },
  {
    icon: <Brain className="w-6 h-6" />,
    title: "Innovation",
    description: "Foster continuous improvement and adaptation",
    features: ["R&D", "Digital", "Strategy", "Growth"]
  },
  {
    icon: <Zap className="w-6 h-6" />,
    title: "Revenue Growth",
    description: "Accelerate growth through optimized strategies",
    features: ["Sales", "Markets", "Customers", "Revenue"]
  }
];

export default function BusinessCoPilot() {
  return (
    <section className="py-16 bg-dark relative overflow-hidden">
      <motion.div 
        className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(255,107,0,0.05),rgba(10,10,10,0))]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />
      
      <div className="container mx-auto px-6 relative">
        <FadeIn>
          <div className="text-center mb-12">
            <span className="text-primary uppercase tracking-[0.2em] text-sm mb-3 block">
              The Co-Pilot Philosophy
            </span>
            <h2 className="text-4xl md:text-5xl font-light mb-4">
              Your Intelligent
              <span className="block mt-2 gradient-text">Business Partner</span>
            </h2>
            <p className="text-white/70 text-lg max-w-3xl mx-auto">
              More than consultants, we're your dedicated business co-pilots, combining strategic expertise 
              with data-driven insights to navigate your business toward sustainable success.
            </p>
          </div>
        </FadeIn>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {pillars.map((pillar, index) => (
            <FadeIn key={index} delay={0.1 * index}>
              <motion.div 
                className="group cursor-pointer"
                whileHover={{ y: -5 }}
                transition={{ duration: 0.3 }}
              >
                <div className="relative">
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-primary/20 to-primary-light/20 rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-500" />
                  
                  <div className="relative bg-dark-lighter p-6 rounded-lg">
                    <div className="text-primary/50 mb-4 group-hover:text-primary transition-colors duration-300">
                      {pillar.icon}
                    </div>
                    
                    <h3 className="text-lg font-light mb-2 text-white/90 group-hover:text-primary transition-colors">
                      {pillar.title}
                    </h3>
                    
                    <p className="text-sm text-white/70 mb-4">
                      {pillar.description}
                    </p>

                    <div className="flex flex-wrap gap-2">
                      {pillar.features.map((feature, i) => (
                        <span key={i} className="text-xs px-2 py-1 bg-dark rounded-full text-white/50">
                          {feature}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </motion.div>
            </FadeIn>
          ))}
        </div>
      </div>
    </section>
  );
}