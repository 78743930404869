import React from 'react';
import { motion } from 'framer-motion';
import { MousePointer2 } from 'lucide-react';

export default function FooterSection() {
  return (
    <div className="absolute bottom-12 left-0 w-full">
      <div className="container mx-auto px-6">
        <div className="flex justify-between items-center border-t border-primary/10 pt-8">
          <motion.div 
            className="flex items-center space-x-2 text-primary/50 text-sm"
            animate={{ 
              y: [0, -5, 0],
              opacity: [0.5, 1, 0.5]
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          >
            <MousePointer2 className="w-4 h-4" />
            <span>Scroll to explore</span>
          </motion.div>
          <motion.div 
            className="text-primary/50 text-sm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.2 }}
          >
            © 2024 InfiniteMind.TECH
          </motion.div>
        </div>
      </div>
    </div>
  );
}