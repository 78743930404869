import React from 'react';
import { motion } from 'framer-motion';
import { 
  Building2, 
  Briefcase, 
  Activity, 
  Globe2, 
  ShieldCheck, 
  Lightbulb,
  Users,
  GraduationCap,
  Scale,
  Plane,
  Building,
  Newspaper
} from 'lucide-react';
import FadeIn from '../../../components/animations/FadeIn';

const industries = [
  {
    icon: <Briefcase className="w-8 h-8" />,
    title: 'Professional Services',
    description: 'Digital transformation solutions for consulting and professional firms',
    stats: ['500+ Firms Transformed', '200% Efficiency Gain', '45% Cost Reduction'],
    image: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?auto=format&fit=crop&q=80&w=2000'
  },
  {
    icon: <Building2 className="w-8 h-8" />,
    title: 'Financial Services',
    description: 'Digital solutions for banks, insurance, and fintech',
    stats: ['50+ Financial Institutions', '$2B+ Transactions', '99.99% Uptime'],
    image: 'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?auto=format&fit=crop&q=80&w=2000'
  },
  {
    icon: <Activity className="w-8 h-8" />,
    title: 'Healthcare',
    description: 'Innovative solutions for healthcare providers and medical technology',
    stats: ['200+ Healthcare Providers', '1M+ Patient Records', 'HIPAA Compliant'],
    image: 'https://images.unsplash.com/photo-1576091160550-2173dba999ef?auto=format&fit=crop&q=80&w=2000'
  },
  {
    icon: <Globe2 className="w-8 h-8" />,
    title: 'E-commerce & Retail',
    description: 'Omnichannel solutions for modern retail experiences',
    stats: ['$500M+ GMV', '10M+ Customers', '300% Growth'],
    image: 'https://images.unsplash.com/photo-1441986300917-64674bd600d8?auto=format&fit=crop&q=80&w=2000'
  },
  {
    icon: <GraduationCap className="w-8 h-8" />,
    title: 'Education',
    description: 'Digital learning and educational technology solutions',
    stats: ['1M+ Students', '95% Engagement', '150+ Institutions'],
    image: 'https://images.unsplash.com/photo-1523050854058-8df90110c9f1?auto=format&fit=crop&q=80&w=2000'
  },
  {
    icon: <Scale className="w-8 h-8" />,
    title: 'Legal Services',
    description: 'Legal tech solutions and digital transformation',
    stats: ['300+ Law Firms', '60% Time Saved', 'AI-Powered Research'],
    image: 'https://images.unsplash.com/photo-1589829545856-d10d557cf95f?auto=format&fit=crop&q=80&w=2000'
  },
  {
    icon: <ShieldCheck className="w-8 h-8" />,
    title: 'Government & Defense',
    description: 'Secure and compliant solutions for public sector',
    stats: ['ISO 27001 Certified', 'FedRAMP Ready', 'Zero Breaches'],
    image: 'https://images.unsplash.com/photo-1590859808308-3d2d9c515b1a?auto=format&fit=crop&q=80&w=2000'
  },
  {
    icon: <Building className="w-8 h-8" />,
    title: 'Manufacturing',
    description: 'Industry 4.0 solutions for smart manufacturing',
    stats: ['30% Cost Reduction', '50% Efficiency Gain', 'IoT Enabled'],
    image: 'https://images.unsplash.com/photo-1565465295423-68c959f89282?auto=format&fit=crop&q=80&w=2000'
  },
  {
    icon: <Plane className="w-8 h-8" />,
    title: 'Travel & Hospitality',
    description: 'Digital solutions for modern travel experiences',
    stats: ['25M+ Bookings', '40% Revenue Growth', 'AI Recommendations'],
    image: 'https://images.unsplash.com/photo-1436491865332-7a61a109cc05?auto=format&fit=crop&q=80&w=2000'
  },
  {
    icon: <Newspaper className="w-8 h-8" />,
    title: 'Media & Entertainment',
    description: 'Digital content and streaming solutions',
    stats: ['50M+ Users', '4K Streaming', 'AI Content Analysis'],
    image: 'https://images.unsplash.com/photo-1578022761797-b8636ac1773c?auto=format&fit=crop&q=80&w=2000'
  },
  {
    icon: <Users className="w-8 h-8" />,
    title: 'Non-Profit',
    description: 'Digital solutions for social impact organizations',
    stats: ['200+ Organizations', '150% Donor Growth', 'Impact Analytics'],
    image: 'https://images.unsplash.com/photo-1559027615-cd4628902d4a?auto=format&fit=crop&q=80&w=2000'
  },
  {
    icon: <Lightbulb className="w-8 h-8" />,
    title: 'Energy & Utilities',
    description: 'Smart solutions for sustainable energy management',
    stats: ['40% Energy Savings', 'Carbon Neutral', 'Smart Grid Ready'],
    image: 'https://images.unsplash.com/photo-1473341304170-971dccb5ac1e?auto=format&fit=crop&q=80&w=2000'
  }
];

export default function IndustrySection() {
  return (
    <section id="industries" className="py-32 bg-dark-lighter relative overflow-hidden">
      <motion.div 
        className="absolute inset-0 bg-[radial-gradient(circle_at_70%_50%,rgba(255,107,0,0.05),rgba(10,10,10,0))]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />
      
      <div className="container mx-auto px-6 relative">
        <FadeIn>
          <div className="text-center mb-16">
            <span className="text-primary uppercase tracking-[0.2em] text-sm mb-4 block">
              Industries
            </span>
            <h2 className="text-4xl md:text-5xl font-light mb-6">
              Expertise Across
              <span className="block mt-2 gradient-text">Multiple Sectors</span>
            </h2>
            <p className="text-white/70 text-lg max-w-2xl mx-auto">
              We deliver transformative solutions across diverse industries, 
              combining deep domain knowledge with technical excellence.
            </p>
          </div>
        </FadeIn>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {industries.map((industry, index) => (
            <FadeIn key={index} delay={0.1 * index}>
              <motion.div 
                className="group cursor-pointer"
                whileHover={{ y: -5 }}
                transition={{ duration: 0.3 }}
              >
                <div className="relative">
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-primary/20 to-primary-light/20 rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-500" />
                  
                  <div className="relative bg-dark rounded-lg overflow-hidden">
                    <div className="aspect-[16/9] relative overflow-hidden">
                      <img 
                        src={industry.image} 
                        alt={industry.title}
                        className="object-cover w-full h-full transform group-hover:scale-110 transition-transform duration-700"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-dark via-dark/50 to-transparent opacity-60 group-hover:opacity-80 transition-opacity duration-300" />
                    </div>
                    
                    <div className="p-8">
                      <div className="text-primary/50 mb-6 group-hover:text-primary transition-colors duration-300">
                        {industry.icon}
                      </div>
                      
                      <h3 className="text-2xl font-light mb-4 group-hover:text-primary transition-colors">
                        {industry.title}
                      </h3>
                      
                      <p className="text-white/70 mb-6">
                        {industry.description}
                      </p>
                      
                      <div className="space-y-2">
                        {industry.stats.map((stat, i) => (
                          <div 
                            key={i}
                            className="text-sm text-white/50 group-hover:text-white/70 transition-colors"
                          >
                            • {stat}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </FadeIn>
          ))}
        </div>
      </div>
    </section>
  );
}