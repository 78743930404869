import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Brain, Zap, Target, Users, Lightbulb, Globe } from 'lucide-react';
import FadeIn from '../animations/FadeIn';
import CEODashboard from '../dashboard/CEODashboard';
import AIMobileApps from '../dashboard/AIMobileApps';
import AIDigitalAssistant from '../dashboard/AIDigitalAssistant';
import AICybersecurity from '../dashboard/AICybersecurity';
import AIBusinessPerformance from '../dashboard/AIBusinessPerformance';
import AIDepartmentDashboard from '../dashboard/AIDepartmentDashboard';

export default function RealizationsShowcase() {
  const [selectedDemo, setSelectedDemo] = useState<'dashboard' | 'mobile' | 'assistant' | 'security' | 'business' | 'department'>('dashboard');

  return (
    <section className="py-20 bg-dark relative overflow-hidden">
      <motion.div 
        className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(255,107,0,0.05),rgba(10,10,10,0))]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />
      
      <div className="container mx-auto px-6 relative">
        <FadeIn>
          <div className="text-center mb-16">
            <span className="text-primary uppercase tracking-[0.2em] text-sm mb-4 block">
              Our Realizations
            </span>
            <h2 className="text-4xl md:text-5xl font-light mb-6">
              Transformative Solutions
              <span className="block mt-2 gradient-text">In Action</span>
            </h2>
            <p className="text-white/70 text-lg max-w-2xl mx-auto">
              Explore our portfolio of successful implementations that showcase our expertise 
              in delivering cutting-edge solutions across various industries.
            </p>
          </div>
        </FadeIn>

        <div className="flex flex-wrap justify-center gap-4 mb-12">
          <button
            onClick={() => setSelectedDemo('dashboard')}
            className={`px-6 py-3 rounded-lg transition-colors ${
              selectedDemo === 'dashboard'
                ? 'bg-primary text-white'
                : 'text-white/70 hover:text-white hover:bg-primary/10'
            }`}
          >
            AI Executive Dashboard
          </button>
          <button
            onClick={() => setSelectedDemo('mobile')}
            className={`px-6 py-3 rounded-lg transition-colors ${
              selectedDemo === 'mobile'
                ? 'bg-primary text-white'
                : 'text-white/70 hover:text-white hover:bg-primary/10'
            }`}
          >
            AI Mobile Apps
          </button>
          <button
            onClick={() => setSelectedDemo('assistant')}
            className={`px-6 py-3 rounded-lg transition-colors ${
              selectedDemo === 'assistant'
                ? 'bg-primary text-white'
                : 'text-white/70 hover:text-white hover:bg-primary/10'
            }`}
          >
            AI Digital Assistant
          </button>
          <button
            onClick={() => setSelectedDemo('security')}
            className={`px-6 py-3 rounded-lg transition-colors ${
              selectedDemo === 'security'
                ? 'bg-primary text-white'
                : 'text-white/70 hover:text-white hover:bg-primary/10'
            }`}
          >
            AI & Cybersecurity
          </button>
          <button
            onClick={() => setSelectedDemo('business')}
            className={`px-6 py-3 rounded-lg transition-colors ${
              selectedDemo === 'business'
                ? 'bg-primary text-white'
                : 'text-white/70 hover:text-white hover:bg-primary/10'
            }`}
          >
            AI & Business Performance
          </button>
          <button
            onClick={() => setSelectedDemo('department')}
            className={`px-6 py-3 rounded-lg transition-colors ${
              selectedDemo === 'department'
                ? 'bg-primary text-white'
                : 'text-white/70 hover:text-white hover:bg-primary/10'
            }`}
          >
            AI By Department
          </button>
        </div>

        <div className="bg-dark-lighter rounded-lg p-8">
          {selectedDemo === 'dashboard' ? (
            <CEODashboard />
          ) : selectedDemo === 'mobile' ? (
            <AIMobileApps />
          ) : selectedDemo === 'assistant' ? (
            <AIDigitalAssistant />
          ) : selectedDemo === 'security' ? (
            <AICybersecurity />
          ) : selectedDemo === 'business' ? (
            <AIBusinessPerformance />
          ) : (
            <AIDepartmentDashboard />
          )}
        </div>
      </div>
    </section>
  );
}