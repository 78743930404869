import React from 'react';
import { motion } from 'framer-motion';
import { Award, Star, Globe } from 'lucide-react';
import FadeIn from '../../../components/animations/FadeIn';

const expertise = [
  {
    icon: <Award className="w-6 h-6" />,
    title: "Executive Leadership",
    description: "Fortune 500 experience driving digital transformation",
    stats: ["25+ Years Experience", "Global Leadership", "Industry Pioneers"]
  },
  {
    icon: <Star className="w-6 h-6" />,
    title: "Industry Recognition",
    description: "Thought leaders shaping industry standards",
    stats: ["50+ Awards", "100+ Publications", "Keynote Speakers"]
  },
  {
    icon: <Globe className="w-6 h-6" />,
    title: "Global Impact",
    description: "Transformative results across continents",
    stats: ["20+ Countries", "$2B+ Value", "300+ Projects"]
  }
];

export default function ConsultingTeam() {
  return (
    <section className="py-16 bg-dark-lighter relative overflow-hidden">
      <motion.div 
        className="absolute inset-0 bg-[radial-gradient(circle_at_70%_50%,rgba(255,107,0,0.05),rgba(10,10,10,0))]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />
      
      <div className="container mx-auto px-6 relative">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {expertise.map((item, index) => (
            <FadeIn key={index} delay={0.1 * index}>
              <motion.div
                className="relative group"
                whileHover={{ y: -5 }}
                transition={{ duration: 0.3 }}
              >
                <div className="absolute -inset-2 bg-gradient-to-r from-primary/5 via-primary/10 to-primary/5 rounded-lg blur-lg opacity-0 group-hover:opacity-100 transition-all duration-700" />
                
                <div className="relative p-6">
                  <div className="flex items-center space-x-4 mb-4">
                    <div className="p-3 bg-primary/10 rounded-lg text-primary">
                      {item.icon}
                    </div>
                    <div>
                      <h3 className="text-xl font-light text-white/90 group-hover:text-primary transition-colors duration-300">
                        {item.title}
                      </h3>
                      <p className="text-sm text-white/50">
                        {item.description}
                      </p>
                    </div>
                  </div>
                  
                  <div className="space-y-2">
                    {item.stats.map((stat, i) => (
                      <div 
                        key={i} 
                        className="flex items-center text-white/70"
                      >
                        <div className="w-1.5 h-1.5 rounded-full bg-primary mr-3" />
                        {stat}
                      </div>
                    ))}
                  </div>
                </div>
              </motion.div>
            </FadeIn>
          ))}
        </div>
      </div>
    </section>
  );
}