import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Camera, Brain, Activity, MessageSquare, Users, Smartphone } from 'lucide-react';
import FadeIn from '../animations/FadeIn';

interface AppScreen {
  id: string;
  title: string;
  description: string;
  icon: React.ReactNode;
  screens: {
    title: string;
    content: React.ReactNode;
  }[];
  stats: {
    label: string;
    value: string;
    trend: number;
  }[];
}

const apps: AppScreen[] = [
  {
    id: 'vision',
    title: 'AI Vision Assistant',
    description: 'Real-time object recognition and scene understanding',
    icon: <Camera className="w-6 h-6" />,
    screens: [
      {
        title: 'Object Detection',
        content: (
          <div className="space-y-4">
            <div className="bg-dark/50 p-4 rounded-lg">
              <div className="flex items-center justify-between mb-2">
                <span className="text-primary">Processing...</span>
                <span className="text-sm text-white/50">98% Confidence</span>
              </div>
              <div className="space-y-2">
                {['Person', 'Car', 'Building', 'Tree'].map((item, i) => (
                  <div key={i} className="flex items-center justify-between">
                    <span>{item}</span>
                    <div className="w-32 h-2 bg-dark rounded-full overflow-hidden">
                      <motion.div
                        className="h-full bg-primary"
                        initial={{ width: 0 }}
                        animate={{ width: `${90 - i * 15}%` }}
                        transition={{ duration: 1, delay: i * 0.2 }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      }
    ],
    stats: [
      { label: 'Recognition Accuracy', value: '99.2%', trend: 2.4 },
      { label: 'Processing Speed', value: '45ms', trend: -15.3 },
      { label: 'User Satisfaction', value: '4.8/5', trend: 0.3 }
    ]
  },
  {
    id: 'health',
    title: 'Smart Health Monitor',
    description: 'AI-powered health tracking and predictive wellness',
    icon: <Activity className="w-6 h-6" />,
    screens: [
      {
        title: 'Health Metrics',
        content: (
          <div className="space-y-4">
            <div className="bg-dark/50 p-4 rounded-lg">
              <div className="flex items-center justify-between mb-4">
                <span className="text-primary">Real-time Monitoring</span>
                <span className="text-sm text-white/50">Updated 2s ago</span>
              </div>
              <div className="space-y-4">
                {[
                  { label: 'Heart Rate', value: '72 bpm', trend: -2 },
                  { label: 'Blood Pressure', value: '120/80', trend: 1 },
                  { label: 'Blood Oxygen', value: '98%', trend: 0 },
                  { label: 'Temperature', value: '36.6°C', trend: 0.5 }
                ].map((metric, i) => (
                  <div key={i} className="flex items-center justify-between">
                    <span>{metric.label}</span>
                    <div className="flex items-center space-x-2">
                      <span className="text-primary">{metric.value}</span>
                      <motion.div
                        className={`text-sm ${metric.trend > 0 ? 'text-green-500' : metric.trend < 0 ? 'text-red-500' : 'text-white/50'}`}
                        animate={{ y: [0, metric.trend, 0] }}
                        transition={{ duration: 2, repeat: Infinity }}
                      >
                        {metric.trend > 0 ? '↑' : metric.trend < 0 ? '↓' : '→'}
                      </motion.div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      }
    ],
    stats: [
      { label: 'Prediction Accuracy', value: '95.8%', trend: 1.7 },
      { label: 'Response Time', value: '120ms', trend: -8.4 },
      { label: 'User Retention', value: '92%', trend: 5.2 }
    ]
  },
  {
    id: 'chat',
    title: 'Neural Chat',
    description: 'Advanced conversational AI for natural language',
    icon: <MessageSquare className="w-6 h-6" />,
    screens: [
      {
        title: 'Conversation',
        content: (
          <div className="space-y-4">
            <div className="bg-dark/50 p-4 rounded-lg">
              <div className="space-y-4">
                {[
                  { role: 'user', message: 'How can AI help my business?' },
                  { role: 'assistant', message: 'AI can automate tasks, analyze data, and provide insights for better decision-making.' },
                  { role: 'user', message: 'What specific applications would you recommend?' },
                  { role: 'assistant', message: 'Based on your industry, I recommend starting with customer service automation and predictive analytics.' }
                ].map((msg, i) => (
                  <motion.div
                    key={i}
                    className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'}`}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: i * 0.2 }}
                  >
                    <div className={`max-w-[80%] p-3 rounded-lg ${
                      msg.role === 'user' ? 'bg-primary text-white' : 'bg-dark text-white/90'
                    }`}>
                      {msg.message}
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        )
      }
    ],
    stats: [
      { label: 'Language Accuracy', value: '97.5%', trend: 3.2 },
      { label: 'Response Speed', value: '85ms', trend: -12.1 },
      { label: 'User Growth', value: '+45%', trend: 8.9 }
    ]
  }
];

export default function AIMobileApps() {
  const [selectedApp, setSelectedApp] = useState(apps[0]);

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {apps.map((app) => (
          <button
            key={app.id}
            onClick={() => setSelectedApp(app)}
            className={`p-4 rounded-lg transition-colors ${
              selectedApp.id === app.id
                ? 'bg-primary text-white'
                : 'bg-dark text-white/70 hover:bg-primary/10'
            }`}
          >
            <div className="flex items-center space-x-3">
              {app.icon}
              <span>{app.title}</span>
            </div>
          </button>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Mobile Screen Preview */}
        <div className="bg-dark p-8 rounded-lg">
          <div className="max-w-sm mx-auto">
            <div className="relative border-8 border-dark-lighter rounded-[3rem] overflow-hidden aspect-[9/19]">
              <div className="absolute inset-0 p-4 space-y-4">
                {/* App Header */}
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-lg font-light">{selectedApp.title}</h3>
                    <p className="text-sm text-white/50">{selectedApp.description}</p>
                  </div>
                  {selectedApp.icon}
                </div>

                {/* App Content */}
                <div className="mt-4">
                  {selectedApp.screens.map((screen, index) => (
                    <div key={index}>
                      <h4 className="text-sm text-white/70 mb-2">{screen.title}</h4>
                      {screen.content}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* App Analytics */}
        <div className="space-y-6">
          <div className="bg-dark p-6 rounded-lg">
            <h3 className="text-xl font-light mb-4">Performance Metrics</h3>
            <div className="space-y-4">
              {selectedApp.stats.map((stat, index) => (
                <div key={index} className="flex items-center justify-between">
                  <span className="text-white/70">{stat.label}</span>
                  <div className="flex items-center space-x-3">
                    <span className="text-lg">{stat.value}</span>
                    <span className={stat.trend > 0 ? 'text-green-500' : 'text-red-500'}>
                      {stat.trend > 0 ? '+' : ''}{stat.trend}%
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-dark p-6 rounded-lg">
            <h3 className="text-xl font-light mb-4">Usage Analytics</h3>
            <div className="grid grid-cols-2 gap-4">
              {[
                { label: 'Active Users', value: '25.8K', trend: '+12%' },
                { label: 'Session Duration', value: '8.5m', trend: '+5%' },
                { label: 'Retention Rate', value: '92%', trend: '+3%' },
                { label: 'User Rating', value: '4.8/5', trend: '+0.2' }
              ].map((metric, index) => (
                <div key={index} className="bg-dark-lighter p-4 rounded-lg">
                  <div className="text-white/50 text-sm mb-1">{metric.label}</div>
                  <div className="text-xl">{metric.value}</div>
                  <div className="text-sm text-green-500">{metric.trend}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}