import React, { useState } from 'react';
import { motion } from 'framer-motion';
import TextTransitionHero from '../animations/TextTransitionHero';
import FadeIn from '../animations/FadeIn';
import StartProjectModal from '../project/StartProjectModal';
import ActionButton from '../ui/ActionButton';

export default function CoPilotHero() {
  const [isProjectModalOpen, setProjectModalOpen] = useState(false);

  return (
    <>
      <section className="min-h-screen bg-black text-white relative overflow-hidden">
        {/* Hero Background Image */}
        <div className="absolute inset-0">
          <img 
            src="https://images.unsplash.com/photo-1552664730-d307ca884978?auto=format&fit=crop&q=80&w=2000" 
            alt="Modern business team analyzing performance data"
            className="object-cover w-full h-full opacity-50"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-black via-black/95 to-black/90" />
          {/* Orange and Blue Accent Overlays */}
          <div className="absolute inset-0 bg-gradient-radial from-orange-500/10 via-transparent to-transparent opacity-30" style={{ transform: 'translate(-25%, -25%)' }} />
          <div className="absolute inset-0 bg-gradient-radial from-blue-500/10 via-transparent to-transparent opacity-30" style={{ transform: 'translate(75%, 75%)' }} />
          {/* Additional Performance-themed Overlay */}
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_25%_25%,rgba(255,107,0,0.15),transparent_50%)]" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_75%_75%,rgba(59,130,246,0.15),transparent_50%)]" />
        </div>
        
        <div className="container mx-auto px-6 relative">
          <div className="min-h-screen grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            {/* Content Side */}
            <FadeIn>
              <div>
                <h1 className="text-5xl md:text-6xl font-light mb-6">
                  Your AI-Powered
                  <span className="block mt-2 gradient-text">
                    Business Partner
                  </span>
                </h1>
                
                <TextTransitionHero />
                
                <ActionButton 
                  onClick={() => setProjectModalOpen(true)}
                  className="mt-8 px-8 py-4 text-lg"
                >
                  Start Your Growth Journey
                </ActionButton>
              </div>
            </FadeIn>
          </div>
        </div>
      </section>

      <StartProjectModal 
        isOpen={isProjectModalOpen}
        onClose={() => setProjectModalOpen(false)}
      />
    </>
  );
}