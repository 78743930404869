import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Brain, MessageSquare, Zap, Search, Database, Code } from 'lucide-react';

interface AssistantFeature {
  id: string;
  title: string;
  description: string;
  icon: React.ReactNode;
  screens: {
    title: string;
    content: React.ReactNode;
  }[];
  stats: {
    label: string;
    value: string;
    trend: number;
  }[];
}

const features: AssistantFeature[] = [
  {
    id: 'conversation',
    title: 'Intelligent Conversation',
    description: 'Natural language understanding and contextual responses',
    icon: <MessageSquare className="w-6 h-6" />,
    screens: [
      {
        title: 'Active Conversation',
        content: (
          <div className="space-y-4">
            <div className="bg-dark/50 p-4 rounded-lg">
              <div className="space-y-4">
                {[
                  { role: 'user', message: 'Can you help optimize our cloud infrastructure?' },
                  { role: 'assistant', message: 'I can analyze your current setup and suggest improvements. What are your main concerns?' },
                  { role: 'user', message: 'We need better performance and cost efficiency.' },
                  { role: 'assistant', message: 'Based on typical patterns, I recommend:\n1. Implement auto-scaling\n2. Optimize resource allocation\n3. Use spot instances where applicable' }
                ].map((msg, i) => (
                  <motion.div
                    key={i}
                    className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'}`}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: i * 0.2 }}
                  >
                    <div className={`max-w-[80%] p-3 rounded-lg whitespace-pre-line ${
                      msg.role === 'user' ? 'bg-primary text-white' : 'bg-dark text-white/90'
                    }`}>
                      {msg.message}
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        )
      }
    ],
    stats: [
      { label: 'Response Accuracy', value: '98.5%', trend: 2.4 },
      { label: 'Avg Response Time', value: '0.8s', trend: -15.3 },
      { label: 'Context Retention', value: '95%', trend: 3.2 }
    ]
  },
  {
    id: 'code',
    title: 'Code Assistant',
    description: 'Intelligent code analysis and generation',
    icon: <Code className="w-6 h-6" />,
    screens: [
      {
        title: 'Code Generation',
        content: (
          <div className="space-y-4">
            <div className="bg-dark/50 p-4 rounded-lg font-mono text-sm">
              <div className="flex items-center justify-between mb-2">
                <span className="text-primary">Generated Code</span>
                <span className="text-sm text-white/50">TypeScript</span>
              </div>
              <motion.div 
                className="space-y-2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                {[
                  'interface DataProcessor {',
                  '  process(data: unknown[]): Promise<Result>;',
                  '  validate(input: unknown): boolean;',
                  '}',
                  '',
                  'class AIDataProcessor implements DataProcessor {',
                  '  async process(data: unknown[]): Promise<Result> {',
                  '    // AI-powered processing logic',
                  '    return this.analyze(data);',
                  '  }',
                  '}'
                ].map((line, i) => (
                  <motion.div
                    key={i}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: i * 0.1 }}
                  >
                    {line}
                  </motion.div>
                ))}
              </motion.div>
            </div>
          </div>
        )
      }
    ],
    stats: [
      { label: 'Code Quality', value: '94%', trend: 3.8 },
      { label: 'Generation Speed', value: '1.2s', trend: -10.5 },
      { label: 'Accuracy Rate', value: '96%', trend: 2.1 }
    ]
  },
  {
    id: 'analysis',
    title: 'Data Analysis',
    description: 'Advanced data analysis and insights generation',
    icon: <Database className="w-6 h-6" />,
    screens: [
      {
        title: 'Real-time Analysis',
        content: (
          <div className="space-y-4">
            <div className="bg-dark/50 p-4 rounded-lg">
              <div className="flex items-center justify-between mb-4">
                <span className="text-primary">Data Insights</span>
                <span className="text-sm text-white/50">Live Feed</span>
              </div>
              <div className="space-y-4">
                {[
                  { metric: 'User Engagement', value: '↑ 28%', insight: 'Strong growth in mobile users' },
                  { metric: 'Revenue Impact', value: '↑ 15%', insight: 'New features driving sales' },
                  { metric: 'System Load', value: '↓ 12%', insight: 'Optimization effective' },
                  { metric: 'Error Rate', value: '↓ 45%', insight: 'Quality improving' }
                ].map((item, i) => (
                  <motion.div
                    key={i}
                    className="flex items-center justify-between"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: i * 0.2 }}
                  >
                    <div>
                      <div className="text-sm text-white/70">{item.metric}</div>
                      <div className="text-xs text-white/50">{item.insight}</div>
                    </div>
                    <div className="text-primary">{item.value}</div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        )
      }
    ],
    stats: [
      { label: 'Analysis Depth', value: '92%', trend: 4.2 },
      { label: 'Processing Speed', value: '2.1s', trend: -8.7 },
      { label: 'Insight Quality', value: '96%', trend: 3.4 }
    ]
  }
];

export default function AIDigitalAssistant() {
  const [selectedFeature, setSelectedFeature] = useState(features[0]);

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {features.map((feature) => (
          <button
            key={feature.id}
            onClick={() => setSelectedFeature(feature)}
            className={`p-4 rounded-lg transition-colors ${
              selectedFeature.id === feature.id
                ? 'bg-primary text-white'
                : 'bg-dark text-white/70 hover:bg-primary/10'
            }`}
          >
            <div className="flex items-center space-x-3">
              {feature.icon}
              <span>{feature.title}</span>
            </div>
          </button>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Assistant Interface Preview */}
        <div className="bg-dark p-8 rounded-lg">
          <div className="max-w-2xl mx-auto">
            <div className="relative border-8 border-dark-lighter rounded-lg overflow-hidden">
              <div className="p-4 space-y-4">
                {/* Interface Header */}
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-lg font-light">{selectedFeature.title}</h3>
                    <p className="text-sm text-white/50">{selectedFeature.description}</p>
                  </div>
                  {selectedFeature.icon}
                </div>

                {/* Interface Content */}
                <div className="mt-4">
                  {selectedFeature.screens.map((screen, index) => (
                    <div key={index}>
                      <h4 className="text-sm text-white/70 mb-2">{screen.title}</h4>
                      {screen.content}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Feature Analytics */}
        <div className="space-y-6">
          <div className="bg-dark p-6 rounded-lg">
            <h3 className="text-xl font-light mb-4">Performance Metrics</h3>
            <div className="space-y-4">
              {selectedFeature.stats.map((stat, index) => (
                <div key={index} className="flex items-center justify-between">
                  <span className="text-white/70">{stat.label}</span>
                  <div className="flex items-center space-x-3">
                    <span className="text-lg">{stat.value}</span>
                    <span className={stat.trend > 0 ? 'text-green-500' : 'text-red-500'}>
                      {stat.trend > 0 ? '+' : ''}{stat.trend}%
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-dark p-6 rounded-lg">
            <h3 className="text-xl font-light mb-4">Usage Analytics</h3>
            <div className="grid grid-cols-2 gap-4">
              {[
                { label: 'Active Sessions', value: '1.2K', trend: '+15%' },
                { label: 'Avg Session Time', value: '12m', trend: '+8%' },
                { label: 'Task Completion', value: '94%', trend: '+3%' },
                { label: 'User Satisfaction', value: '4.9/5', trend: '+0.3' }
              ].map((metric, index) => (
                <div key={index} className="bg-dark-lighter p-4 rounded-lg">
                  <div className="text-white/50 text-sm mb-1">{metric.label}</div>
                  <div className="text-xl">{metric.value}</div>
                  <div className="text-sm text-green-500">{metric.trend}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}