import React from 'react';
import { Download } from 'lucide-react';
import FadeIn from '../../../components/animations/FadeIn';

const researchPapers = [
  {
    title: 'The State of AI in 2024',
    description: 'Comprehensive analysis of AI adoption and impact across industries.',
    downloadUrl: '#',
    image: 'https://images.unsplash.com/photo-1620712943543-bcc4688e7485?auto=format&fit=crop&q=80&w=1200'
  },
  {
    title: 'Digital Transformation Report',
    description: 'Key insights and strategies for successful digital transformation.',
    downloadUrl: '#',
    image: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80&w=1200'
  }
];

export default function ResearchSection() {
  return (
    <section className="py-20 bg-dark relative">
      <div className="container mx-auto px-6">
        <FadeIn>
          <h2 className="text-3xl font-light mb-12">Research Papers</h2>
        </FadeIn>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {researchPapers.map((paper, index) => (
            <FadeIn key={index} delay={0.1 * index}>
              <div className="group cursor-pointer bg-dark-lighter rounded-lg overflow-hidden">
                <div className="aspect-[16/9] relative overflow-hidden">
                  <img 
                    src={paper.image} 
                    alt={paper.title}
                    className="object-cover w-full h-full transform group-hover:scale-110 transition-transform duration-700"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-dark-lighter via-dark-lighter/50 to-transparent opacity-60" />
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-light mb-2 group-hover:text-primary transition-colors">
                    {paper.title}
                  </h3>
                  <p className="text-white/70 mb-4">{paper.description}</p>
                  <a 
                    href={paper.downloadUrl}
                    className="inline-flex items-center text-primary hover:text-primary-light"
                  >
                    <Download className="w-4 h-4 mr-2" />
                    <span>Download PDF</span>
                  </a>
                </div>
              </div>
            </FadeIn>
          ))}
        </div>
      </div>
    </section>
  );
}