import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout';
import HomePage from './pages/home';
import ServicesPage from './pages/services';
import WorkPage from './pages/work';
import AboutPage from './pages/about';
import InsightsPage from './pages/insights';
import PositionsPage from './pages/careers/positions';
import ConsultingPage from './pages/consulting';
import CaseStudiesPage from './pages/work/case-studies';

function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/work" element={<WorkPage />} />
        <Route path="/work/case-studies" element={<CaseStudiesPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/insights" element={<InsightsPage />} />
        <Route path="/careers/positions" element={<PositionsPage />} />
        <Route path="/consulting" element={<ConsultingPage />} />
      </Route>
    </Routes>
  );
}

export default App;