import { FlowRenderer } from '../renderers/FlowRenderer';
import { FlowSimulation } from '../simulation/FlowSimulation';

export class AnimationLoop {
  private animationFrame: number | null = null;
  private lastTime: number = 0;
  private resetInterval: NodeJS.Timeout | null = null;

  constructor(
    private renderer: FlowRenderer,
    private simulation: FlowSimulation,
    private canvas: HTMLCanvasElement
  ) {}

  start() {
    this.animate(0);
    this.resetInterval = setInterval(() => {
      this.simulation.reset();
    }, 15000);
  }

  stop() {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
    }
    if (this.resetInterval) {
      clearInterval(this.resetInterval);
    }
  }

  private animate = (currentTime: number) => {
    const deltaTime = (currentTime - this.lastTime) / 1000;
    this.lastTime = currentTime;

    this.renderer.clear(this.canvas.width, this.canvas.height);
    this.renderer.updateTime(deltaTime);
    this.simulation.update(currentTime);

    const currentNodes = this.simulation.getNodes();

    // Draw connections first
    currentNodes.forEach(node => {
      node.connections.forEach(targetId => {
        const targetNode = currentNodes.find(n => n.id === targetId);
        if (targetNode) {
          this.renderer.drawConnection(node, targetNode);
        }
      });
    });

    // Draw nodes on top
    currentNodes.forEach(node => {
      this.renderer.drawNode(node);
    });

    this.animationFrame = requestAnimationFrame(this.animate);
  };
}