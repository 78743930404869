import React, { Suspense, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Loading from '../ui/Loading';
import LoadingProgress from '../ui/LoadingProgress';
import ChatBot from '../chat/ChatBot';
import { useSmoothScroll } from '../../hooks/useSmoothScroll';

export default function Layout() {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  
  // Add smooth scroll hook
  useSmoothScroll();

  return (
    <div className="min-h-screen bg-dark flex flex-col">
      {isInitialLoading ? (
        <LoadingProgress onLoadingComplete={() => setIsInitialLoading(false)} />
      ) : (
        <>
          <Header />
          <main className="flex-grow">
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>
          </main>
          <Footer />
          <ChatBot />
        </>
      )}
    </div>
  );
}