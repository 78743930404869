import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  TrendingUp, 
  BarChart2, 
  PieChart,
  Activity,
  Users,
  DollarSign,
  Target,
  Clock,
  ArrowUp,
  ArrowDown,
  ChevronRight
} from 'lucide-react';

interface BusinessMetric {
  id: string;
  title: string;
  description: string;
  icon: React.ReactNode;
  screens: {
    title: string;
    content: React.ReactNode;
  }[];
  stats: {
    label: string;
    value: string;
    trend: number;
  }[];
}

const metrics: BusinessMetric[] = [
  {
    id: 'financial',
    title: 'Financial Performance',
    description: 'Real-time financial metrics and analysis',
    icon: <DollarSign className="w-6 h-6" />,
    screens: [
      {
        title: 'Financial Overview',
        content: (
          <div className="space-y-4">
            <div className="bg-dark/50 p-4 rounded-lg">
              <div className="flex items-center justify-between mb-4">
                <span className="text-primary">Revenue Analysis</span>
                <span className="text-sm text-white/50">Q1 2024</span>
              </div>
              <div className="space-y-4">
                {[
                  { category: 'Product Revenue', value: '$2.8M', growth: '+15%', status: 'Above Target' },
                  { category: 'Service Revenue', value: '$1.5M', growth: '+22%', status: 'Exceeding' },
                  { category: 'Recurring Revenue', value: '$850K', growth: '+18%', status: 'On Track' },
                  { category: 'Operating Margin', value: '32%', growth: '+5%', status: 'Improving' }
                ].map((item, i) => (
                  <motion.div
                    key={i}
                    className="flex items-center justify-between bg-dark/30 p-3 rounded"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: i * 0.1 }}
                  >
                    <div>
                      <div className="text-sm font-medium">{item.category}</div>
                      <div className="text-xs text-white/50">{item.status}</div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <span className="text-lg text-primary">{item.value}</span>
                      <span className="text-green-500">{item.growth}</span>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        )
      }
    ],
    stats: [
      { label: 'Revenue Growth', value: '+18.5%', trend: 3.2 },
      { label: 'Profit Margin', value: '32%', trend: 2.1 },
      { label: 'Cost Reduction', value: '12%', trend: -8.4 }
    ]
  },
  {
    id: 'operational',
    title: 'Operational Efficiency',
    description: 'Process performance and optimization metrics',
    icon: <Activity className="w-6 h-6" />,
    screens: [
      {
        title: 'Process Analytics',
        content: (
          <div className="space-y-4">
            <div className="bg-dark/50 p-4 rounded-lg">
              <div className="flex items-center justify-between mb-4">
                <span className="text-primary">Process Performance</span>
                <span className="text-sm text-white/50">Real-time</span>
              </div>
              <div className="space-y-4">
                {[
                  { process: 'Order Processing', time: '45 mins', efficiency: '94%', trend: 'Improving' },
                  { process: 'Customer Support', time: '8 mins', efficiency: '96%', trend: 'Optimal' },
                  { process: 'Inventory Management', time: '2 hrs', efficiency: '92%', trend: 'Stable' },
                  { process: 'Quality Control', time: '1.5 hrs', efficiency: '98%', trend: 'Excellent' }
                ].map((process, i) => (
                  <motion.div
                    key={i}
                    className="flex items-center justify-between bg-dark/30 p-3 rounded"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: i * 0.1 }}
                  >
                    <div>
                      <div className="text-sm font-medium">{process.process}</div>
                      <div className="text-xs text-white/50">{process.time}</div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <span className="text-primary">{process.efficiency}</span>
                      <span className="text-sm text-green-500">{process.trend}</span>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        )
      }
    ],
    stats: [
      { label: 'Process Efficiency', value: '94%', trend: 5.2 },
      { label: 'Cycle Time', value: '-25%', trend: -15.3 },
      { label: 'Resource Usage', value: '82%', trend: -3.4 }
    ]
  },
  {
    id: 'customer',
    title: 'Customer Metrics',
    description: 'Customer satisfaction and engagement analysis',
    icon: <Users className="w-6 h-6" />,
    screens: [
      {
        title: 'Customer Analytics',
        content: (
          <div className="space-y-4">
            <div className="bg-dark/50 p-4 rounded-lg">
              <div className="flex items-center justify-between mb-4">
                <span className="text-primary">Customer Insights</span>
                <span className="text-sm text-white/50">Last 30 Days</span>
              </div>
              <div className="space-y-4">
                {[
                  { metric: 'Customer Satisfaction', score: '4.8/5', trend: '+0.3', status: 'Excellent' },
                  { metric: 'Net Promoter Score', score: '72', trend: '+5', status: 'Industry Leading' },
                  { metric: 'Retention Rate', score: '94%', trend: '+2%', status: 'Strong' },
                  { metric: 'Engagement Score', score: '88%', trend: '+4%', status: 'Growing' }
                ].map((metric, i) => (
                  <motion.div
                    key={i}
                    className="flex items-center justify-between bg-dark/30 p-3 rounded"
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: i * 0.1 }}
                  >
                    <div>
                      <div className="text-sm font-medium">{metric.metric}</div>
                      <div className="text-xs text-white/50">{metric.status}</div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <span className="text-primary">{metric.score}</span>
                      <span className="text-green-500">{metric.trend}</span>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        )
      }
    ],
    stats: [
      { label: 'Customer Growth', value: '+28%', trend: 12.4 },
      { label: 'Satisfaction', value: '4.8/5', trend: 0.3 },
      { label: 'Retention', value: '94%', trend: 2.1 }
    ]
  }
];

export default function AIBusinessPerformance() {
  const [selectedMetric, setSelectedMetric] = useState(metrics[0]);
  const [expandedSection, setExpandedSection] = useState<string | null>(null);

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {metrics.map((metric) => (
          <button
            key={metric.id}
            onClick={() => setSelectedMetric(metric)}
            className={`p-4 rounded-lg transition-colors ${
              selectedMetric.id === metric.id
                ? 'bg-primary text-white'
                : 'bg-dark text-white/70 hover:bg-primary/10'
            }`}
          >
            <div className="flex items-center space-x-3">
              {metric.icon}
              <span>{metric.title}</span>
            </div>
          </button>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Performance Dashboard */}
        <div className="bg-dark p-8 rounded-lg">
          <div className="max-w-2xl mx-auto">
            <div className="relative border-8 border-dark-lighter rounded-lg overflow-hidden">
              <div className="p-4 space-y-4">
                {/* Dashboard Header */}
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-lg font-light">{selectedMetric.title}</h3>
                    <p className="text-sm text-white/50">{selectedMetric.description}</p>
                  </div>
                  {selectedMetric.icon}
                </div>

                {/* Dashboard Content */}
                <div className="mt-4">
                  {selectedMetric.screens.map((screen, index) => (
                    <div key={index}>
                      <h4 className="text-sm text-white/70 mb-2">{screen.title}</h4>
                      {screen.content}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Performance Analytics */}
        <div className="space-y-6">
          <div className="bg-dark p-6 rounded-lg">
            <h3 className="text-xl font-light mb-4">Key Performance Indicators</h3>
            <div className="space-y-4">
              {selectedMetric.stats.map((stat, index) => (
                <div key={index} className="flex items-center justify-between">
                  <span className="text-white/70">{stat.label}</span>
                  <div className="flex items-center space-x-3">
                    <span className="text-lg">{stat.value}</span>
                    <span className={stat.trend > 0 ? 'text-green-500' : 'text-red-500'}>
                      {stat.trend > 0 ? '+' : ''}{stat.trend}%
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-dark p-6 rounded-lg">
            <h3 className="text-xl font-light mb-4">Performance Trends</h3>
            <div className="grid grid-cols-2 gap-4">
              {[
                { label: 'Growth Rate', value: '+28%', trend: '+5.2%' },
                { label: 'Market Share', value: '32%', trend: '+2.1%' },
                { label: 'ROI', value: '285%', trend: '+15.3%' },
                { label: 'Efficiency', value: '94%', trend: '+3.2%' }
              ].map((metric, index) => (
                <div key={index} className="bg-dark-lighter p-4 rounded-lg">
                  <div className="text-white/50 text-sm mb-1">{metric.label}</div>
                  <div className="text-xl">{metric.value}</div>
                  <div className="text-sm text-green-500">{metric.trend}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}