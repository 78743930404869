import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Send } from 'lucide-react';

export default function FooterNewsletter() {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState<'idle' | 'success' | 'error'>('idle');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Implement newsletter subscription logic here
      setStatus('success');
      setEmail('');
      setTimeout(() => setStatus('idle'), 3000);
    } catch (error) {
      setStatus('error');
      setTimeout(() => setStatus('idle'), 3000);
    }
  };

  return (
    <div className="lg:col-span-3">
      <h3 className="text-base font-light text-white mb-3">Stay Updated</h3>
      <p className="text-xs text-white/70 mb-4">
        Subscribe to our newsletter for the latest insights and updates on AI, digital transformation,
        and technology trends.
      </p>
      <form onSubmit={handleSubmit} className="space-y-3">
        <div className="relative">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="w-full bg-dark rounded-lg pl-3 pr-10 py-2 text-sm text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
            required
          />
          <motion.button
            type="submit"
            className="absolute right-2 top-1/2 -translate-y-1/2 p-1.5 text-primary hover:text-primary-light transition-colors"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            aria-label="Subscribe to newsletter"
          >
            <Send className="w-4 h-4" />
          </motion.button>
        </div>
        {status === 'success' && (
          <p className="text-xs text-green-500">Thank you for subscribing!</p>
        )}
        {status === 'error' && (
          <p className="text-xs text-red-500">An error occurred. Please try again.</p>
        )}
        <p className="text-xs text-white/50">
          By subscribing, you agree to our Privacy Policy and consent to receive updates.
        </p>
      </form>
    </div>
  );
}